/* @flow */

import type { ProductFilterBucket, ProductFilterInput } from "shop-state/types";

import React from "react";
import FilterContainer from "../FilterContainer";

import CheckmarkIcon from "icons/checkmark.svg";

import styles from "./styles.scss";

type Props = {
  openFilter: string,
  setOpenFilter: (filterName: string) => void,
  filter: ProductFilterBucket,
  onValueClick: ProductFilterInput => void,
  activeFilters: Array<ProductFilterInput>,
};

const FilterBucket = ({
  openFilter,
  setOpenFilter,
  filter,
  onValueClick,
  activeFilters }: Props) => {
  const activeValue = activeFilters && activeFilters.find(af => af.code === filter.code);

  return (
    <FilterContainer
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      filterName={filter.label.toLocaleUpperCase()}
    >
      {filter.values.map(v => {
        return (
          <div
            key={v.value}
            className={styles.value}
            onClick={() => onValueClick({ code: filter.code, value: v.value })}
          >
            <span>
              {v.value}
            </span>

            {activeValue && activeValue.value === v.value &&
              <CheckmarkIcon className={styles.checkmark} />
            }
          </div>
        );
      })}
    </FilterContainer>
  );
};

export default FilterBucket;
