/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import { StoreInfoContext } from "entrypoint/shared";
import OrderHistory from "./OrderHistory";

import AccountViewHero from "./AccountViewHero";
import ShippingAddress from "./ShippingAddress";
import ElapsingPoints from "./ElapsingPoints";
import Wishlist from "./Wishlist";

import styles from "./styles.scss";

type Props = {
  customer: Customer,
};

const AccountView = ({ customer }: Props) => {
  const t = useTranslate();
  const { info: { usePoints } } = useContext(StoreInfoContext);

  return (
    <div className={styles.block}>
      <Helmet
        title={t("ACCOUNT.MY_ACCOUNT")}
      />
      <AccountViewHero customer={customer} />
      <Wrapper>
        <div className={styles.mainSplit}>
          <div className={styles.left}>
            <ShippingAddress
              className={styles.shipping_address}
              customer={customer} />
            {usePoints &&
            <ElapsingPoints
              className={styles.elapsing_points}
              customer={customer} />
            }
          </div>
          <div className={styles.right}>
            <OrderHistory className={styles.history} />
            <Wishlist className={styles.wishlist} />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default AccountView;
