/* @flow */

import { useData } from "crustate/react";
import { useEffect, useState } from "react";
import { CustomerData } from "data";

type Error = {
  field: string,
  error: string,
};

type Category = {
  +name: string,
  +url: string,
};

type TopCategory = {
  +name: string,
  +url: string,
  +children: $ReadOnlyArray<Category>,
};

declare type DOMEvent<T> = {
  target: T,
} & Event;

export const usePriceToPoints = () => {};
export const usePointsToPrice = () => {};

export const focusInvalidField = (e: SyntheticEvent<HTMLFormElement>, errors: Array<Error>) => {
  const field = e.currentTarget.querySelector(`[name='${errors[0].field}']`);

  if (field) {
    field.focus();
  }
};

export const useGetRemaining = (grandTotalPoints: number) => {
  const customerData = useData(CustomerData);

  if ((customerData.state !== "LOADED" && customerData.state !== "UPDATING") ||
  !customerData.data) {
    return { remainingPoints: null, remainingPrice: null };
  }

  const { scandic: { points, rate } } = customerData.data;

  const remainingPrice = (grandTotalPoints - points) / rate;

  const remainingPoints = points;

  return { remainingPoints, remainingPrice };
};

export const loadScript = (isLoaded: () => boolean, src: string, cb: () => void): void => {
  if (!process.browser) {
    return;
  }

  if (isLoaded()) {
    cb();

    return;
  }

  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.src = src;

  /* eslint-disable unicorn/prefer-add-event-listener */
  script.onload = () => {
    script.onload = null;

    cb();
  };
  /* eslint-enable unicorn/prefer-add-event-listener */

  if (head) {
    /* eslint-disable unicorn/prefer-dom-node-append */
    head.appendChild(script);
    /* eslint-enable unicorn/prefer-dom-node-append */
  }
};

/**
 * Calculate the stepsize to use with the rangeslider
 */
export const getStepSize = (value: number, percent: number) => {
  const stringValue = String(value);
  const numDigits = stringValue.length;
  const prefix = value < 10 ? 1 :
    Math.ceil(Number.parseInt(stringValue.slice(0, 2), 10) * (0.01 * percent));

  return Number.parseInt(`${prefix}${([numDigits - 1]).join("0")}`, 10);
};

export const useCurrentSrc = (ref: { current: ?HTMLImageElement }, defaultSrc: string): string => {
  const [image, setImage] = useState(defaultSrc);

  useEffect(() => {
    const { current } = ref;

    if (current && current.currentSrc) {
      setImage(current.currentSrc);
    }
  }, [ref]);

  return image;
};

export const isValidHex = (hex: string) => /^[0-9A-F]{6}$/i.test(hex);

export const removeExampleEmail = <T: { email: ?string }>(object: T): T => {
  if (object && object.email && object.email.match(/@example.com$/)) {
    return {
      ...object,
      email: null,
    };
  }

  return object;
};

export const sortCategories = (
  categories: $ReadOnlyArray<TopCategory>,
  locale: string): Array<TopCategory> => {
  return categories.slice().sort((a, b) => a.name.localeCompare(b.name, locale));
};
