/* @flow */

import React, { useRef, useEffect, useState, useContext } from "react";
import cn from "classnames";

import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { QuoteData } from "data";
import { useData, useSendMessage } from "crustate/react";
import { setMode, MODE } from "state/view-mode";
import { removeQuoteItem, updateQuoteItemQty } from "@crossroads/shop-state/quote";
import PriceSplit from "components/PriceSplit";
import { pointsPriceByID } from "helpers/points";
import Button from "components/Button";
import QtyPickerMini from "components/QtyPickerMini";
import useFormat from "helpers/use-format";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const CartMiniDefault = ({ className }: Props) => {
  const refInnerBody = useRef<?HTMLDivElement>();
  const refBody = useRef<?HTMLDivElement>();
  const [bottomAligned, setBottomAligned] = useState(false);
  const [topAligned, setTopAligned] = useState(false);
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { formatPrice, formatPoints } = useFormat();
  const quoteData = useData(QuoteData);
  const { info: { usePoints } } = useContext(StoreInfoContext);
  const quote = quoteData.state === "LOADED" ||
                quoteData.state === "UPDATING_ITEM" ||
                quoteData.state === "REMOVING_ITEM" ? quoteData.data : null;
  const processing = quoteData.state !== "LOADED";
  const [showingMiniCart, setShowingMiniCart] = useState(false);

  const removeItem = (itemBuyRequest: string) => {
    if (processing) {
      return;
    }

    return sendMessage(removeQuoteItem(itemBuyRequest));
  };

  const cartItemHidden = () => {
    const refBodyRect = refBody.current && refBody.current.getBoundingClientRect();
    const refInnerBodyRect = refInnerBody.current && refInnerBody.current.getBoundingClientRect();

    if (refBodyRect && refInnerBodyRect) {
      setTopAligned(refBodyRect.top !== 0 && refBodyRect.top <= refInnerBodyRect.top);
      setBottomAligned(refBodyRect.top !== 0 && refBodyRect.bottom >= refInnerBodyRect.bottom - 5);
    }
  };

  useEffect(() => {
    const ref = refBody;
    if (ref.current) {
      ref.current.addEventListener("scroll", cartItemHidden, { passive: true });
    }

    cartItemHidden();

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", cartItemHidden);
      }
    };
  }, [refBody]);

  if (!quote || quote.items.length === 0) {
    if (showingMiniCart) {
      sendMessage(setMode(MODE.NORMAL));
    }

    return null;
  }

  if (!showingMiniCart) {
    setShowingMiniCart(true);
  }

  const { items, availablePointPayments } = quote;

  const pointPayment = pointsPriceByID(availablePointPayments, "scandic");

  if (!pointPayment) {
    return null;
  }

  const shippingValue = pointPayment.shipping?.points.value.incVat || 0;
  const subtotalPoints = quote.items.reduce((a, c) =>
    a + (pointsPriceByID(c.availablePointPayments, "scandic")?.points.max.incVat || 0), 0);
  const subtotalCurrency = quote.items.reduce((a, c) =>
    a + (pointsPriceByID(c.availablePointPayments, "scandic")?.currency.min.incVat || 0), 0);

  return (
    <div className={cn(styles.block, className)}>
      <div className={styles.list_container}>
        <div
          className={cn(
            styles.shadow__top,
            { [styles.shadow__hidden]: topAligned }
          )}
        />
        <div
          className={cn(
            styles.shadow__bottom,
            { [styles.shadow__hidden]: bottomAligned }
          )}
        />

        <div ref={refBody} className={styles.list}>
          <div ref={refInnerBody}>
            {items.map(x => {
              const product = x.configOption ? {
                ...x.product,
                ...x.configOption.product,
              } : x.product;

              const pointsPrice = pointsPriceByID(x.availablePointPayments, "scandic");

              if (!pointsPrice) {
                return null;
              }

              return (
                <div key={x.itemBuyRequest} className={styles.item}>
                  <div className={styles.itemBody}>
                    <img className={styles.itemImg} src={product.attributes.image?.x1} />
                    <div className={styles.itemTextContainer}>
                      <div className={styles.itemTextContainerTop}>
                        <p className={styles.itemName}>
                          {product.name}
                        </p>
                      </div>
                      <p className={styles.itemBrand}>
                        {product.attributes.manufacturer}
                      </p>
                      <div className={styles.itemQty}>
                        <p>
                          {t("CART.QUANTITY")}
                        </p>
                        <QtyPickerMini
                          className={styles.qtyPicker}
                          value={x.qty}
                          min={0}
                          setValue={(v: number) => {
                            if (quoteData.state !== "LOADED") {
                              return;
                            }

                            if (v > 0) {
                              sendMessage(updateQuoteItemQty(x.itemBuyRequest, v));
                            }
                            else {
                              removeItem(x.itemBuyRequest);
                            }
                          }}
                        />
                      </div>
                      <div className={styles.itemPrice}>
                        <Button
                          className={styles.removeItemButton}
                          onClick={() => removeItem(x.itemBuyRequest)}
                        >
                          {t("CART.REMOVE_PRODUCT")}
                        </Button>
                        <p>
                          {usePoints ?
                            formatPoints(pointsPrice.points.max.incVat) :
                            formatPrice(x.rowTotal.incVat)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

        </div>

      </div>

      <div className={styles.totals}>
        <div className={styles.totalsRow}>
          <span>{t("CART.SUBTOTAL")}</span>
          <span>
            {subtotalPoints > 0 ?
              <>
                {formatPoints(subtotalPoints)}
                {subtotalCurrency > 0 &&
                  <span>&nbsp;+&nbsp;{formatPrice(subtotalCurrency)}</span>
                }
              </> :
              formatPrice(subtotalCurrency)
            }
          </span>
        </div>

        {(quote.shipping && shippingValue > 0) &&
          <div className={styles.totalsRow}>
            <span>{quote.shipping.method.description}</span>
            <span> {formatPoints(shippingValue)}</span>
          </div>
        }

        {/* quote.discountTotal < -0.01 &&
          <div className={styles.totalsRow}>
            <span>{t("CART.DISCOUNT")}</span>
            <span>
              {formatPoints(discountPoints)}
            </span>
          </div>
        */}

        <div className={cn(styles.totalsRow, styles.totalsRow__grandtotal)}>
          <strong>{t("CART.GRANDTOTAL")}</strong>
          <PriceSplit className={styles.grandTotal} pointsPrice={pointPayment} />
        </div>
      </div>

      <div className={styles.bottom}>
        <Button disabled={items.length === 0} to="/checkout/placeorder" variant="primary">{t("CART.CTA")}</Button>
        <Button disabled={items.length === 0} to="/checkout/cart" variant="ghost">{t("CART.EDIT")}</Button>
      </div>
    </div>
  );
};

export default CartMiniDefault;
