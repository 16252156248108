/* @flow */

import type { Customer } from "shop-state/types";

import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import LinkIcon from "icons/link.svg";

import styles from "./styles.scss";

type Props = {
  customer: Customer,
  className?: string,
};

const ShippingAddress = ({ customer, className }: Props) => {
  const t = useTranslate();
  const { firstname, lastname } = customer;
  const shippingAddress = customer.addresses.find(a => a.isDefaultShipping);

  return (
    <div className={cn(styles.block, className)}>
      <h2 className={styles.heading}>{t("ACCOUNT.SHIPPING_ADDRESS")}</h2>
      <div className={styles.box}>
        <div className={styles.address}>
          <p>
            <span>{`${firstname} ${lastname}`}</span>
            <br />
            {shippingAddress ?
              <>
                {shippingAddress.street.length > 0 &&
                  <>
                    <span>{shippingAddress.street[0]}</span>
                    <br />
                  </>
                }

                {shippingAddress.postcode &&
                  <span>{shippingAddress.postcode}</span>
                }

                {shippingAddress.postcode && shippingAddress.city &&
                  <span> </span>
                }

                {shippingAddress.city &&
                  <span>{shippingAddress.city}</span>
                }
              </> : <span className={styles.missingAddress}>{t("ADDRESS.MISSING")}</span>
            }
          </p>
          <p>
            {shippingAddress &&
              <>
                <span>{shippingAddress.telephone}</span>
                <br />
              </>
            }
            <span style={{ lineBreak: "anywhere" }}>{customer.email}</span>
          </p>
        </div>
      </div>
      <div className={styles.edit}>
        <p>
          <span>{t("ACCOUNT.EDIT_INFO_TEXT")}</span>
          <br />
          <a href={t("ACCOUNT.EDIT_LINK")} target="_blank" rel="noopener noreferrer">
            {t("ACCOUNT.EDIT_INFO")}
            <LinkIcon />
          </a>
        </p>
      </div>
    </div>
  );
};

export default ShippingAddress;
