/* @flow */

import React, { useEffect, useRef, useState } from "react";

type Props = {
  className: string,
  zoom?: number,
  address: Array<string>,
  countryCode: string,
};

const MAX_TRIES = 10;

const Map = ({ address, zoom, className, countryCode }: Props) => {
  if (process.env.NODE_ENV !== "production" && !address) {
    throw new Error("You need to pass a address to the <Map /> component");
  }

  const [showMap, setShowMap] = useState(false);

  const mapRef = useRef(null);

  const loadMap = (counter = 1) => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    if (!globalGoogleMapsLoaded) {
      if (counter > MAX_TRIES) {
        return;
      }

      setTimeout(() => {
        loadMap(counter + 1);
      }, counter * 100);
      return;
    }

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({
      address: address.join(" "),
    }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const country = results[0].address_components.find(ac => ac.types.includes("country"));

        if (country && country.short_name.toLowerCase() !== countryCode.toLowerCase()) {
          return;
        }

        setShowMap(true);

        const options = {
          zoom,
          center: results[0].geometry.location,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          disableDefaultUI: true,
        };

        const mapInstance = new google.maps.Map(map, options);

        if (results[0]) {
          /* eslint-disable no-new */
          new google.maps.Marker({
            map: mapInstance,
            position: results[0].geometry.location,
          });
          /* eslint-enable no-new */
        }
      }
    });
  };

  useEffect(() => {
    loadMap();
  });

  return (
    <div
      ref={mapRef}
      style={showMap ?
        { background: "#eee" } :
        { background: "#eee", height: "0px", opacity: 0 }}
      className={className}
    />
  );
};

Map.defaultProps = {
  zoom: 14,
};

export default Map;
