/* @flow */

import type { History, Location } from "react-router";
import type { TimerStep } from "helpers/payment-timer";

import React, { useState, useMemo } from "react";
import { Route, Switch } from "react-router";
import Redirect from "@crossroads/react-router-redirect";
import CmsPageView from "components/CmsPageView";
import {
  RouteData, CustomerData, QuoteData, OrderData, HomeData, BrandData, SearchData,
  OrderHistoryData, PopularData, WishlistData, AllProductsData, CmsData, VerifyData,
} from "data";
import { useData } from "crustate/react";
import App from "components/App";
import CategoryView, { HintCategoryView } from "components/CategoryView";
import BrandView from "components/BrandView";
import PopularView from "components/PopularView";
import SearchView from "components/SearchView";
import AllProductsView from "components/AllProductsView";
import ProductView, { HintProductView } from "components/ProductView";
import HomeView from "components/HomeView";
import AccountView from "components/AccountView";
import SuccessView from "components/SuccessView";
import CheckoutView from "components/CheckoutView";
import NotFoundView from "components/NotFoundView";
import LoadingView from "components/LoadingView";
import { PAGE_SIZE as ORDER_HISTORY_PAGE_SIZE } from "state/order-history";
import { CheckoutTimerContext } from "helpers/payment-timer";
import { locationWithDefaults } from "helpers/use-update-product-list";
import { useTranslate } from "@awardit/react-use-translate";
import { parseParams } from "helpers/location-search-string";

type RoutesProps = {
  location: Location,
  history: History,
};

type RouteViewProps = {
  location: Location,
  history: {
    replace: ({}) => void,
  },
};

const RouteView = ({ location, history }: RouteViewProps) => {
  const data = useData(RouteData);

  if (data.state === "LOADING") {
    if (data.hint) {
      switch (data.hint.type) {
        case "product":
          return <HintProductView product={data.hint.product} />;
        case "category":
          return <HintCategoryView category={data.hint.category} />;
        case "cms_page":
          return <CmsPageView cmsPage={data.hint.cmsPage} />;
        default:
      }
    }

    return <LoadingView />;
  }

  if (!data.route) {
    // TODO: Check error msg
    return <NotFoundView />;
  }

  switch (data.route.type) {
    case "product":
      return <ProductView product={data.route.product} />;
    case "category":
      return <CategoryView category={data.route.category} location={location} history={history} />;
    case "cms_page":
      return <CmsPageView cmsPage={data.route.cmsPage} />;
    case "redirect":
      return <Redirect to={data.route.url} />;
    default:
      return null;
  }
};

const Routes = ({ location, history }: RoutesProps) => {
  const customerData = useData(CustomerData);
  const quoteData = useData(QuoteData);
  const t = useTranslate();

  const [checkoutTimerStep, setCheckoutTimerStep] = useState<TimerStep | null>(null);

  const checkoutTimer = useMemo(() => ({
    checkoutTimerStep,
    setCheckoutTimerStep,
  }), [checkoutTimerStep, setCheckoutTimerStep]);

  return (
    <CheckoutTimerContext.Provider value={checkoutTimer}>
      <Switch>
        <App location={location} history={history}>
          <Switch>
            <Route
              exact path="/" render={() => (
                <HomeData.Provider>
                  <HomeView />
                </HomeData.Provider>
              )} />

            <Route
              exact path="/account"
              render={({ history }) => {
                if (customerData.state === "ERROR") {
                  history.push("/");
                  return null;
                }

                if (!customerData.data || quoteData.data === null) {
                  return null;
                }

                return (
                  <OrderHistoryData.Provider pageSize={ORDER_HISTORY_PAGE_SIZE} page={1}>
                    <WishlistData.Provider>
                      <AccountView
                        customer={customerData.data}
                        quote={quoteData.data}
                      />
                    </WishlistData.Provider>
                  </OrderHistoryData.Provider>
                );
              }}
            />

            <Route
              path="/checkout/success"
              render={({ history }) => (
                <OrderData.Provider>
                  <SuccessView history={history} />
                </OrderData.Provider>
              )} />

            <Route
              exact
              path="/checkout/:step"
              render={props => (
                <CmsData.Provider url={t("CHECKOUT.TERMS_LINK")}>
                  <VerifyData.Provider>
                    <CheckoutView {...props} />
                  </VerifyData.Provider>
                </CmsData.Provider>
              )}
            />

            <Route
              path="/brand/:brand"
              render={({ match, location, search }) => {
                const { page } = parseParams(search);
                return (
                  <BrandData.Provider
                    name={match.params.brand}
                    brand={decodeURIComponent(match.params.brand)}
                    page={page}
                    location={locationWithDefaults(location)}
                  >
                    <BrandView />
                  </BrandData.Provider>
                );
              }} />

            <Route
              exact path="/popular-products"
              render={({ location }) => {
                return (
                  <HomeData.Provider>
                    <PopularData.Provider
                      location={location}
                    >
                      <PopularView />
                    </PopularData.Provider>
                  </HomeData.Provider>
                );
              }} />

            <Route
              exact path="/all-products"
              render={({ match, location, search }) => {
                const query = decodeURIComponent(match.params.query);
                const { page } = parseParams(search);

                return (
                  <AllProductsData.Provider
                    location={locationWithDefaults(location)}
                    page={page}
                    query={query}
                  >
                    <AllProductsView location={location} />
                  </AllProductsData.Provider>
                );
              }} />

            <Route
              path="/search/:query"
              render={({ match, location, search }) => {
                const { page } = parseParams(search);
                const query = decodeURIComponent(match.params.query);

                return (
                  <SearchData.Provider
                    location={locationWithDefaults(location)}
                    page={page}
                    query={query}
                    name={`search_${match.params.query}`}
                  >
                    <SearchView query={query} />
                  </SearchData.Provider>
                );
              }}
            />

            <Route
              path="/(.+)"
              render={({ location: { pathname, state, search }, history }) => {
                const { page } = parseParams(search);
                return (
                  <RouteData.Provider
                    location={locationWithDefaults(location)}
                    page={page}
                    name={`route_${pathname}`}
                    hint={state && state.hint}
                  >
                    <RouteView location={location} history={history} />
                  </RouteData.Provider>
                );
              }} />
          </Switch>
        </App>
      </Switch>
    </CheckoutTimerContext.Provider>
  );
};

const AppRoutes = () => <Route component={Routes} />;

export default AppRoutes;
