/* @flow */

import type {
  FilterLocation as Location,
  FilterableProductList,
  ProductFilterInput,
  ProductFilterInputPrice,
} from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

import {
  getInputFilters,
  getPage,
} from "@crossroads/shop-state/filter";

type Data =
  | { state: "INIT" }
  | { state: "LOADING" }
  | { state: "UPDATING", data: FilterableProductList }
  | { state: "LOADED", data: FilterableProductList };

export type PopularResponse =
  Response<typeof POPULAR_RESPONSE, ?FilterableProductList> | EffectErrorMessage;

export type PopularRequest = {
  tag: typeof POPULAR_REQUEST,
  filters: ?Array<ProductFilterInput | ProductFilterInputPrice>,
  page: ?number,
};

export const POPULAR_RESPONSE: "response/popular" = "response/popular";
export const POPULAR_REQUEST: "request/popular" = "request/popular";

export const load = (location: Location): PopularRequest => {
  const filters = getInputFilters(location);
  const page = getPage(location);

  return {
    tag: POPULAR_REQUEST,
    filters,
    page,
  };
};

export const PopularModel: Model<Data, {
  location: Location}, PopularRequest | PopularResponse> = {
    id: "popular",
    init: ({ location }) =>
      updateData(
        { state: "LOADING" },
        load(location)
      ),
    update: (state: Data, msg) => {
      switch (msg.tag) {
        case EFFECT_ERROR:
          throw new Error("Route load failed");
        case POPULAR_REQUEST:
          if (state.state === "LOADED") {
            return updateData(
              { state: "UPDATING", data: state.data },
              msg
            );
          }

          break;
        case POPULAR_RESPONSE:
          if (msg.data && (state.state === "LOADING" || state.state === "UPDATING")) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;
        default:
      }
    },
  };
