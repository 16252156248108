/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";
import Icon from "icons/customer-service.svg";
import { Link } from "react-router-dom";

const CustomerServiceLink = () => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Icon className={styles.icon} />
      <div className={styles.text}>
        <span>{t("CHECKOUT.NEED_HELP")}</span>
        <br />
        <Link to={t("CUSTOMER_SERVICE.LINK")} target="_blank" rel="noopener noreferrer">
          {t("CHECKOUT.CONTACT_CUSTOMER_SERVICE")}
        </Link>
      </div>
    </div>
  );
};

export default CustomerServiceLink;
