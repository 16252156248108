/* @flow */

import type { Quote, Customer } from "shop-state/types";

import React from "react";
import cn from "classnames";
import { Box, BoxBody } from "components/Box";
import { useTranslate } from "@awardit/react-use-translate";
import useCustomer from "helpers/use-customer";
import { QuoteData } from "data";
import { useData } from "crustate/react";
import { pointsPriceByID } from "helpers/points";
import { getCustomerPoints } from "state/customer";
import WarningIcon from "icons/warning.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

type GetErrorMessageProps = {
  quote: ?Quote,
  customer: ?Customer,
};

const useGetErrorMessages = ({
  quote,
  customer }: GetErrorMessageProps): Array<string> => {
  const t = useTranslate();

  if (!quote || !customer) {
    return [];
  }

  const availablePointPayment = quote.availablePointPayments ?
    pointsPriceByID(quote.availablePointPayments, "scandic") : null;

  if (!quote.requiresPointPayment) {
    if (!availablePointPayment) {
      return [t(`REJECT_REASONS.POINT_PAYMENT_OPTIONAL.NO_AVAILABLE_POINT_PAYMENT`)];
    }

    // Check if the customer spent all points in this time-window
    const balance = getCustomerPoints(customer);
    const points = customer.points.find(x => x.id === "scandic") || null;
    const grandTotal = quote.grandTotal.incVat;
    const maximumPoints = Math.min(
      availablePointPayment.points.available.incVat,
      balance
    );

    if (points && points.spendingLimit && balance > 0 && maximumPoints === 0 && grandTotal > 0) {
      return [t("REJECT_REASONS.POINT_PAYMENT_OPTIONAL.REMAINING_POINTS_MAXED_OUT", {
        limit: points.spendingLimit.limit,
      })];
    }

    return [];
  }

  if (!availablePointPayment) {
    return [t("REJECT_REASONS.POINT_PAYMENT_REQUIRED.NO_AVAILABLE_POINT_PAYMENT")];
  }

  const errors = [];

  availablePointPayment.rejectionReasons.forEach(rr => {
    switch (rr.type) {
      case "PointsQuoteRejectionReasonBalance":
        errors.push(t("REJECT_REASONS.POINT_PAYMENT_REQUIRED.BALANCE", {
          requested: rr.requested,
          balance: rr.balance,
        }));

        break;
      case "PointsQuoteRejectionReasonTotalLimit":
        errors.push(t("REJECT_REASONS.POINT_PAYMENT_REQUIRED.TOTAL_LIMIT", {
          remaining: rr.remaining,
          requested: rr.requested,
          limit: rr.limit,
        }));

        break;
      case "PointsQuoteRejectionReasonOrderLimit":
        errors.push(t("REJECT_REASONS.POINT_PAYMENT_REQUIRED.ORDER_LIMIT", {
          limit: rr.limit,
          requested: rr.requested,
        }));

        break;
      default:
    }
  });

  return errors;
};

const RejectionReasons = ({ className = "" }: Props): React$Node => {
  const quoteData = useData(QuoteData);
  const quote = quoteData.data || {};
  const { customer } = useCustomer();
  const errorMessages = useGetErrorMessages({ quote, customer });

  if (errorMessages.length === 0) {
    return null;
  }

  return (
    <Box className={cn(styles.block, className)}>
      <BoxBody>
        <ul className={styles.list}>
          {errorMessages.map((m, i) => (
            <li key={i}>
              <WarningIcon />
              <p>{m}</p>
            </li>
          ))}
        </ul>
      </BoxBody>
    </Box>
  );
};

export default RejectionReasons;
