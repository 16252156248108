/* @flow */

import type { Product } from "shop-state/types";
import type { Option } from "@crossroads/ui-components";

import React from "react";
import { pointsPriceByID } from "helpers/points";
import { getAttributesConfigurable, getSelectedConfigurable } from "@crossroads/ui-components";

export const getSelectedPointsPrice = (product: Product, selected: Option) => {
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const item = getSelectedConfigurable(selected, configAttributes);

  switch (product.type) {
    case "configurable":
      if (item) {
        const selectedOption = product.options.items.find(x => x.buyRequest === item.buyRequest);

        if (selectedOption) {
          return {
            pointsPrice: pointsPriceByID(selectedOption.pointsPrices, "scandic"),
          };
        }
      }

      break;
    case "bundle":
      // @TODO: handle points here when the API is done
      break;
    default:
  }

  return {
    pointsPrice: pointsPriceByID(product.pointsPrices, "scandic"),
  };
};
