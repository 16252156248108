/* @flow */

import type { SortableProductList, FilterableProductList } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import useDevice from "helpers/use-device";
import { parseParams, stringifyParams } from "helpers/location-search-string";
import ProductList, { ListItem } from "components/ProductList";
import Wrapper from "components/Wrapper";
import { Pagination } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  className?: string,
  updating: boolean,
  numPages: number,
  productList: SortableProductList | FilterableProductList,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
  category?: string,
};

type PaginationButtonT = {
  page: number,
  active: boolean,
  children: React$Node,
};

const PaginatedProductList = memo<Props>(({
  updating,
  numPages,
  productList,
  className,
  breadcrumbLinks,
  category,
}: Props) => {
  const t = useTranslate();
  const location = useLocation();
  const params = parseParams(location.search);
  const page = parseInt(params.page, 10) || 1;
  const isDesktop = useDevice("gt", 801);

  if (updating) {
    return (
      <div className={className}>
        <Wrapper className={styles.wrapper}>
          <ProductList products={[null, null, null, null, null, null, null, null]} />
        </Wrapper>
      </div>
    );
  }

  const PaginationButton = ({ active, children, page }: PaginationButtonT) => {
    const newSearch = {
      ...params,
      page: String(page),
    };

    return (
      <Link
        disabled={active}
        className={
          cn(styles.paginationButton, { [styles.active]: active })
        }
        to={stringifyParams(newSearch)}
      >
        {children}
      </Link>
    );
  };

  return (
    <div className={styles.block}>
      <Wrapper className={styles.wrapper}>
        {productList.items.length > 0 ?
          <>
            <ProductList>
              {productList.items.map((p, i) => (
                <ListItem
                  key={`p${p.sku}`}
                  loading={false}
                  product={p}
                  breadcrumbLinks={breadcrumbLinks}
                  listName={category}
                  idx={i}
                />
              ))}
            </ProductList>
            {numPages > 1 && (
              <div className={styles.paginationWrapper}>
                <Pagination
                  className={styles.pagination}
                  currentPage={page}
                  pageCount={numPages}
                  ButtonComponent={PaginationButton}
                  maxButtonCount={isDesktop ? 10 : 5}
                />
              </div>
            )}
          </> :
          <p className={styles.empty}>{t("CATEGORY.EMPTY")}</p>
        }
      </Wrapper>
    </div>
  );
});

export default PaginatedProductList;
