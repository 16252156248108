/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";

const localePrefixScandicUrlMap = {
  en: "https://www.scandichotels.com",
  sv: "https://www.scandichotels.se",
  nb: "https://www.scandichotels.no",
  nn: "https://www.scandichotels.no",
  da: "https://www.scandichotels.dk",
  fi: "https://www.scandichotels.fi",
  de: "https://www.scandichotels.de",
};

const getUrlFromLocale = (locale: string): string => {
  const localePrefix = locale.split("_")[0];

  return localePrefixScandicUrlMap[localePrefix] || localePrefixScandicUrlMap.en;
};

const useLocale = () => {
  const { info: { locale } } = useContext(StoreInfoContext);

  return {
    localeUrl: getUrlFromLocale(locale),
  };
};

export default useLocale;
