/* @flow */

import React, { useContext, useMemo } from "react";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Field, { SearchableDropdownField } from "components/Field";

type Props = {
  type: "billing" | "shipping",
  className?: string,
  processing: boolean,
};

type ContactInfoProps = {
  processing: boolean,
};

export const ContactInfo = ({ processing }: ContactInfoProps) => {
  const t = useTranslate();

  return (
    <div className={styles.row}>
      <div className={styles.s50}>
        <Field
          disabled
          name="email"
          autoComplete="email"
          label={t("ACCOUNT.EMAIL")}
        />
      </div>
      <div className={styles.s50}>
        <Field
          alwaysValidate
          disabled={processing}
          name="billing.telephone"
          autoComplete="tel"
          label={t("ADDRESS.TELEPHONE")}
        />
      </div>
    </div>
  );
};

const Address = ({ type, className, processing }: Props) => {
  const t = useTranslate();

  const { info: { countries, locale } } = useContext(StoreInfoContext);

  const sortedCountries = useMemo(() => {
    return countries.slice().sort((a, b) => {
      return a.name.localeCompare(
        b.name,
        locale.split("_")[0],
        { sensitivity: "base" }
      );
    });
  }, [countries, locale]);

  return (
    <div className={className}>
      <div className={styles.row}>
        <div className={styles.s50}>
          <Field alwaysValidate disabled={processing} name={`${type}.firstname`} autoComplete="given-name" label={t("ADDRESS.FIRSTNAME")} />
        </div>
        <div className={styles.s50}>
          <Field alwaysValidate disabled={processing} name={`${type}.lastname`} autoComplete="family-name" label={t("ADDRESS.LASTNAME")} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.s50}>
          <Field alwaysValidate disabled={processing} name={`${type}.street.0`} autoComplete="address-line1" label={t("ADDRESS.STREET_1")} />
        </div>
        <div className={styles.s50}>
          <Field alwaysValidate disabled={processing} name={`${type}.street.1`} autoComplete="address-line2" label={t("ADDRESS.STREET_2")} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.s33}>
          <Field alwaysValidate disabled={processing} name={`${type}.postcode`} autoComplete="postal-code" label={t("ADDRESS.POSTCODE")} />
        </div>
        <div className={styles.s33}>
          <Field alwaysValidate disabled={processing} name={`${type}.city`} autoComplete="address-level2" label={t("ADDRESS.CITY")} />
        </div>
        <div className={styles.s33}>
          <SearchableDropdownField
            label={t("ADDRESS.COUNTRY")}
            className={styles.countries}
            name={`${type}.countryCode`}
            placeholder={t("ADDRESS.COUNTRY")}
            autoComplete="country"
            items={sortedCountries} />
        </div>
      </div>
    </div>
  );
};

export default Address;
