/* @flow */

import { useState, useRef, useCallback, useEffect } from "react";

const MIN_SCROLL_DIFF_PIXEL = 10;

const useHeaderScroll = () => {
  const scrollPositionY = useRef(0);
  const [hidden, setHidden] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrolling = useRef();

  const resetScroll = useCallback(() => {
    clearTimeout(scrolling.current);
    scrolling.current = null;
  }, []);

  const setIsScrolledCb = useCallback(() => {
    if (!scrolling.current) {
      scrollPositionY.current = window.scrollY;
    }

    if (scrolling.current &&
      window.scrollY < (scrollPositionY.current - MIN_SCROLL_DIFF_PIXEL)) {
      setHidden(false);
    }
    else if (scrolling.current &&
      window.scrollY > (scrollPositionY.current + MIN_SCROLL_DIFF_PIXEL)) {
      setHidden(true);
    }

    if (window.scrollY > 0) {
      setIsScrolled(true);
    }
    else {
      setIsScrolled(false);
    }

    clearTimeout(scrolling.current);
    scrolling.current = setTimeout(resetScroll, 20);
  }, [setHidden, scrolling, resetScroll, scrollPositionY, setIsScrolled]);

  useEffect(() => {
    window.addEventListener("scroll", setIsScrolledCb, { passive: true });

    return () => {
      window.removeEventListener("scroll", setIsScrolledCb);
    };
  }, [setIsScrolledCb]);

  return [hidden, isScrolled];
};

export default useHeaderScroll;
