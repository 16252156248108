/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";

import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";

type Props = {
  className?: string,
  totalCount?: number,
  brandName: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
};

const BrandHero = ({ className, totalCount, breadcrumbLinks, brandName }: Props) => {
  const t = useTranslate();

  return (
    <div className={cn(styles.block, className)}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.body}>
          <Breadcrumbs
            className={styles.breadcrumbs}
            current={brandName}
            links={breadcrumbLinks}
          />

          {typeof totalCount !== "undefined" &&
            <>
              <h1 className={styles.title}>{brandName}</h1>
              <p className={styles.itemCount}>
                {totalCount > 1 &&
                  t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_PLURAL", { itemsCount: totalCount })
                }
                {totalCount === 1 &&
                  t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_SINGULAR")
                }
                {totalCount === 0 &&
                  t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_EMPTY")
                }
              </p>
            </>
          }
        </div>
      </Wrapper>
    </div>

  );
};

export default BrandHero;
