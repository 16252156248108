/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { SearchRequest, SearchResponse } from "@crossroads/shop-state/search";

import { SEARCH_REQUEST, SEARCH_RESPONSE } from "@crossroads/shop-state/search";
import { search } from "queries";
import { PAGE_SIZE } from "effects/route";

type History = {
  push: (path: string) => void,
};

const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  storage.addEffect({
    effect: async (msg: SearchRequest) => {
      try {
        const { productsBySearch } = await client(search, {
          query: msg.query,
          filter: msg.filters,
          sort: msg.sort,
          page: msg.page !== null ? msg.page : 1,
          pageSize: PAGE_SIZE,
        }, { cache: true });

        return ({
          tag: SEARCH_RESPONSE,
          data: productsBySearch,
        }: SearchResponse);
      }
      catch (e) {
        history.push("/");
        throw e;
      }
    },
    subscribe: { [SEARCH_REQUEST]: true },
  });
};

export default registerClient;
