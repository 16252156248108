/* @flow */

import type { Query, Client } from "@awardit/graphql-ast-client";
import type { Quote } from "shop-state/types";
import type { Data as QuoteData } from "state/quote";

import React, { useState, useContext, useEffect } from "react";
import { useSendMessage } from "crustate/react";
import { getQuoteData } from "state/quote";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { Button } from "@crossroads/ui-components";
import { setQuotePaymentMethodStripe } from "@crossroads/shop-state/quote";
import { loadStripe } from "@stripe/stripe-js";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, Elements, useElements } from "@stripe/react-stripe-js";

import "./styles.scss";

type StripeType = {
  handleCardAction: (clientSecret: string) =>
    Promise<{| error: { message: string} |} | {| paymentIntent: { id: string} |}>,
  createPaymentMethod: ({ type: string, card: any, billing_details: any }) =>
    Promise<{| error: { message: string} |} | {|
      paymentIntent: { id: string},
      paymentMethod: { id: string },
    |}>,
};

type CSSModule = { [key: ?string]: string };

export type CreateStripePaymentIntent = {
  result: string,
  error: string,
  clientSecret: string,
};

type StripeMethod = "CARD" | "BUTTON" | null;

type StripePaymentReq = {
  off: (name: string) => void,
  on: (string, ({
    reject: () => void,
    error?: ?string,
    complete: (i: string) => void,
    paymentMethod: { id: string },
  }) => void) => void,
  canMakePayment: () => Promise<?{ applePay: boolean }>,
  show: () => void,
};

type StripeCardProps = {
  disabled: boolean,
  processing: boolean,
  styles: CSSModule,
  options?: {
    style: {
      base: { [string]: string | { [string]: string } },
      invalid: { [string]: string | { [string]: string } },
    },
  },
};

type ElementEvent = {
  elementType: string,
  error: {
    elementType: string,
    message: string,
  },
};

type StripePaymentProviderProps = {
  children: React$Node,
  quoteData: QuoteData,
  client: Client<{}>,
  storeInfo: StoreInfo,
  confirmStripePaymentIntentQuery: Query<{ intent: string }, any>,
  createStripePaymentIntentQuery: Query<
    { paymentMethod: string },
    { createStripePaymentIntent: CreateStripePaymentIntent }>,
};

type StripeContextType = {
  stripeMethod: StripeMethod,
  setStripeMethod: StripeMethod => void,
  stripePaymentReq: ?StripePaymentReq,
  setStripePaymentReq: StripePaymentReq => void,
  browser: ?string,
  setBrowser: string => void,
  payWithStripe: void => Promise<mixed>,
  quote: Quote,
  storeInfo: StoreInfo,
  stripe: StripeType,
  confirmStripePaymentIntentQuery: Query<{ intent: string }, any>,
  createStripePaymentIntentQuery: Query<
    { paymentMethod: string },
    { createStripePaymentIntent: CreateStripePaymentIntent }>,
};

type StoreInfo = {
  baseCurrencyCode: string,
  name: string,
  defaultCountry: {
    code: string,
  },
};

const formatStripeBillingAddress = (q: Quote) => {
  const a = q.addresses.find(a => a.type === "billing");

  if (!a) {
    return;
  }

  const address = {
    city: (a.city || "").trim(),
    country: a.country.code,
    line1: (a.street[0] || "").trim(),
    line2: "",
    /* eslint-disable camelcase */
    postal_code: (a.postcode || "").replace(/\s/, ""),
    /* eslint-enable camelcase */
  };

  if (a.street[1]) {
    const line2 = a.street[1].trim();

    if (line2.length > 0) {
      address.line2 = line2;
    }
  }

  return {
    email: q.email,
    name: `${a.firstname} ${a.lastname}`,
    phone: a.telephone,
    address,
  };
};

const ApplePayButton = ({ disabled }: { disabled: boolean }) => (
  <button
    lang="en"
    aria-label="Apple Pay"
    role="button"
    type="submit"
    className="apple-pay-button"
    style={{ width: "100%", marginBottom: "1em" }}
    disabled={disabled}
  />
);

export const CODE = "Crossroads_Stripe_PaymentIntents";
const SUBCURRENCY_MULTIPLIER = 100;
const defaultOptions = {
  style: {
    base: {
      color: "#222",
      letterSpacing: "0.025em",
      fontSize: "16px",
      "::placeholder": {
        color: "#555",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

export const paymentRequestButtonShow = (stripePaymentReq: StripePaymentReq) => {
  stripePaymentReq.show();

  return new Promise<{ paymentMethod: { id: string }}>((resolve, reject) => {
    if (!stripePaymentReq) {
      return;
    }

    // Remove event listeners
    stripePaymentReq.off("cancel");
    stripePaymentReq.off("paymentmethod");

    stripePaymentReq.on("cancel", () => {
      reject();
    });

    stripePaymentReq.on("paymentmethod", ev => {
      if (ev.error) {
        ev.complete("fail");
        reject(new Error(ev.error));
      }

      ev.complete("success");
      resolve(ev);
    });
  });
};

const useCompletePayment = () => {
  const elements = useElements();

  return (
    { quote, stripe, client, stripeMethod, paymentMethodID,
      createStripePaymentIntentQuery, confirmStripePaymentIntentQuery }: {
    quote: Quote,
    stripe: StripeType,
    client: Client<{}>,
    stripeMethod: StripeMethod,
    paymentMethodID?: string,
    confirmStripePaymentIntentQuery: Query<{ intent: string }, any>,
    createStripePaymentIntentQuery: Query<
      { paymentMethod: string },
      { createStripePaymentIntent: CreateStripePaymentIntent }>,
  }): Promise<mixed> => {
    return new Promise((resolve, reject) => {
      if (!stripe) {
        return reject(new Error("Stripe.js hasn't loaded yet."));
      }

      const handleResponse = (response: CreateStripePaymentIntent) => {
        switch (response.result) {
          case "success":
            return resolve(new Error(response.result));
          case "errorMessage":
            return reject(new Error(response.error));
          case "error":
            return reject(new Error(response.error));
          case "errorInvalidIntentStatus":
            return reject(new Error(response.error));
          case "recreateIntent":
            return createPayment();
          case "requiresAction":
            if (stripe) {
              stripe.handleCardAction(response.clientSecret).then(stripeResponse => {
                if (stripeResponse.error) {
                  // Translate stripeResponse.error.code?
                  return reject(new Error(stripeResponse.error.message));
                }

                client(
                  confirmStripePaymentIntentQuery, { intent: stripeResponse.paymentIntent.id }
                ).then(({ confirmStripePaymentIntent }) =>
                  confirmStripePaymentIntent && handleResponse(confirmStripePaymentIntent));
              });
            }

            break;
          default:
            break;
        }
      };

      const handlePaymentMethodResponse = payload => {
        if (payload.error) {
          return reject(new Error(payload.error.message));
        }

        const paymentId = payload.paymentMethod.id;

        if (typeof paymentId === "undefined") {
          return reject(new Error("NO_PAYMENT_INTENT_ID"));
        }

        client(createStripePaymentIntentQuery, {
          paymentMethod: paymentId,
        }).then(({ createStripePaymentIntent }) => {
          if (createStripePaymentIntent.result !== "success") {
            if (createStripePaymentIntent.error) {
              return reject(new Error(createStripePaymentIntent.error));
            }
          }

          handleResponse(createStripePaymentIntent);
        });
      };

      const createPayment = () => {
        if (stripe) {
          if (stripeMethod === "CARD") {
            const cardNumberElement = elements.getElement(CardNumberElement);

            if (!cardNumberElement) {
              throw new Error("Missing <CardNumberElement />");
            }

            stripe
              .createPaymentMethod({
                type: "card",
                card: cardNumberElement,
                /* eslint-disable camelcase */
                billing_details: formatStripeBillingAddress(quote),
                /* eslint-enable camelcase */
              })
              .then(payload => handlePaymentMethodResponse(payload));
          }
          else {
            handlePaymentMethodResponse({ paymentMethod: { id: paymentMethodID } });
          }
        }
      };

      createPayment();
    });
  };
};

export const useInitPaymentRequest = () => {
  const { setStripePaymentReq, quote, storeInfo } = useContext(StripeContext);
  const stripe = useStripe();

  useEffect(() => {
    if (!quote) {
      throw new Error("Quote is not loaded.");
    }

    if (!stripe) {
      return;
    }

    const grandTotal = quote.grandTotal.incVat;
    const shopName = storeInfo.name;
    const country = storeInfo.defaultCountry.code;
    const currency = storeInfo.baseCurrencyCode.toLowerCase();

    setStripePaymentReq(stripe.paymentRequest({
      country,
      currency,
      total: {
        label: shopName,
        amount: Math.floor(grandTotal * SUBCURRENCY_MULTIPLIER),
      },
    }));
  }, [stripe, quote, storeInfo, setStripePaymentReq]);
};

export const StripeCardComponent =
({ options = defaultOptions, processing, disabled, styles }: StripeCardProps) => {
  const t = useTranslate();
  const [fieldsFilled, setFieldsFilled] = useState<{ [k: string]: boolean }>({});
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(null);
  const FIELDS = {
    cardNumber: t("STRIPE.ERROR.CARD_NUMBER"),
    cardExpiry: t("STRIPE.ERROR.CARD_EXPIRY"),
    cardCvc: t("STRIPE.ERROR.CARD_CVC"),
  };

  const onChange = (e: ElementEvent) => {
    if (e.error) {
      return setError({ type: [e.elementType], msg: e.error.message });
    }

    if (e.empty) {
      setFieldsFilled({ ...fieldsFilled, [e.elementType]: false });
    }
    else {
      setFieldsFilled({ ...fieldsFilled, [e.elementType]: true });
      setError(null);
    }
  };

  const fieldEmptyError = Object.keys(FIELDS).map(f =>
    !fieldsFilled[f] ? FIELDS[f] : null
  ).filter(x => x)[0];

  return (
    <div className={styles.stripeBlock}>
      <div className={styles.stripeRow}>
        <label className={styles.stripeLabel}>
          {t("STRIPE.CARD_NUMBER")}
          <CardNumberElement {...options} onChange={onChange} />
        </label>
      </div>

      <div className={styles.stripeRow}>
        <div className={styles.stripeSplit}>
          <div className={styles.stripeLeft}>
            <label className={styles.stripeLabel}>
              {t("STRIPE.MM/YY")}
              <CardExpiryElement {...options} onChange={onChange} />
            </label>
          </div>
          <div className={styles.stripeRight}>
            <label className={styles.stripeLabel}>
              {t("STRIPE.CVC")}
              <CardCvcElement {...options} onChange={onChange} />
            </label>
          </div>
        </div>
      </div>

      {error && <div className={styles.stripeError}>{error.msg}</div>}
      {(dirty && fieldEmptyError) && <div className={styles.stripeError}>{fieldEmptyError}</div>}

      <Button
        className={styles.stripeButton}
        variant="primary"
        loading={processing}
        disabled={processing || disabled}
        onClick={e => {
          setDirty(true);
          if (error || fieldEmptyError) {
            e.preventDefault();
          }
        }}
      >
        {processing ? "Processing…" : t("CHECKOUT.PAY_WITH_CARD")}
      </Button>
    </div>
  );
};

const CustomPaymentRequestButton = (
  { styles, disabled }: { styles: CSSModule, disabled: boolean }
) => {
  const t = useTranslate();

  return (
    <div>
      <Button
        variant="primary"
        disabled={disabled}
        className={styles.button}
      >
        {t("PAYMENT.USE_SAVED_CARD")}
      </Button>
    </div>
  );
};

export const StripeButtonComponent = ({ processing, styles, disabled }: {
  processing: boolean, styles: CSSModule, disabled: boolean,
  }) => {
  const [canMakePayment, setCanMakePayment] = useState<boolean>(false);
  const { stripePaymentReq, browser, setBrowser, quote } = useContext(StripeContext);
  const stripe = useStripe();
  const t = useTranslate();

  useEffect(() => {
    if (stripePaymentReq) {
      stripePaymentReq.canMakePayment().then(result => {
        if (result) {
          setBrowser(result.applePay ? "apple" : "other");
          setCanMakePayment(Boolean(result));
        }
      });
    }
  }, [quote, stripePaymentReq, stripe, setBrowser]);

  if (!canMakePayment) {
    return null;
  }

  if (!quote) {
    throw new Error("Quote is missing");
  }

  if (processing) {
    return <Button loading className={styles.stripeButton} variant="primary">&nbsp;</Button>;
  }

  return (
    <div>
      {browser === "apple" ?
        <ApplePayButton disabled={disabled} /> :
        <CustomPaymentRequestButton disabled={disabled} variant="primary" browser={browser} styles={styles} />
      }
      <div className={styles.orParagraph}>{t("OR")}</div>
    </div>
  );
};

export const StripeContext = React.createContext<StripeContextType>({});

export const StripePaymentProvider = (props: StripePaymentProviderProps) => {
  const sendMessage = useSendMessage();
  const { quoteData } = props;
  const quote = getQuoteData(quoteData);
  const { payment } = quote ? quote : {};
  const paymentCode = payment?.code;
  const stripeKey = payment && payment.code === CODE ? payment.publishableKey : null;
  const [stripePromise, setStripePromise] = useState(null);
  const { info: { locale } } = useContext(StoreInfoContext);

  if (!stripeKey && paymentCode === CODE) {
    throw new Error("Could not load Stripe: No publishableKey found in quote.payment");
  }

  useEffect(() => {
    if (paymentCode === "free") {
      return;
    }

    if (!stripePromise && stripeKey) {
      setStripePromise(loadStripe(stripeKey));
    }
  }, [paymentCode, stripePromise, stripeKey]);

  useEffect(() => {
    // Payment methods are deactivated for SE and ROTW, but for some
    // unknown reason setQuotePaymentMethodStripe runs over and over
    // again for ROTW, hence the ugly en_GB statement
    if (locale !== "en_GB" && paymentCode !== "free" && !stripeKey && quoteData.state === "LOADED") {
      sendMessage(setQuotePaymentMethodStripe());
    }
  }, [paymentCode, sendMessage, stripeKey, quoteData.state]);

  return (
    <Elements options={{ locale: locale.split("_")[0] }} stripe={stripePromise}>
      <StripePaymentProviderInner {...props} />
    </Elements>
  );
};

const StripePaymentProviderInner = ({ client, quoteData, storeInfo, confirmStripePaymentIntentQuery,
  createStripePaymentIntentQuery, children }: StripePaymentProviderProps) => {
  const quote = getQuoteData(quoteData) || {};
  const [browser, setBrowser] = useState<string | null>(null);
  const [stripePaymentReq, setStripePaymentReq] = useState(null);
  const stripe = useStripe();
  const [stripeMethod, setStripeMethod] = useState<StripeMethod>(null);
  const completePayment = useCompletePayment();

  const payWithStripe = () => {
    if (!stripePaymentReq) {
      throw new Error("Stripe not loaded");
    }

    if (stripeMethod === "CARD") {
      return completePayment({
        quote, stripe, client, stripeMethod,
        confirmStripePaymentIntentQuery,
        createStripePaymentIntentQuery,
      });
    }

    // Button payment
    return paymentRequestButtonShow(stripePaymentReq).then(ev => {
      return completePayment({
        quote, stripe, client, stripeMethod, paymentMethodID: ev.paymentMethod.id,
        confirmStripePaymentIntentQuery, createStripePaymentIntentQuery,
      });
    });
  };

  return (
    <StripeContext.Provider value={{
      confirmStripePaymentIntentQuery,
      createStripePaymentIntentQuery,
      stripeMethod,
      setStripeMethod,
      stripePaymentReq,
      setStripePaymentReq,
      browser,
      setBrowser,
      quote,
      payWithStripe,
      stripe,
      storeInfo,
    }}
    >
      {children}
    </StripeContext.Provider>
  );
};
