/* @flow */

import React from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";

const NotFound = () => {
  const t = useTranslate();

  return (
    <Wrapper className={styles.block}>
      <Helmet
        title={t("404.TITLE")}
      />

      <h1>{t("404.HEADING")}</h1>
      <p dangerouslySetInnerHTML={{ __html: t("404.SUBHEADING") }} className={styles.lead} />
    </Wrapper>
  );
};

export default NotFound;
