/* @flow */

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import Button from "components/Button";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

type Props = {
  children: React$Node,
  smallImage?: { x1: string, x2: string },
  mediumImage?: { x1: string, x2: string },
  largeImage: { x1: string, x2: string, x3: string },
  CTA: string,
  alt: string,
  link: string,
  modifier: string,
  buttonModifier: string,
  className?: string,
  wrapperClass?: string,
  wrapContent?: boolean,
};

const Hero = ({
  className,
  wrapperClass,
  children,
  smallImage = { x1: "", x2: "" },
  mediumImage = { x1: "", x2: "" },
  largeImage,
  alt,
  link,
  CTA,
  modifier,
  buttonModifier,
  wrapContent = false,
}: Props) => {
  const Wrap = wrapContent ? Wrapper : "div";

  return (
    <Link
      to={link}
      title={alt}
      className={cn(
        styles.block,
        { [styles.block__wrap]: wrapContent },
        className
      )}
      style={{ backgroundColor: modifier }}
    >
      <div className={styles.smallImage} style={{ backgroundImage: `url(${smallImage.x2})` }} />
      <div className={styles.mediumImage} style={{ backgroundImage: `url(${mediumImage.x2})` }} />
      <div className={styles.largeImage} style={{ backgroundImage: `url(${largeImage.x2})` }} />
      <Wrap className={cn(styles.contentWrapper, wrapperClass)}>
        <div className={styles.children}>{children}</div>
        <Button
          variant={buttonModifier}
          className={cn(styles.cta, styles["cta__" + buttonModifier])}
        >
          {CTA}
        </Button>
      </Wrap>
    </Link>
  );
};

export default Hero;
