/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";
import styles from "./styles.scss";
import ScandicLogo from "../scandic_logo.svg";

type Props = {
  className?: string,
};

const Logo = ({ className, ...props }: Props) => {
  const t = useTranslate();

  return (
    <div {...props} className={cn(styles.block, className)}>
      <ScandicLogo
        alt={t("LOGO.ALT")}
        title={t("LOGO.TITLE")}
        className={styles.logo}
      />
    </div>
  );
};

export default Logo;
