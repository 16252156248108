/* @flow */

import type { ProductCardProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import cn from "classnames";
import ProductCard, { DummyCard } from "components/ProductCard";
import { useVisibilityObservable } from "@crossroads/ui-components";
import styles from "./styles.scss";
import React, { memo } from "react";

type Props = {
  children?: React$Node,
  products?: $ReadOnlyArray<?ProductCardProduct>,
  heading?: string,
  productsPerRow?: 4 | 2,
  loading?: boolean,
  listName?: string,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

type ItemProps = {
  loading?: boolean,
  product: ProductCardProduct,
  idx?: number,
  listName?: string,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

type DummyItemProps = {
  idx?: number,
};

export const ListItem = memo<ItemProps>(({
  product, breadcrumbLinks, idx, loading, listName,
}: ItemProps) => {
  const el = useVisibilityObservable();

  if (loading === true) {
    return <ListItemDummy idx={idx} />;
  }

  return (
    <div ref={el} className={styles.item} data-idx={idx}>
      <ProductCard
        className={styles.itemInner}
        product={product}
        breadcrumbLinks={breadcrumbLinks}
        list={listName}
        position={idx}
      />
    </div>
  );
});

export const ListItemDummy = memo<DummyItemProps>(({ idx }: DummyItemProps) => {
  const el = useVisibilityObservable();

  return (
    <div ref={el} className={styles.item} data-idx={idx}>
      <div className={styles.itemInner}>
        <DummyCard />
      </div>
    </div>
  );
});

const ProductList = ({
  products,
  children,
  breadcrumbLinks,
  heading,
  listName,
  productsPerRow = 4,
  loading = false,
}: Props) => {
  return (
    <div>
      {heading &&
        <h2>{heading}</h2>
      }
      <div className={cn(
        styles.block,
        [styles["block__perRow" + productsPerRow]]
      )}
      >
        {products ? products.map((x, i) =>
          x ?
            <ListItem
              key={x.sku}
              loading={loading}
              breadcrumbLinks={breadcrumbLinks}
              listName={listName}
              product={x}
              idx={i} /> :
            <ListItemDummy key={i} className={styles.itemInner} />
        ) : children}
      </div>
    </div>
  );
};

export default ProductList;
