/* @flow */

import type { QuoteItem, ProductSubset } from "shop-state/types";

import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";

import { useTranslate } from "@awardit/react-use-translate";
import { Link, withRouter } from "react-router-dom";
import { QuoteData } from "data";
import { useSendMessage, useData } from "crustate/react";
import { AnalyticsContext } from "@crossroads/analytics";
import { removeQuoteItem, updateQuoteItemQty } from "@crossroads/shop-state/quote";
import { Dialogue } from "@crossroads/ui-components";
import Button from "components/Button";
import Container from "components/CheckoutView/Container";
import QtyPicker from "components/QtyPicker";
import { CartSummary } from "components/CartSummary";
import PriceSplit from "components/PriceSplit";
import { addToWishlist } from "state/wishlist-toggle";
import { pointsPriceByID } from "helpers/points";
import TrashIcon from "icons/trash.svg";
import CloseIcon from "icons/close-small.svg";

import styles from "./styles.scss";

const Cart = () => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const quote = useData(QuoteData);
  const gaContext = useContext(AnalyticsContext);
  const [open, setOpen] = useState(true);
  const [activeItem, setActiveItem] = useState<QuoteItem | null>(null);
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);

  useEffect(() => {
    if (quote.data) {
      gaContext.viewedCart(quote.data.grandTotal.incVat, quote.data.items.map(item => {
        return {
          sku: item.product.sku ?? item.product.sku,
          name: item.product.name,
          price: {
            exVat: item.rowTotal.exVat,
            incVat: item.rowTotal.incVat,
            vat: item.rowTotal.incVat - item.rowTotal.exVat,
          },
          qty: item.qty,
          attributes: {
            manufacturer: item.product.attributes.manufacturer,
          },
          categories: item.product.categories,
        };
      }));
    }
  }, []);

  if (quote.state === "LOADING") {
    return null;
  }

  const remove = (item: QuoteItem) => {
    sendGAEvent({
      sku: item.product.sku,
      name: item.product.name,
      price: item.product.price,
      qty: item.qty,
      attributes: {
        manufacturer: item.product.attributes.manufacturer,
      },
      categories: item.product.categories,
    }, -item.qty);

    sendMessage(removeQuoteItem(item.itemBuyRequest));
  };

  const confirmRemove = (item: QuoteItem) => {
    setConfirmDialogueOpen(true);
    setActiveItem(item);
  };

  const moveToWishlist = ({ itemBuyRequest, buyRequest, sku }) => {
    if (!buyRequest) {
      return;
    }

    sendMessage(addToWishlist(buyRequest, sku));
    sendMessage(removeQuoteItem(itemBuyRequest));
  };

  const sendGAEvent = (product: ProductSubset, diff) => {
    gaContext.registerModifyCart({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: Math.abs(diff),
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    }, diff > 0 ? "add_to_cart" : "remove_from_cart", product.price?.incVat);
  };

  const processingItem = typeof quote.processingItem !== "undefined" ? quote.processingItem : null;

  return (
    <Container
      right={
        <div>
          <CartSummary open={open} setOpen={setOpen}>
            <Button variant="primary" to="/checkout/placeorder">
              {t("CART.TO_CHECKOUT")}
            </Button>
          </CartSummary>
        </div>
      }
    >
      {confirmDialogueOpen &&
      <Dialogue
        className={styles.confirmDialogue}
        open={confirmDialogueOpen}
        setOpen={() => setConfirmDialogueOpen(false)}
        title={t("CART.REMOVE_PRODUCT")}
        closeIcon={<div className={styles.closeIconWrapper}><CloseIcon /></div>}
        primaryAction={t("CART.REMOVE_APPROVE")}
        secondaryAction={t("CONFIRM_DIALOG.CANCEL")}
        onPrimaryAction={() => {
          if (activeItem) {
            setConfirmDialogueOpen(false);
            remove(activeItem);
          }
        }}
        onSecondaryAction={() => setConfirmDialogueOpen(false)}
      >
        {t("CART.CONFIRM_TEXT", {
          itemName: `${activeItem?.product.name || ""}, ${activeItem?.product.attributes.manufacturer || ""}`,
        })}
      </Dialogue>}
      <div className={styles.block}>
        {(quote.data?.items || []).map(x => {
          const product = x.configOption ? {
            ...x.product,
            ...x.configOption.product,
            categories: x.product.categories,
          } : x.product;

          const pointPayment = pointsPriceByID(x.availablePointPayments, "scandic");

          if (!pointPayment) {
            return null;
          }

          return (
            <div
              key={x.itemBuyRequest}
              className={
                cn(styles.item, {
                  [styles.processing]: processingItem === x.itemBuyRequest,
                  [styles.disabled]: quote.state === "UPDATING_ITEM",
                })}
            >
              <div className={styles.left}>
                <img
                  className={styles.image}
                  alt={product.name}
                  src={product.attributes.image?.x1}
                />
                <div className={styles.info}>
                  <Link
                    to={{
                      pathname: product.url,
                      state: { hint: {
                        type: "product",
                        product,
                        image: product.attributes.image?.x1,
                      } },
                    }}
                    className={styles.name}
                  >
                    {product.name}
                  </Link>
                  <p className={styles.brand}>{product.attributes.manufacturer}</p>
                  {x.bundleOptions &&
                    <ul className={styles.bundleOptions}>
                      {x.bundleOptions.map(o => o.products.map(p =>
                        <li key={o.title}>{p.product.name}</li>
                      ))}
                    </ul>
                  }
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.top}>
                  <QtyPicker
                    className={styles.qtyPicker}
                    value={x.qty}
                    min={0}
                    setValue={(v: number) => {
                      if (v > 0) {
                        sendMessage(updateQuoteItemQty(x.itemBuyRequest, v));

                        sendGAEvent({
                          ...product, qty: Math.abs(v - x.qty),
                        }, v - x.qty);
                      }
                      else {
                        confirmRemove(x);
                      }
                    }} />

                  <PriceSplit className={styles.price} pointsPrice={pointPayment} />
                </div>
                <div className={styles.bottom}>
                  {product.buyRequest &&
                    <Button
                      className={cn("link", styles.link)}
                      onClick={() => moveToWishlist({
                        itemBuyRequest: x.itemBuyRequest,
                        buyRequest: product.buyRequest,
                        sku: product.sku,
                      })}
                    >
                      {t("CART.MOVE_TO_WISHLIST")}
                    </Button>
                  }
                  <Button className={cn("link", styles.link, styles.remove)} onClick={() => confirmRemove(x)}>
                    <TrashIcon /> {t("CART.REMOVE_PRODUCT")}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default withRouter(Cart);
