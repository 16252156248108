/* @flow */

import { useCallback, useState, useEffect } from "react";
import { debounce } from "diskho";

type RefType = {|
  current: ?React$ElementRef<'div'>,
|};

const useFixedFilterBar = (
  filterbarRef: RefType,
  filterbarPlaceholderRef: RefType,
  headerHeight: number) => {
  const [filterbarFixed, setFilterbarFixed] = useState<boolean>(false);

  const onScroll = useCallback(debounce(() => {
    if (filterbarRef.current && filterbarPlaceholderRef.current) {
      const filterbarTop = filterbarRef.current &&
        filterbarRef.current.getBoundingClientRect().top;
      const placeholderTop = filterbarPlaceholderRef.current &&
        filterbarPlaceholderRef.current.getBoundingClientRect().top;

      if (!filterbarFixed && filterbarTop < headerHeight) {
        setFilterbarFixed(true);
      }
      else if (filterbarFixed && filterbarTop <= placeholderTop) {
        setFilterbarFixed(false);
      }
    }
  }, 10), [headerHeight, filterbarRef.current, filterbarPlaceholderRef.current, filterbarFixed]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  return filterbarFixed;
};

export default useFixedFilterBar;

