/* @flow */

import type { Location } from "react-router";
import type { Category, HintCategory } from "shop-state/types";

import React, { useRef, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import { getCategoryMeta } from "helpers/get-meta";
import CategoryHero, { CategoryHeroHint } from "components/CategoryHero";
import { useTranslate } from "@awardit/react-use-translate";

import useFixedFilterBar from "helpers/use-fixed-filterbar";
import useUI from "helpers/use-ui";
import useHeaderHeight from "helpers/use-header-height";
import { useData, useSendMessage } from "crustate/react";
import { RouteData } from "data";
import cn from "classnames";
import { load as loadRoute } from "@crossroads/shop-state/route";
import useFilter from "helpers/use-filter";
import Filterbar from "components/Filterbar";
import OffCanvasFilterMenu from "components/OffCanvasFilterMenu";
import PaginatedProductList from "components/PaginatedProductList";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import { useLocation } from "react-router-dom";
import { PAGE_SIZE } from "effects/route";

import styles from "./styles.scss";

type CategoryProps = {
  category: Category,
  location: Location,
};

type HintCategoryProps = {
  category: HintCategory,
};

// TODO: use Pagination instead on server
const CategoryView = ({ category, location }: CategoryProps) => {
  const t = useTranslate();
  const { info: { usePoints } } = useContext(StoreInfoContext);
  const filterbarRef = useRef();
  const filterbarPlaceholderRef = useRef();
  const currentHeaderHeight = useHeaderHeight(styles);
  const { headerHidden } = useUI();
  const route = useData(RouteData);
  const updating = route.state === "UPDATING";
  const sendMessage = useSendMessage();
  const listName = location.pathname.slice(1);
  const { pathname, search } = useLocation();
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });

  const useFilterStateConfig = {
    loading: updating,
    productList: category.products,
    usePoints,
    load: location => {
      sendMessage(loadRoute(location));
    },
    sortValues: [
      {
        code: `position_asc`,
        label: t("FILTER.SORT_POSITION"),
      },
      {
        code: `price_desc`,
        label: t("FILTER.SORT_PRICE_DESC"),
      },
      {
        code: `price_asc`,
        label: t("FILTER.SORT_PRICE_ASC"),
      },
      {
        code: `name_asc`,
        label: t("FILTER.SORT_NAME"),
      },
    ],
  };

  const filterState = useFilter(useFilterStateConfig);

  const filterbarFixed = useFixedFilterBar(
    filterbarRef,
    filterbarPlaceholderRef,
    currentHeaderHeight);

  const meta = getCategoryMeta(category);
  const numPages = Math.ceil(category.products.totalCount / PAGE_SIZE);

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />
      <CategoryHero category={category} breadcrumbLinks={breadcrumbLinks} />
      <Filterbar
        ref={filterbarRef}
        className={cn(
          styles.filterbar,
          { [styles.filterbar__fixed]: filterbarFixed },
          { [styles.filterbar__fixed_header_hidden]: filterbarFixed && headerHidden }
        )}
        filterState={filterState}
      />
      <div
        ref={filterbarPlaceholderRef}
        className={cn(
          styles.filterbar_placeholder,
          { [styles.filterbar_placeholder__hidden]: !filterbarFixed })} />

      <PaginatedProductList
        updating={updating}
        numPages={numPages}
        items={category.products.items}
        productList={category.products}
        listName={listName}
        breadcrumbLinks={[...breadcrumbLinks, {
          pathname,
          search,
          title: category.name,
          hint: {
            name: category.name,
          },
        }]}
        category={category.name}
      />

      <OffCanvasFilterMenu filterState={filterState} />
    </div>
  );
};

export const HintCategoryView = ({ category }: HintCategoryProps) => {
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });

  return (
    <div className={styles.block}>
      <Helmet
        title={category.name}
      />
      <CategoryHeroHint category={category} breadcrumbLinks={breadcrumbLinks} />
      <Wrapper className={styles.listWrapper}>
        <ProductList products={[null, null, null, null, null, null, null, null]} />
      </Wrapper>
    </div>
  );
};

export default CategoryView;
