/* @flow */

import React, { useCallback, useEffect, useRef } from "react";
import { useData, useSendMessage } from "crustate/react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { login, abortSSOLogin, fetchLoginToken } from "state/customer";
import { parseParams } from "helpers/location-search-string";
import { useTranslate } from "@awardit/react-use-translate";
import Spinner from "components/Spinner";
import { setMode, MODE } from "state/view-mode";
import { CustomerData, ViewModeData } from "data";

import { url } from "config";

import styles from "./styles.scss";

const LoginModal = ({ modalContainer }: { modalContainer: React$ElementRef<any> }) => {
  const t = useTranslate();
  const history = useHistory();
  const location = useLocation();
  const customerData = useData(CustomerData);
  const viewMode = useData(ViewModeData);
  const origin = useRef("");
  const sendMessage = useSendMessage();
  const baseOptions = `&for_origin=${origin.current}`;
  const visibleOptions = `&forceAuthN=true&ui_locales=${t("LOCALE.ISO.639-1")}`;
  const hiddenOptions = `&prompt=none`;
  const loginUrl = customerData.state === "FETCHED_LOGIN_TOKEN" ||
    customerData.state === "LOGGING_IN" ? customerData.url : "";
  const showIframe = viewMode === MODE.LOGIN;

  useEffect(() => {
    const modalRef = modalContainer.current;
    if (showIframe) {
      disableBodyScroll(modalRef);
    }
    else {
      enableBodyScroll(modalRef);
    }
  }, [showIframe]);

  useEffect(() => {
    if (customerData.state === "NOT_LOGGED_IN") {
      sendMessage(fetchLoginToken());
    }

    origin.current = window.location.origin;
  }, []);

  useEffect(() => {
    if (viewMode === MODE.LOGIN) {
      sendMessage(fetchLoginToken());
    }
  }, [viewMode]);

  useEffect(() => {
    if (customerData.state === "LOGGED_IN" && viewMode === MODE.LOGIN) {
      sendMessage(setMode(MODE.NORMAL));
    }
  }, [customerData]);

  const close = useCallback(() => {
    sendMessage(setMode(MODE.NORMAL));
    sendMessage(abortSSOLogin());

    if (location.pathname.includes("checkout")) {
      history.push("/");
    }
  }, [history, sendMessage]);

  useEffect(() => {
    const loginCallback = window.addEventListener("message", event => {
      if (event.origin === url && event.data.type === "loginCallback") {
        const credentials = parseParams(event.data.params);

        if (typeof credentials.code === "string" && typeof credentials.state === "string") {
          return sendMessage(login(credentials.code, credentials.state));
        }

        sendMessage(abortSSOLogin());
      }

      if (event.data === "closeLoginModal") {
        close();
      }
    });

    return () => window.removeEventListener("message", loginCallback);
  }, [sendMessage, close]);

  if (customerData.state !== "FETCHED_LOGIN_TOKEN" && customerData.state !== "LOGGING_IN") {
    return null;
  }

  if (showIframe) {
    return (
      <div className={styles.block}>
        <div className={styles.container}>
          {customerData.state === "FETCHED_LOGIN_TOKEN" &&
            <iframe
              className={styles.iframe}
              src={loginUrl + baseOptions + visibleOptions}
              title="Scandic SSO"
              name="sso"
            />
          }

          <div className={styles.spinnerContainer}>
            <Spinner className={styles.spinner} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <iframe
      src={loginUrl + baseOptions + hiddenOptions}
      style={{
        width: 0,
        height: 0,
        border: "none",
        position: "absolute",
        display: "none",
      }}
    />
  );
};

export default LoginModal;
