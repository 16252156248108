/* @flow */

import type { Location } from "react-router";
import type { Mode } from "state/view-mode";

import React, { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import usePrevious from "helpers/use-previous";
import styles from "./styles.scss";
import AppHeaderSmall from "components/AppHeader/Small";
import AppHeaderLarge from "components/AppHeader/Large";
import { CustomerData, QuoteData, ViewModeData } from "data";
import { useData, useSendMessage } from "crustate/react";
import { setMode, MODE } from "state/view-mode";
import useUI from "helpers/use-ui";

type Props = {
  className: string,
  onCheckout: boolean,
  onSuccess: boolean,
  location: Location,
};

const AppHeader = ({ onCheckout, onSuccess, ...props }: Props) => {
  const sendMessage = useSendMessage();
  const location = useLocation();
  const { setHamburgerOpen, setSubNavOpen, headerHidden, pageIsScrolled } = useUI();
  const customerData = useData(CustomerData);
  const viewMode = useData(ViewModeData);
  const quoteData = useData(QuoteData);
  const prevQuoteData = usePrevious(quoteData);
  const [lastVisited, setLastVisited] = useState<string | null>(null);
  const customer = customerData.state === "LOGGED_IN" ||
                   customerData.state === "UPDATING" ? customerData.data : null;
  const customerLoggingIn = ["LOGGING_IN", "FETCHING_LOGIN_TOKEN", "FETCHED_LOGIN_TOKEN"].includes(customerData.state);

  const setViewMode = useCallback((mode: Mode) => {
    sendMessage(setMode(mode));
  }, [sendMessage]);

  useEffect(() => {
    if (!location.pathname.includes("checkout")) {
      setLastVisited(location.pathname);
    }
  }, [location]);

  // Close menus when switching page
  useEffect(() => {
    setViewMode(MODE.NORMAL);
    setSubNavOpen(false);
    setHamburgerOpen(false);
  }, [setViewMode, setSubNavOpen, setHamburgerOpen, location.pathname]);

  // Open mini cart when adding to cart
  useEffect(() => {
    if (location.pathname === "/checkout" ||
        !prevQuoteData ||
        (quoteData.state !== "LOADED" || prevQuoteData.state !== "ADDING_ITEM")) {
      return;
    }

    const count = quoteData.data.items.reduce((a, { qty }) => a + qty, 0);
    const prevCount = prevQuoteData.data.items.reduce((a, { qty }) => a + qty, 0);

    if (count > prevCount) {
      setViewMode(MODE.CART);
      setSubNavOpen(false);
      setHamburgerOpen(false);
    }
  }, [setViewMode, prevQuoteData, quoteData, location, setSubNavOpen, setHamburgerOpen]);

  return (
    <div>
      <div
        className={styles.container}
      >
        <AppHeaderSmall
          {...props}
          mode={viewMode}
          setMode={setViewMode}
          customer={customer}
          customerLoggingIn={customerLoggingIn}
          hidden={headerHidden && pageIsScrolled && viewMode === MODE.NORMAL && !onCheckout}
          lastVisited={lastVisited}
          onCheckout={onCheckout}
        />
        <AppHeaderLarge
          {...props}
          mode={viewMode}
          setMode={setViewMode}
          customer={customer}
          customerLoggingIn={customerLoggingIn}
          hidden={headerHidden && pageIsScrolled && viewMode === MODE.NORMAL && !onCheckout}
          active={pageIsScrolled || viewMode !== MODE.NORMAL}
          lastVisited={lastVisited}
          onCheckout={onCheckout}
          onSuccess={onSuccess}
        />
      </div>
    </div>
  );
};

export default AppHeader;
