module.exports = {
  host: "localhost",
  port: 9127,
  url: "https://shop.scandichotels.com",
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: [
    { store: "se", currencyCode: "SEK", accounts: ["UA-59171748-7", "UA-177450375-2", "UA-177450375-1"] },
    { store: "no", currencyCode: "NOK", accounts: ["UA-59171748-7", "UA-177450375-3", "UA-177450375-1"] },
    { store: "dk", currencyCode: "DKK", accounts: ["UA-59171748-7", "UA-177450375-4", "UA-177450375-1"] },
    { store: "fi", currencyCode: "EUR", accounts: ["UA-59171748-7", "UA-177450375-5", "UA-177450375-1"] },
    { store: "en", currencyCode: "EUR", accounts: ["UA-59171748-7", "UA-177450375-6", "UA-177450375-1"] },
  ],
  isProduction: true,
  proxyHost: null,
  loginHost: {
    sv_SE: "https://login.scandichotels.se",
    nb_NO: "https://login.scandichotels.no",
    fi_FI: "https://login.scandichotels.fi",
    da_DK: "https://login.scandichotels.dk",
    en_GB: "https://login.scandichotels.com",
  },
  cspUri: "https://csp-report.browser-intake-datadoghq.eu/api/v2/logs?dd-api-key=pub552607268219ad5b8d2647030dd2f46f&dd-evp-origin=content-security-policy&ddsource=csp-report&ddtags=service%3Ascandichotels.com%2Cenv%3Aproduction",
};
