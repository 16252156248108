/* @flow */

import type { FormState } from "components/CheckoutView/Checkout";

import styles from "./styles.scss";
import React, { useEffect, useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import Adress, { ContactInfo } from "components/Address";
import { Button, Foldable } from "@crossroads/ui-components";
import { CheckboxField } from "components/Field";
import { Box, BoxHeader, BoxBody } from "components/Box";
import { StoreInfoContext } from "entrypoint/shared";

type Props = {
  state: FormState,
  editing: boolean,
  setEditing: boolean => void,
  processing: boolean,
};

// eslint-disable-next-line complexity
const Addresses = ({ state, editing, setEditing, processing }: Props) => {
  const t = useTranslate();
  const { info: { countries } } = useContext(StoreInfoContext);

  const onClickToggle = (e: MouseEvent) => {
    e.preventDefault();

    if (processing) {
      return;
    }

    setEditing(!editing);
  };

  const { billing, shipping, shipToOtherAddress } = state;

  const billingCountry = billing ?
    countries.find(c => c.code === billing.countryCode) :
    null;

  const shippingCountry = shipping ?
    countries.find(c => c.code === shipping.countryCode) :
    null;

  // Update open state of Foldable to re-calc new height
  useEffect(() => {
    if (editing) {
      setEditing(false);
      setTimeout(() => {
        setEditing(true);
      }, 0);
    }
  }, [state.shipToOtherAddress]);

  return (
    <div className={styles.block}>
      <Box>
        <BoxHeader>
          <h2>{t("CHECKOUT.ADDRESSES_TITLE")}</h2>
          {editing ?
            <Button className={styles.toggle} variant="blank" size="small" onClick={onClickToggle}>{t("CHECKOUT.ADDRESS_MINIMIZE")}</Button> :
            <Button className={styles.toggle} variant="blank" size="small" onClick={onClickToggle}>{t("CHECKOUT.EDIT_ADDRESS")}</Button>
          }
        </BoxHeader>
        <Foldable open={!editing}>
          <BoxBody>
            <div>
              <div className={styles.minimizedContact}>
                <p>
                  <span>{`${t("ADDRESS.TELEPHONE")}: `}</span>{billing.telephone || <span className={styles.error}>{t("ADDRESS.TELEPHONE_MISSING")}</span>}
                </p>
                <p>
                  <span>{`${t("ACCOUNT.EMAIL")}: `}</span>{state.email || <span className={styles.error}>{t("ADDRESS.EMAIL_MISSING")}</span>}
                </p>
              </div>
              {shipToOtherAddress &&
                <p className={styles.addressTitle}>
                  {`${t("ADDRESS.BILLING_ADDRESS")}:`}
                </p>
              }
              <p className={styles.minimizedInfo}>

                {
                  billing.firstname && billing.lastname ?
                    billing.firstname + " " + billing.lastname :
                    <span className={styles.error}>{t("ADDRESS.NAME_MISSING")}</span>
                }
                <br />

                {billing.street && Object.values(billing.street).length > 0 ?
                  Object.values(billing.street).join(", ") :
                  <span className={styles.error}>{t("ADDRESS.STREET_MISSING")}</span>
                }
                <br />
                {billing.postcode || <span className={styles.error}>{t("ADDRESS.POSTCODE_MISSING")}</span>}
                &nbsp;{billing.city || <span className={styles.error}>{t("ADDRESS.CITY_MISSING")}</span>}
                <br />
                {(billingCountry && billingCountry.name) || <span className={styles.error}>{t("ADDRESS.COUNTRY_MISSING")}</span>}
              </p>

              {shipToOtherAddress &&
                <div>
                  <p className={styles.addressTitle}>
                    {`${t("ADDRESS.SHIPPING_ADDRESS")}:`}
                  </p>
                  <p className={styles.minimizedInfo}>
                    {
                      shipping.firstname && shipping.lastname ?
                        shipping.firstname + " " + shipping.lastname :
                        <span className={styles.error}>{t("ADDRESS.NAME_MISSING")}</span>
                    }
                    <br />

                    {shipping.street && Object.values(shipping.street).length > 0 ?
                      Object.values(shipping.street).join(", ") :
                      <span className={styles.error}>{t("ADDRESS.STREET_MISSING")}</span>
                    }
                    <br />
                    {shipping.postcode || <span className={styles.error}>{t("ADDRESS.POSTCODE_MISSING")}</span>}
                    &nbsp;{shipping.city || <span className={styles.error}>{t("ADDRESS.CITY_MISSING")}</span>}
                    <br />
                    {(shippingCountry && shippingCountry.name) || <span className={styles.error}>{t("ADDRESS.COUNTRY_MISSING")}</span>}
                  </p>
                </div>
              }
            </div>
          </BoxBody>
        </Foldable>
        <Foldable
          className={styles.editAddresses}
          open={editing}
        >
          <BoxBody>
            <div className={styles.row}>
              <ContactInfo processing={processing} />
            </div>
            <div className={styles.row}>
              {shipToOtherAddress &&
                <h2>{t("CHECKOUT.ADDRESS.BILLING")}</h2>
              }
              <Adress
                processing={processing}
                type="billing"
              />
            </div>

            <CheckboxField name="shipToOtherAddress" checked={state.shipToOtherAddress}>
              {t("CHECKOUT.ADDRESS.SHIP_TO_OTHER_ADDRESS")}
            </CheckboxField>

            {shipToOtherAddress &&
              <div className={styles.row}>
                <h2>{t("CHECKOUT.ADDRESS.SHIPPING")}</h2>
                <Adress processing={processing} type="shipping" />
              </div>
            }
          </BoxBody>
        </Foldable>
      </Box>
    </div>
  );
};

export default Addresses;
