/* @flow */

import type { Level, Content } from "@crossroads/shop-state/messages";

import React, { useState, useEffect } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import { removeMessage } from "@crossroads/shop-state/messages";
import { MessagesData } from "data";
import { useData, useSendMessage } from "crustate/react";
import { Foldable } from "@crossroads/ui-components";
import RemoveIcon from "icons/cross.svg";

type MessageProps = {
  content: Content,
  level: Level,
  translated: boolean,
  onRemove: () => void,
};

const MESSAGE_TRANSITION = 400;
const LIFETIME = 5000;

const Message = ({ content, level, translated, onRemove }: MessageProps) => {
  const t = useTranslate();
  const [deleting, setDeleting] = useState(false);
  let text = "";

  const close = () => {
    setDeleting(true);
    setTimeout(() => {
      setDeleting(false);
      onRemove();
    }, MESSAGE_TRANSITION);
  };

  useEffect(() => {
    setTimeout(close, LIFETIME);
  }, []);

  if (!translated) {
    text = typeof content === "string" ?
      t("STATUS_CODE." + content) :
      t("STATUS_CODE." + content.translationKey, content.variable);
  }

  return (
    <Foldable
      open={!deleting}
      className={cn(
        styles.message,
        styles[level],
        { [styles.deleting]: deleting }
      )}
      onClick={close}
    >
      <div className={styles.itemInner}>
        <div className={styles.itemPadding}>
          <RemoveIcon className={styles.icon} />
          <p>
            {translated && typeof content === "string" ? content : text}
          </p>
        </div>
      </div>
    </Foldable>
  );
};

const SystemMessages = () => {
  const sendMessage = useSendMessage();
  const messages = useData(MessagesData);

  return (
    <div className={styles.block}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.messages}>
          {messages.map(x => (
            <Message
              {...x}
              key={x.id}
              onRemove={() => {
                if (x.id !== undefined) {
                  sendMessage(removeMessage(x.id));
                }
              }}
            />
          ))}
        </div>
      </Wrapper>
    </div>
  );
};

export default SystemMessages;
