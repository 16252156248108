/* @flow */

import type { History, Location } from "react-router";
import type { Node as ReactNode } from "react";
import React, { useContext, useRef, useState } from "react";
import { usePreserveScrollPosition } from "react-router-on-navigation";
import { Link } from "react-router-dom";
import useLocale from "helpers/use-locale";
import useCustomer from "helpers/use-customer";
import AppHeader from "components/AppHeader";
import LoginModal from "components/LoginModal";
import Notice from "components/Notice";
import Button from "components/Button";
import CookieConsent from "components/CookieConsent";
import { NotificationBar, AppFooter as AppFooterExt } from "@crossroads/ui-components";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { MessagesData, ViewModeData, CmsData } from "data";
import useUI, { UiProvider } from "helpers/use-ui";
import { MODE } from "state/view-mode";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import useCookie from "helpers/use-cookie";
import InfoIcon from "icons/info.svg";
import Logo from "components/AppHeader/Logo";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  children: ReactNode,
  location: Location,
  history: History,
};

type PointsRemainingProps = {
  pointsAccepted: string,
  setPointsAccepted: (value: string) => void,
};

const PointsRemaining = ({ pointsAccepted, setPointsAccepted }: PointsRemainingProps) => {
  const { customer } = useCustomer();
  const t = useTranslate();
  const { formatPoints, formatDate } = useFormat();

  if (!customer) {
    return null;
  }

  const { spent, limit, resetsAt } = customer.points.find(x => x.id === "scandic")?.spendingLimit || {};

  if (limit !== undefined && limit > 0) {
    return (
      <Notice
        cookieName="points_notice_accepted"
        icon={<InfoIcon />}
        accepted={pointsAccepted}
        acceptanceText={spent > 0 ? t("POINTS_NOTICE_REMAINING", { spent: formatPoints(spent), limit: formatPoints(limit), resetsAt: formatDate(resetsAt) }) : t("POINTS_NOTICE", { limit: formatPoints(limit) })}
        callback={setPointsAccepted}
      />
    );
  }

  return null;
};

const cookieAcceptOptions = { domain: `.${typeof window !== "undefined" ? window.location.host.replace(/:\d+$/, "") : ""}`, expires: 3650 };
const pointsAcceptOptions = { expires: 1 };

const App = ({ children, location, history }: Props) => {
  const t = useTranslate();
  const container = useRef(null);
  const modalContainer = useRef(null);
  const { info } = useContext(StoreInfoContext);
  const { localeUrl } = useLocale();
  const { loggedIn } = useCustomer();
  const onCheckout = (location ? location.pathname : "").includes("checkout");
  const onSuccess = (location ? location.pathname : "").includes("success");
  const [consent, setConsent, consentRead] =
    useCookie("cookie_notice_accepted", "initial", cookieAcceptOptions);
  const [pointsAccepted, setPointsAccepted, pointsAcceptedRead] =
    useCookie("points_notice_accepted", "false", pointsAcceptOptions);
  const script = [];
  const [toggleCookieDialogue, setToggleCookieDialogue] = useState(false);
  const [hideNotificationBar, setHideNotificationBar] = useState(false);

  usePreserveScrollPosition(history);

  const footerContentTerms = () => (
    <div className={styles.footerTerms}>
      <div className={styles.footerTermsContainer}>
        <Link className={styles.link} to={t("FOOTER.LINKS.TERMS.LINK")}>{t("FOOTER.LINKS.TERMS.TEXT")}</Link>
        <Link className={styles.link} to="/privacy-policy">{t("FOOTER.PRIVACY_POLICY")}</Link>
        <Button
          className={styles.link}
          onClick={() => setToggleCookieDialogue(true)}
        >
          {t("FOOTER.NEED_HELP.COOKIES")}
        </Button>
      </div>
      <p dangerouslySetInnerHTML={{ __html: t("FOOTER.COPYRIGHT", { year: new Date().getFullYear() }) }} />
    </div>
  );

  const footerContentCustom = () => (
    <>
      <h2 className={styles.heading}>{t("FOOTER.NEED_HELP.TITLE")}</h2>
      <nav className={styles.nav}>
        {loggedIn && (
          <Link to={t("FOOTER.LINKS.ACCOUNT.LINK")}>
            {t("FOOTER.LINKS.ACCOUNT.TEXT")}
          </Link>
        )}
        <Link className={styles.link} to={t("CUSTOMER_SERVICE.LINK")}>
          {t("FOOTER.NEED_HELP.CONTACT")}
        </Link>
        <a
          href={t("FOOTER.LINKS.ABOUT.LINK")}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {t("FOOTER.LINKS.ABOUT.TEXT")}
        </a>
        <a
          href={localeUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={cn(styles.link, styles.backTo)}
        >
          <span>{t("HEADER.BACK_TO", { url: localeUrl.replace("https://www.", "") })}</span>
        </a>
      </nav>
    </>
  );

  return (
    <MessagesData.Provider>
      <ViewModeData.Provider>
        <CmsData.Provider url="cookie-consent">
          <UiProvider>
            <div
              ref={container} className={cn(
                styles.block,
                { [styles.noticePadding]: !pointsAccepted && onCheckout },
                { [styles.onCheckout]: onCheckout }
              )}
            >
              <Helmet
                titleTemplate={`${info.titlePrefix || ""} %s ${info.titleSuffix || ""}`}
                link={[
                  { rel: "apple-touch-icon", sizes: "57x57", href: "/assets/apple-touch-icon-57x57.png" },
                  { rel: "apple-touch-icon", sizes: "60x60", href: "/assets/apple-touch-icon-60x60.png" },
                  { rel: "apple-touch-icon", sizes: "72x72", href: "/assets/apple-touch-icon-72x72.png" },
                  { rel: "apple-touch-icon", sizes: "76x76", href: "/assets/apple-touch-icon-76x76.png" },
                  { rel: "apple-touch-icon", sizes: "114x114", href: "/assets/apple-touch-icon-114x114.png" },
                  { rel: "apple-touch-icon", sizes: "120x120", href: "/assets/apple-touch-icon-120x120.png" },
                  { rel: "apple-touch-icon", sizes: "144x144", href: "/assets/apple-touch-icon-144x144.png" },
                  { rel: "apple-touch-icon", sizes: "152x152", href: "/assets/apple-touch-icon-152x152.png" },
                  { rel: "apple-touch-icon", sizes: "180x180", href: "/assets/apple-touch-icon-180x180.png" },
                  { rel: "apple-touch-icon-precomposed", href: "/assets/apple-touch-icon-120x120-precomposed.png" },
                  { rel: "icon", type: "image/png", size: "32x32", href: "/assets/favicon-32x32.webp" },
                  { rel: "icon", type: "image/png", size: "96x96", href: "/assets/favicon-96x96.webp" },
                  { rel: "icon", type: "image/png", size: "16x16", href: "/assets/favicon-16x16.webp" },
                  { rel: "mask-icon", href: "/assets/safari-pinned-tab.svg", color: "#cd0921" },
                  { rel: "manifest", href: "/assets/manifest.json" },
                ]}
                meta={[
                  { name: "msapplication-TileColor", content: "#ffffff" },
                  { name: "theme-color", content: "#ffffff" },
                ]}
                htmlAttributes={{ lang: info.locale.slice(0, 2) }}
                script={script}
              />
              <div className={styles.top}>
                <AppHeader
                  className={styles.header}
                  location={location}
                  onCheckout={onCheckout}
                  onSuccess={onSuccess}
                />

                <div className={styles.height} />

                {consent !== "initial" && pointsAcceptedRead &&
                  <div className={styles.notices}>
                    <PointsRemaining
                      pointsAccepted={pointsAccepted}
                      setPointsAccepted={setPointsAccepted}
                    />
                  </div>
                }

                <div ref={modalContainer}>
                  <LoginModal modalContainer={modalContainer} />
                </div>

                <div>
                  {children}
                </div>
              </div>

              {consentRead &&
                <div className={cn(styles.notices, styles.noticesCookie)}>
                  <CookieConsent
                    isVisible={consentRead && (consent === "initial" || toggleCookieDialogue)}
                    consent={consent}
                    setConsent={setConsent}
                    consentRead={consentRead}
                    setToggleCookieDialogue={setToggleCookieDialogue}
                  />
                </div>
              }

              {!onCheckout && (
                <AppFooterExt
                  className={styles.footer}
                  activeLinks={["start", "categories", "brands", "search"]}
                  contentCustom={footerContentCustom}
                  contentTerms={footerContentTerms}
                  contentBranding={() => <Logo className={styles.logo} />}
                  MODE={MODE}
                  useUi={useUI}
                />
              )}

              {info.popUp && info.popUp.body !== "" && !hideNotificationBar &&
                <NotificationBar
                  className={styles.notificationBar}
                  text={info.popUp.body}
                  onClose={() => setHideNotificationBar(true)}
                />
              }
            </div>
          </UiProvider>
        </CmsData.Provider>
      </ViewModeData.Provider>
    </MessagesData.Provider>
  );
};

export default App;
