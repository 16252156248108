/* @flow */

import type { Mode } from "state/view-mode";

import React, { useState } from "react";
import cn from "classnames";
import { Link, withRouter } from "react-router-dom";
import { Button, Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import { MODE } from "state/view-mode";
import CloseIcon from "icons/cross.svg";
import ChevronIcon from "icons/chevron.svg";

import styles from "./styles.scss";

export type Item = {
  name: string,
  url: string,
};

type ParentItem = {
  id: string,
  name: string,
  url: string,
  children: Array<Item>,
};

type Props = {
  history: {
    location: {
      pathname: string,
    },
  },
  setMode: Mode => void,
  setHamburgerOpen: boolean => void,
  className: string,
  onClose: () => void,
  onBack: () => void,
  heading: string,
  items: Array<ParentItem>,
};

type HeaderProps = {
  onClose: () => void,
  onBack: () => void,
  heading: string,
};

type ItemProps = {
  className: string,
  item: Item,
  children: React$Node,
};

export const DrawerHeader = ({ heading, onClose, onBack }: HeaderProps) => {
  const t = useTranslate();
  return (
    <div className={styles.topWrapper}>
      <Wrapper className={styles.top}>
        {onBack &&
          <Button aria-label={t("HEADER.BACK")} className={styles.back} onClick={onBack}>
            <ChevronIcon />
          </Button>
        }
        <h2 className={styles.header}>{heading}</h2>
        <Button aria-label={t("HEADER.CLOSE_MENU")} className={cn(styles.close, styles.actionButton)} onClick={onClose}>
          <CloseIcon />
        </Button>
      </Wrapper>
    </div>
  );
};

const Drawer = ({
  history,
  setMode,
  setHamburgerOpen,
  items,
  heading,
  onClose,
  className = "",
  onBack,
}: Props) => {
  const [subMenu, setSubMenu] = useState<string | null>(null);
  const toggleSubMenu = (name: string) => setSubMenu(subMenu === null ? name : null);

  const ItemLink = ({ item, className = styles.item, children }: ItemProps) => {
    const isParent = history.location.pathname === item.url;

    return item.url === "/" ? (
      <div className={className}>
        {children}
      </div>
    ) : (
      <Link
        to={{
          pathname: item.url,
          state: { hint: { type: "category", category: item } },
        }}
        className={className}
        onClick={() => {
          if (isParent) {
            if (setMode) {
              setMode(MODE.NORMAL);
            }
            else {
              setHamburgerOpen(false);
            }
          }

          setSubMenu(null);
        }}
      >
        {children}
      </Link>
    );
  };

  return (
    <div className={cn(styles.block, className)}>
      <DrawerHeader
        heading={heading}
        onClose={onClose}
        onBack={onBack}
      />

      {items.map(x => (
        <div key={x.name} className={cn(styles.row, styles.parent)}>
          <Wrapper className={styles.wrapper}>
            <div
              className={cn(styles.parent__item,
                { [styles.open]: subMenu === x.name }
              )}
            >
              <ItemLink
                item={x}
                className={cn(
                  styles.heading,
                  styles.heading__small
                )}
              >
                {x.name}
              </ItemLink>

              {x.children.length > 0 &&
                <Button
                  aria-label={x.name}
                  className={styles.actionButton}
                  onClick={() => toggleSubMenu(x.name)}
                >
                  <ChevronIcon className={styles.chevron} />
                </Button>
              }
            </div>

            <ItemLink
              item={x}
              className={cn(
                styles.heading,
                styles.heading__large
              )}
            >
              {x.name}
              <ChevronIcon className={styles.chevron} />
            </ItemLink>

            <Foldable wrapperClass={styles.items} open={subMenu === x.name}>
              {x.children.map(child => (
                <ItemLink key={x.name + "-" + child.name} item={child} className={styles.item}>
                  <div className={styles.item__heading}>{child.name}</div>
                </ItemLink>
              ))}
            </Foldable>
          </Wrapper>
        </div>
      ))}
    </div>
  );
};

export default withRouter(Drawer);
