/* @flow */

import type { Node as ReactNode } from "react";

import React from "react";
import cn from "classnames";

import { Foldable } from "@crossroads/ui-components";
import ChevronIcon from "icons/chevron-small.svg";

import styles from "./styles.scss";

type Props = {
  openFilter: string,
  setOpenFilter: (filter: string) => void,
  filterName: string,
  children: ReactNode,
};

const FilterContainer = ({ openFilter, setOpenFilter, filterName, children }: Props) => {
  return (
    <div className={cn(styles.filter, { [styles.open]: openFilter === filterName })}>
      <div
        className={styles.filter__header}
        onClick={() => setOpenFilter(openFilter === filterName ? "" : filterName)}
      >
        <h5>{filterName.toLocaleUpperCase()}</h5>
        <ChevronIcon className={styles.header__close_icon} />
      </div>
      <Foldable open={openFilter === filterName}>
        <div className={styles.filter__body}>
          {children}
        </div>
      </Foldable>
    </div>
  );
};

export default FilterContainer;
