/* @flow */

import type { Category, HintCategory } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";

type Props = {
  category: Category,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
};

type HintProps = {
  category: HintCategory,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
};

const CategoryHero = ({ category, className, breadcrumbLinks }: Props) => {
  const t = useTranslate();

  const {
    products: { totalCount = 0 },
    image,
  } = category;
  const { x1: smallImage, x2: mediumImage, x3: largeImage } = image || {};

  return (
    <div
      className={cn(
        styles.block,
        className
      )}
    >
      <Wrapper className={styles.wrapper}>
        <div className={styles.body}>
          <Breadcrumbs
            className={styles.breakcrumbs}
            links={breadcrumbLinks}
            current={category.name} />

          <div className={styles.text}>
            <h1 className={styles.title}>{category.name}</h1>
            <p className={styles.total_count}>
              {totalCount === 1 && t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_SINGULAR")}
              {totalCount > 1 && t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_PLURAL", { itemsCount: totalCount })}
            </p>
          </div>
          { smallImage &&
            <picture
              key={`${category.name}_heroimage`}
              className={styles.image}
              alt={`Logo ${category.name}`}
            >
              <source media="(max-width: 550px)" srcSet={smallImage} />
              <source media="(min-width: 551px) and (max-width: 1057px)" srcSet={mediumImage} />
              <source media="(min-width: 1058px)" srcSet={largeImage} />
              <img src={smallImage} alt={`Logo ${category.name}`} />
            </picture>
          }
        </div>
      </Wrapper>
    </div>
  );
};

export const CategoryHeroHint = ({ category, className, breadcrumbLinks }: HintProps) => {
  return (
    <div className={cn(styles.block, className)}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.body}>
          <Breadcrumbs
            className={styles.breakcrumbs}
            links={breadcrumbLinks}
            current={category.name} />

          <div className={styles.text}>
            <h1 className={styles.title}>{category.name}</h1>
            <p className={styles.total_count} />
          </div>

          <picture className={styles.image} alt={`Logo ${category.name}`} />
        </div>
      </Wrapper>
    </div>
  );
};

export default CategoryHero;
