/* @flow */

import type {
  Product,
  ProductCardProduct,
  MinimalProductCardProduct,
  Gallery,
  ProductCategory } from "shop-state/types";
import type { Option, BreadcrumbLink } from "@crossroads/ui-components";

import React, { useState, useEffect, useContext, useMemo } from "react";
import { useData, useSendMessage } from "crustate/react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import { useGetProductMeta } from "helpers/get-meta";
import useCustomer from "helpers/use-customer";
import useWishlist from "helpers/use-wishlist";
import { QuoteData } from "data";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import Breadcrumbs from "components/Breadcrumbs";
import { PriceBadges } from "components/Price";
import { getSelectedPointsPrice } from "./get-price";
import {
  Button,
  AddToCart,
  productDefaults,
  inStock,
  getAttributesConfigurable,
  getSelectedConfigurable,
  ProductViewMedia,
  getPrice,
} from "@crossroads/ui-components";
import { ColorSelect } from "components/ProductOptions";
import PriceSplit from "components/PriceSplit";
import cn from "classnames";
import WishlistIcon from "components/WishlistIcon";
import { setMode, MODE } from "state/view-mode";
import { isPointsOnly, isForcedSplitPayment } from "helpers/points";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";

import styles from "./styles.scss";

type ProductViewProps = {
  product: Product,
  location: {
    state?: {
        breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
        list?: any,
        position?: any,
    },
  },
};

type HintProductViewProps = {
  product: ProductCardProduct | MinimalProductCardProduct,
  location: {
    state: {
      breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
      image: { x1: string, x2: string },
    },
  },
};

const AddToCartBtn = ({
  loading = false,
  outOfStock,
  login,
}: { loading?: boolean, outOfStock: boolean, login: () => void,
}) => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const { loggedIn } = useCustomer();
  const addingToCart = quoteState.state === "ADDING_ITEM";

  return loggedIn ? (
    <Button
      className={styles.addToCart}
      variant="primary"
      loading={addingToCart || loading}
      type="submit"
      disabled={outOfStock}
    >
      {outOfStock ? t("PRODUCT.OUT_OF_STOCK") : t("PRODUCT.ADD_TO_CART")}
    </Button>
  ) : (
    <Button
      variant="primary"
      loading={loading}
      className={styles.login}
      type="button"
      onClick={login}
    >
      {t("PRODUCT.LOGIN_TO_PURCHASE")}
    </Button>
  );
};

const sortGalleryByImage = (gallery: Gallery, src: string) => {
  let first = gallery[0];

  // Find image in gallery by src
  for (const i of gallery) {
    if (Object.values(i).some((x: any) => x.x1 && x.x1.includes(src))) {
      first = i;

      break;
    }
  }

  // Return new array with image first
  return [first, ...gallery.filter(i => i !== first)];
};

const mapProductCategories = (categories: $ReadOnlyArray<ProductCategory>) => {
  const structuredCategories: Array<{
      name: string,
      url: string,
      children: Array<{
        name: string,
        url: string,
      }>,
  }> = [];

  for (const category of categories) {
    if (category.parent) {
      let parent = structuredCategories.find(c => c.url === category.parent?.url);

      if (parent) {
        parent.children.push({
          name: category.name,
          url: category.url,
        });
      }
      else {
        parent = {
          name: category.parent?.name || "",
          url: category.parent?.url || "",
          children: [{
            name: category.name,
            url: category.url,
          }],
        };

        structuredCategories.push(parent);
      }
    }
    else {
      structuredCategories.push({
        name: category.name,
        url: category.url,
        children: [],
      });
    }
  }

  return structuredCategories;
};

const ProductView = ({ product, location }: ProductViewProps) => {
  const sendMessage = useSendMessage();
  const gaContext = useContext(AnalyticsContext);
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const [selected, setSelected] = useState<Option>(productDefaults(product));
  const selectedItem = getSelectedConfigurable(selected, configAttributes);
  const { loggedIn } = useCustomer();
  const { toggleWishlist, inWishlist } = useWishlist({ product, selected });
  const brand = product.attributes.manufacturer;
  const t = useTranslate();
  const meta = useGetProductMeta(product, t);
  const { smallImage, largeImage } = product.attributes;
  const outOfStock = !inStock(product, selected);
  const { pointsPrice } = getSelectedPointsPrice(product, selected);
  const pointsOnly = isPointsOnly(pointsPrice);
  const categories = useMemo(() => mapProductCategories(product.categories), [product]);
  const breadcrumbLinks = useBreadcrumbLinks({ fallbackCategories: categories });
  const gallery = React.useMemo(() => (
    Array.isArray(product.gallery) && product.gallery.length > 1 ?
      sortGalleryByImage(product.gallery, (product.attributes.smallImage?.x2 || "").split("/").pop()) :
      []
  ), [product]);

  const login = () => {
    sendMessage(setMode(MODE.LOGIN));
  };

  const [currentImage, setCurrentImage] = useState({
    smallImage: smallImage ? { x1: smallImage.x1, x2: smallImage.x2 } : null,
    largeImage: largeImage ? { x1: largeImage.x1, x2: largeImage.x2 } : null,
  });

  useEffect(() => {
    gaContext.registerProductDetailsView({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    },
    product.price.incVat,
    location.state?.list,
    location.state?.position);
  }, []);

  // Change image when selecting option
  useEffect(() => {
    if (selectedItem) {
      const a = selectedItem.product.attributes;

      if (a) {
        setCurrentImage({
          smallImage: a.smallImage ? { x1: a.smallImage.x1, x2: a.smallImage.x2 } : null,
          largeImage: a.largeImage ? { x1: a.largeImage.x1, x2: a.largeImage.x2 } : null,
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    setCurrentImage({
      smallImage,
      largeImage,
    });
  }, [smallImage, largeImage]);

  if (product.name === "Zupergift" && product.type === "configurable") {
    product.options.items.sort((a, b) =>
      parseInt(a.values[0].value.replace(/ /g, ""), 10) - parseInt(b.values[0].value.replace(/ /g, ""), 10));
  }

  if (!pointsPrice) {
    return null;
  }

  const onAdd = p => {
    if (selectedItem) {
      const incVat = getPrice(product, selected, "incVat");
      const exVat = getPrice(product, selected, "exVat");

      gaContext.registerModifyCart({
        ...selectedItem.product,
        sku: product.sku,
        qty: 1,
        price: { incVat, exVat, vat: incVat - exVat },
        categories: p.categories,
      }, "add_to_cart", incVat);
    }
    else {
      gaContext.registerModifyCart({
        ...p,
        qty: 1,
      }, "add_to_cart", p.price.incVat);
    }
  };

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <header className={styles.header__outer}>
        <div className={styles.top}>
          <h1 className={styles.name}>{product.name}</h1>
          {loggedIn && <WishlistIcon
            className={styles.wishlistIcon}
            inWishlist={inWishlist}
            onClick={() => {
              toggleWishlist();
            }} />}
        </div>
        <Link
          to={`/brand/${encodeURIComponent(brand)}`}
          className={styles.brand}
        >
          {brand}
        </Link>
      </header>
      <div className={styles.split}>
        <div className={styles.left}>
          <header className={styles.header__inner}>
            <div className={styles.top}>
              <h1 className={styles.name}>{product.name}</h1>
              {loggedIn && <WishlistIcon
                className={styles.wishlistIcon}
                inWishlist={inWishlist}
                onClick={() => {
                  toggleWishlist();
                }} />}
            </div>
            <Link
              to={`/brand/${encodeURIComponent(brand)}`}
              className={styles.brand}
            >
              {brand}
            </Link>
          </header>
          <section className={styles.addToCart}>
            <AddToCart
              product={product}
              selected={selected}
              setSelected={setSelected}
              qty={1}
              templates={{
                color: ColorSelect,
              }}
              onAdd={onAdd}
            >
              <div className={cn(styles.action, { [styles.configurable]: product.type === "configurable" })}>
                <PriceSplit className={styles.price} pointsPrice={pointsPrice} />

                <AddToCartBtn
                  login={login}
                  outOfStock={outOfStock} />
              </div>
            </AddToCart>
          </section>

          <PriceBadges
            minimum={pointsPrice.points.min.incVat}
            pointsOnly={pointsOnly}
            forcedSplitPayment={isForcedSplitPayment(pointsPrice)}
          />

          <section className={styles.description}>
            <h5 className={styles.descriptionHeading}>{t("PRODUCT.DESCRIPTION")}</h5>
            <div
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
              className={styles.descriptionContent}
            />
            {product.attrDescriptions.manufacturer &&
            product.attrDescriptions.manufacturer.icon &&
            product.attrDescriptions.manufacturer.description &&
              <div className={styles.manufacturer}>
                <Link to={`/brand/${encodeURIComponent(brand)}`} className={styles.logo}>
                  <img
                    src={product.attrDescriptions.manufacturer ? product.attrDescriptions.manufacturer.icon : ""}
                    alt={`Logo ${brand}`}
                  />
                </Link>
                <p>{product.attrDescriptions.manufacturer ? product.attrDescriptions.manufacturer.description : ""}</p>
                <Link to={`/brand/${encodeURIComponent(brand)}`} className={styles.goto}>
                  {t("PRODUCT.GO_TO_MANUFACTURER")}
                </Link>
              </div>
            }
          </section>
        </div>

        <div className={styles.right}>
          <ProductViewMedia
            alt={product.name}
            currentImage={currentImage}
            gallery={gallery}
            galleryPerRow={2}
            location={{ ...location }}
          />
        </div>
      </div>

      <div className={styles.lists}>
        <div className={styles.relatedList}>
          {product.relatedProducts.items.length > 0 &&
            <ProductList
              heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
              products={product.relatedProducts.items}
              productsPerRow={2}
              listName="Related products"
            />
          }
        </div>

        <div className={styles.historyList}>
          {product.crossSellProducts.items.length > 0 &&
            <ProductList
              heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
              products={product.crossSellProducts.items}
              productsPerRow={2}
              listName="Cross sell products"
            />
          }
        </div>
      </div>
    </Wrapper>
  );
};

export const Hint = ({ product, location }: HintProductViewProps) => {
  const breadcrumbLinks = useBreadcrumbLinks();
  const brand = product.attributes.manufacturer ?
    product.attributes.manufacturer : null;
  const image = product.attributes.largeImage ||
    product.attributes.smallImage ||
    location.state.image || {};

  return (
    <Wrapper className={styles.hintWrapper}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <header className={styles.header__outer}>
        <div className={styles.top}>
          <h1 className={styles.name}>{product.name}</h1>
        </div>
        {brand &&
          <Link
            to={`/brand/${encodeURIComponent(brand)}`}
            className={styles.brand}
          >
            {brand}
          </Link>
        }
      </header>

      <div className={styles.split}>

        <div className={styles.left}>
          <header className={styles.header__inner}>
            <div className={styles.top}>
              <h1 className={styles.name}>{product.name}</h1>
            </div>
            {brand &&
              <Link
                to={`/brand/${encodeURIComponent(brand)}`}
                className={styles.brand}
              >
                {brand}
              </Link>
            }
          </header>

          <div className={styles.dummyContent}>
            <div className={styles.top} />
            <div className={styles.bottom} />
          </div>
        </div>

        <div className={styles.right}>
          <ProductViewMedia
            alt={product.name}
            currentImage={{
              largeImage: image,
              smallImage: image,
            }}
            gallery={[]}
            galleryPerRow={2}
            location={{ ...location }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export const HintProductView = withRouter(Hint);

export default withRouter(ProductView);
