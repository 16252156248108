/* @flow */

import type { Location } from "react-router";
import type { Customer } from "shop-state/types";
import type { Mode } from "state/view-mode";
import type { Language } from "components/LanguageSwitcher";

import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { CartMiniConfigurable as CartMini } from "components/CartMini";
import StatusbarTier from "components/StatusbarTier";
import CartCounter from "components/AppHeader/CartCounter";
import cn from "classnames";
import SearchMenu from "../SearchMenu";
import Drawer from "../Drawer";
import Logo from "../Logo";
import HamburgerIcon from "icons/hamburger.svg";
import CloseIcon from "icons/cross.svg";
import SystemMessages from "components/SystemMessages";
import LanguageSwitcher, { languages } from "components/LanguageSwitcher";
import useFormat from "helpers/use-format";
import { mapBrands } from "../Large";
import { MODE } from "state/view-mode";
import useSteps from "helpers/use-steps";
import ChevronIcon from "icons/chevron.svg";
import useUI from "helpers/use-ui";
import Bottom from "./Bottom";

import styles from "./styles.scss";

type Props = {
  className: string,
  customer: ?Customer,
  customerLoggingIn: boolean,
  mode: Mode,
  setMode: Mode => void,
  hidden: boolean,
  onCheckout: boolean,
  location: Location,
  lastVisited: ?string,
};

const AppHeaderSmall = ({
  className = "",
  mode,
  setMode,
  customer,
  hidden,
  onCheckout,
  lastVisited,
  customerLoggingIn,
  ...props
}: Props) => {
  const { formatPoints, formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const history = useHistory();
  const t = useTranslate();
  const { categories, brands, info: { usePoints, locale } } = useContext(StoreInfoContext);
  const [visited, setVisited] = useState<Array<string>>([]);
  const {
    hamburgerOpen,
    setHamburgerOpen,
    subNavOpen,
    setSubNavOpen,
    toggleHamburger,
    closeHamburger,
    isHamburgerOpening,
    isHamburgerClosing,
    setModeAndOpenMenu,
  } = useUI();
  const loggedIn = customer !== null;
  const step = onCheckout ? props.location.pathname.split("/").slice(-1)[0] : "";
  const currentStep = useSteps(step);
  const closeMenu = () => setMode(MODE.NORMAL);
  const lang = useRef<Language>(languages.find(x => x.lang === locale) || languages[0]);
  const setLang = (l: Language) => {
    lang.current = l;
  };

  useEffect(() => {
    const _locale = locale.replace(/_/, "-");
    const language = languages.find(language => language.lang === _locale);

    if (language && language !== lang.current) {
      lang.current = language;
    }
  }, [locale]);

  const cartVisited = useMemo(() => {
    return onCheckout ? Boolean(visited.find(v => v === "cart")) : false;
  }, [onCheckout, visited]);

  const grandTotal = quoteData.data ?
    quoteData.data.grandTotal.incVat :
    0;

  useEffect(() => {
    if (onCheckout && step && !visited.find(v => v === step)) {
      setVisited(v => [...v, step]);
    }
    else if (!onCheckout) {
      setVisited([]);
    }
  }, [step, onCheckout]);

  const cartCount =
    quoteData.state === "LOADED" || quoteData.state === "UPDATING" ?
      quoteData.data.items.reduce((a, { qty }) => a + qty, 0) :
      0;

  // TODO: goback to checkout/cart
  const goBack = () => {
    history.goBack();
  };

  if (onCheckout) {
    return (
      <div className={cn(
        styles.block,
        className)}
      >
        <Wrapper className={cn(styles.wrapper)}>
          <div className={styles.left}>
            {cartVisited && step === "placeorder" &&
              <Button
                className={styles.backbutton}
                onClick={goBack}
              >
                <ChevronIcon />
              </Button>
            }
            {currentStep &&
              <h1 className={styles.title}>{currentStep.titleShort}</h1>
            }
          </div>

          <div className={styles.right}>
            <nav className={styles.nav}>
              <Button onClick={() => lastVisited ? history.push(lastVisited) : history.push("/")}>
                <CloseIcon />
              </Button>
            </nav>
          </div>
          <SystemMessages />
        </Wrapper>
      </div>
    );
  }

  return (
    <div className={styles.outerContainer}>
      {mode !== MODE.NORMAL &&
        <div className={styles.dim} onClick={() => setMode(MODE.NORMAL)} />
      }

      <StatusbarTier
        customer={customer}
        className={cn(
          styles.statusbar,
          { [styles.hidden]: hidden },
          { [styles.hamburgerOpen]: hamburgerOpen }
        )}
      >
        <div className={cn(styles.statusbarItem, styles.scandicfriends)}>
          <span className={styles.pointsShop}>{t("HEADER.POINTS_SHOP")}</span>
        </div>
      </StatusbarTier>

      <div className={cn(
        styles.container,
        styles["mode__" + mode],
        { [styles.hamburgerOpen]: hamburgerOpen },
        { [styles.isOpening]: isHamburgerOpening },
        { [styles.isClosing]: isHamburgerClosing },
        { [styles.subNavOpen]: subNavOpen }
      )}
      >
        <div className={cn(
          styles.block,
          { [styles.hidden]: hidden },
          className)}
        >
          <Wrapper className={cn(styles.wrapper)}>
            <div className={styles.left}>
              <Link className={cn(styles.item, styles.padding)} to="/">
                <Logo />
              </Link>
            </div>

            <div className={styles.right}>
              <nav className={styles.nav}>
                <div className={styles.nav}>
                  {loggedIn && <CartCounter
                    useSpinner
                    className={cn(styles.item, styles.padding)}
                    isOpen={mode === MODE.CART} openMiniCart={() =>
                      setMode(mode === MODE.CART ? MODE.NORMAL : MODE.CART)} />}
                  <span
                    className={cn(styles.item, styles.padding)}
                    onClick={toggleHamburger}
                  >
                    <HamburgerIcon />
                  </span>
                </div>
              </nav>
            </div>
          </Wrapper>
        </div>

        <nav className={styles.drawer}>
          <div className={styles.drawerMain}>
            <Wrapper className={styles.top}>
              <Logo className={styles.logo} />

              <Button aria-label={t("HEADER.CLOSE_MENU")} onClick={() => closeHamburger(styles.animationDuration)}>
                <CloseIcon className={styles.close} />
              </Button>
            </Wrapper>

            <span
              className={styles.item}
              onClick={() => setModeAndOpenMenu(MODE.CATEGORIES)}
            >
              {t("HEADER.CATEGORIES")}
            </span>

            <span
              className={styles.item}
              onClick={() => setModeAndOpenMenu(MODE.BRANDS)}
            >
              {t("HEADER.BRANDS")}
            </span>

            <Link
              className={styles.item}
              title={t("CUSTOMER_SERVICE.LINK")}
              to={t("CUSTOMER_SERVICE.LINK")}
            >
              <span>{t("CUSTOMER_SERVICE.TEXT")}</span>
            </Link>

            <Link
              className={styles.item}
              title={t("HEADER.ALL_PRODUCTS")}
              to="/all-products"
            >
              <span>{t("HEADER.ALL_PRODUCTS")}</span>
            </Link>

            <span
              className={styles.item}
              onClick={() => setModeAndOpenMenu(MODE.SEARCH)}
            >
              <span>{t("HEADER.SEARCH")}</span>
            </span>

            {loggedIn &&
              <Link
                className={styles.item}
                title={t("HEADER.CART")}
                to={cartCount > 0 ? "/checkout/cart" : "/"}
              >
                <span>{t("HEADER.CART")}</span>
                <span>
                  <span className={styles.grandTotal}>
                    {usePoints ?
                      formatPoints(grandTotal) :
                      formatPrice(grandTotal)}
                  </span>
                  <span className={styles.cartCount}>({cartCount})</span>
                </span>
              </Link>
            }
          </div>
          <Bottom
            customer={customer}
            customerLoggingIn={customerLoggingIn}
            mode={mode}
            closeMenu={(mode: Mode) => closeHamburger(styles.animationDuration, mode)}
            setMode={setMode}
            currentLang={lang.current}
          />
        </nav>

        <nav className={styles.search}>
          <SearchMenu
            heading={t("HEADER.SEARCH")}
            onClose={() => closeHamburger(styles.animationDuration)}
            onItemClick={closeMenu}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>

        {mode === MODE.CATEGORIES &&
        <nav className={styles.categories}>
          <Drawer
            setHamburgerOpen={setHamburgerOpen}
            heading={t("HEADER.CATEGORIES")}
            items={categories}
            onClose={() => closeHamburger(styles.animationDuration)}
            onItemClick={closeMenu}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>
        }

        {mode === MODE.BRANDS &&
        <nav className={styles.brands}>
          <Drawer
            setHamburgerOpen={setHamburgerOpen}
            heading={t("HEADER.BRANDS")}
            items={mapBrands(brands)}
            onClose={() => closeHamburger(styles.animationDuration)}
            onItemClick={closeMenu}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>
        }

        <nav className={styles.language}>
          <LanguageSwitcher
            currentLang={lang.current}
            setCurrentLang={setLang}
            onClose={() => closeHamburger(styles.animationDuration)}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>

        {mode === MODE.CART &&
          <CartMini className={styles.cartMini} />
        }
      </div>

      <Wrapper>
        <SystemMessages />
      </Wrapper>
    </div>
  );
};

export default AppHeaderSmall;
