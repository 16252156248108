/* @flow */

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";
import type { ProductCardProduct } from "shop-state/types";

export type Wishlist = {
  items: Array<ProductCardProduct>,
  totalCount: number,
};

type Data =
  | { state: "LOADING" }
  | { state: "UPDATING", data: Wishlist }
  | { state: "LOADED", data: Wishlist }
  | { state: "ERROR", error: string };

export type WishlistLoadResponse = Response<typeof WISHLIST_LOAD_RESPONSE, ?Wishlist>;
export type WishlistRemoveProductResponse = {|
  +tag: typeof WISHLIST_REMOVE_PRODUCT_RESPONSE,
  data: Wishlist,
  sku: string,
|} | {| tag: typeof WISHLIST_REMOVE_PRODUCT_RESPONSE, error: string |};

export type WishlistLoadRequest = {
  tag: typeof WISHLIST_LOAD_REQUEST,
};

export type WishlistRemoveProductRequest = {
  tag: typeof WISHLIST_REMOVE_PRODUCT_REQUEST,
  itemId: string,
  sku: string,
};

export const WISHLIST_LOAD_RESPONSE: "wishlist/load/response" = "wishlist/load/response";
export const WISHLIST_LOAD_REQUEST: "wishlist/load/request" = "wishlist/load/request";
export const WISHLIST_REMOVE_PRODUCT_RESPONSE: "wishlist/remove_product/response" = "wishlist/remove_product/response";
export const WISHLIST_REMOVE_PRODUCT_REQUEST: "wishlist/remove_product/request" = "wishlist/remove_product/request";

export const getWishlist = () => ({ tag: WISHLIST_LOAD_REQUEST });

export const removeFromWishlist = (itemId: string, sku: string) => ({
  tag: WISHLIST_REMOVE_PRODUCT_REQUEST,
  itemId,
  sku,
});

export const WishlistModel: Model<Data, {},
    WishlistLoadRequest
  | WishlistLoadResponse
  | WishlistRemoveProductRequest
  | WishlistRemoveProductResponse> = {
    id: "wishlist",
    init: () =>
      updateData(
        { state: "LOADING" },
        { tag: WISHLIST_LOAD_REQUEST }
      ),
    update: (state: Data, msg) => {
      switch (msg.tag) {
        case WISHLIST_REMOVE_PRODUCT_REQUEST:
          return updateData(
            state.state === "LOADED" ?
              { state: "UPDATING", data: state.data } :
              { state: "LOADING" },
            msg
          );

        case WISHLIST_LOAD_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data && (state.state === "LOADING" || state.state === "UPDATING")) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;
        case WISHLIST_REMOVE_PRODUCT_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;
        default:
      }
    },
  };
