/* @flow */

import type { FilterState } from "helpers/use-filter";

import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import cn from "classnames";
import Button from "components/Button";
import { useTranslate } from "@awardit/react-use-translate";
import { useData, useSendMessage } from "crustate/react";
import { setMode, MODE } from "state/view-mode";
import { ViewModeData } from "data";
import CloseIcon from "icons/close.svg";

import FilterBucket from "./FilterBucket";
import FilterPrice from "./FilterPrice";
import ActiveFilter from "./ActiveFilter";

import styles from "./styles.scss";

type Props = {
  filterState: FilterState,
};

const ANIMATION_STATE = {
  HIDING: "hiding",
  HIDDEN: "hidden",
  ENTERING: "entering",
  ENTERED: "entered",
};

const OffCanvasFilterMenu = ({
  filterState,
}: Props) => {
  const t = useTranslate();
  const [animationState, setAnimationState] = useState(ANIMATION_STATE.HIDDEN);
  const sendMessage = useSendMessage();
  const viewMode = useData(ViewModeData);
  const [openFilter, setOpenFilter] = useState("");
  const filterNode = useRef();
  const sameRange = filterState.price.min === filterState.price.max;

  if (process.browser) {
    /* Temporary disable eslint rule because this part
     * will be removed in compilation for server */

    /* eslint-disable react-hooks/rules-of-hooks */
    useLayoutEffect(() => {
      if (viewMode === MODE.FILTER && document.body && filterNode.current) {
        disableBodyScroll(filterNode.current);
      }

      if (viewMode !== MODE.FILTER && document.body && filterNode.current) {
        enableBodyScroll(filterNode.current);
      }
    }, [viewMode]);
    /* eslint-enable react-hooks/rules-of-hooks */
  }

  const close = () => {
    if (animationState === ANIMATION_STATE.ENTERING) {
      return null;
    }

    setAnimationState(ANIMATION_STATE.HIDING);
    sendMessage(setMode(MODE.NORMAL));

    setTimeout(() => {
      setAnimationState(ANIMATION_STATE.HIDDEN);
    }, parseInt(styles.animationDuration, 10));
  };

  useEffect(() => {
    if (viewMode === MODE.FILTER) {
      setAnimationState(ANIMATION_STATE.ENTERING);

      setTimeout(() => {
        setAnimationState(ANIMATION_STATE.ENTERED);
      }, parseInt(styles.animationDuration, 10));
    }
  }, [viewMode]);

  const _setOpenFilter = (value: string) => {
    if (filterState.loading) {
      return;
    }

    setOpenFilter(value);
  };

  if (!filterState.visible) {
    return null;
  }

  return (
    <>
      <div
        className={cn(
          styles.dim,
          { [styles.hidden]: animationState === ANIMATION_STATE.HIDDEN },
          { [styles.hiding]: animationState === ANIMATION_STATE.HIDING },
          { [styles.entering]: animationState === ANIMATION_STATE.ENTERING })
        }
        onClick={() => close()}
      />
      <div
        className={cn(
          styles.block,
          { [styles.hidden]: viewMode !== MODE.FILTER }
        )}
      >
        <div className={styles.header}>
          <h4>{t("OCF.TITLE")}</h4>
          <Button
            className={styles.closeButton}
            aria-label={t("HEADER.CLOSE_MENU")}
            onClick={() => close()}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className={styles.body}>

          <div className={styles.middle}>
            {filterState.active.filters.length > 0 ?
              <div className={styles.activeFiltersContainer}>
                <Button
                  className={styles.clearbuttonMobile}
                  onClick={filterState.clearAllFilters}
                >
                  {t("FILTER.CLEAR_ALL")}
                </Button>
                <div className={styles.activeFilters}>
                  <div className={styles.activeFiltersLeft}>
                    {filterState.active.filters.map(af => (
                      <ActiveFilter
                        key={af.code}
                        filter={af}
                        clearFilter={filterState.toggleFilter} />
                    ))}
                  </div>
                  <div className={styles.activeFiltersRight}>
                    <Button
                      className={styles.clearbuttonDesktop}
                      onClick={filterState.clearAllFilters}
                    >
                      {t("FILTER.CLEAR_ALL")}
                    </Button>
                  </div>
                </div>
              </div> :
              <div className={styles.activeFiltersEmpty}>
                <div>
                  <p>{t("OCF.NO_ACTIVE_FILTERS")}</p>
                </div>
              </div>
            }
            <div ref={filterNode} className={styles.filters}>
              {filterState.filters.buckets.map(filter => (
                <FilterBucket
                  key={filter.code}
                  setOpenFilter={_setOpenFilter}
                  openFilter={openFilter}
                  filter={filter}
                  activeFilters={filterState.active.filters}
                  onValueClick={filterState.toggleFilter}
                />
              ))}
              {filterState.filters.prices.length > 0 && !sameRange &&
                <FilterPrice
                  label={filterState.filters.prices[0].label}
                  loading={filterState.loading}
                  priceRange={filterState.price.range}
                  setPriceRange={filterState.price.setRange}
                  minPrice={filterState.price.min}
                  maxPrice={filterState.price.max}
                  openFilter={openFilter}
                  setOpenFilter={_setOpenFilter}
                  usePoints={Boolean(filterState.usePoints)}
                />
              }
            </div>
          </div>

          <div className={styles.bottom}>
            <Button
              className={styles.submit_button}
              variant="primary"
              loading={filterState.loading}
              onClick={() => close()}
            >
              {filterState.totalCount > 0 ?
                t("OCF.SHOW_RESULT_TOTAL", { total: filterState.totalCount }) :
                t("OCF.SHOW_RESULT")
              }
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffCanvasFilterMenu;
