/* @flow */

import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { removeFromWishlist } from "state/wishlist";
import { pointsPriceByID } from "helpers/points";
import PriceSplit from "components/PriceSplit";
import { WishlistData } from "data";
import PaginationBar from "components/AccountView/PaginationBar";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const ITEMS_PER_PAGE = 3;

const filterItems = (currentPage: number) =>
  (_, idx) =>
    idx >= (currentPage - 1) * ITEMS_PER_PAGE && idx < currentPage * ITEMS_PER_PAGE;

const Wishlist = ({ className }: Props) => {
  const t = useTranslate();
  const itemsContainer = useRef();
  const sendMessage = useSendMessage();
  const wishlistData = useData(WishlistData);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const items = wishlistData.state === "LOADED" || wishlistData.state === "UPDATING" ?
    wishlistData.data.items.filter(filterItems(currentPage)) : [];

  useEffect(() => {
    if (wishlistData.state === "LOADED") {
      const _pageCount = Math.ceil(wishlistData.data.totalCount / ITEMS_PER_PAGE);
      setPageCount(_pageCount);

      if (_pageCount < currentPage) {
        setCurrentPage(_pageCount);
      }
    }
  }, [wishlistData]);

  if (itemsContainer.current &&
    (wishlistData.state === "LOADED" || wishlistData.state === "UPDATING") &&
    wishlistData.data.items.length >= ITEMS_PER_PAGE) {
    const { height } = itemsContainer.current.getBoundingClientRect();

    if (height > containerHeight) {
      setContainerHeight(height);
    }
  }
  else if (containerHeight > 0) {
    setContainerHeight(0);
  }

  return (
    <div className={cn(styles.block, className)}>
      <h2 className={styles.heading}>{t("WISHLIST.TITLE")}</h2>
      <div
        ref={itemsContainer}
        style={containerHeight > 0 ? { minHeight: containerHeight + "px" } : {}}
        className={styles.items}
      >
        {(wishlistData.state === "LOADED" || wishlistData.state === "UPDATING") &&
          wishlistData.data.items.length > 0 &&
          items.map(x => {
            const points = pointsPriceByID(x.pointsPrices, "scandic");

            if (!points) {
              return null;
            }

            return (
              <div
                key={x.sku}
                className={cn(
                  styles.item,
                  { [styles.loading]: wishlistData.state === "UPDATING" }
                )}
              >
                <div className={styles.top}>
                  <div className={styles.topLeft}>
                    <img src={x.attributes.tinyImage?.x1} alt={x.name} className={styles.image} />
                  </div>
                  <div className={styles.topRight}>
                    <div className={styles.content}>
                      <Link
                        key={x.sku}
                        className={styles.name}
                        to={{
                          pathname: x.url,
                          state: { hint: {
                            type: "product",
                            product: x,
                          } },
                        }}
                      >
                        {x.name}
                      </Link>
                      <span className={styles.brand}>{x.attributes.manufacturer}</span>
                    </div>
                    <PriceSplit className={styles.price} pointsPrice={points} />
                  </div>
                </div>
                <div className={cn(styles.cta)}>
                  <span
                    className={cn(styles.remove)}
                    onClick={() => sendMessage(removeFromWishlist(x.wishlist.itemId, x.sku))}
                  >
                    {t("WISHLIST.REMOVE_FROM_WISHLIST")}
                  </span>
                </div>
              </div>
            );
          })
        }

        {(wishlistData.state === "LOADED" || wishlistData.state === "UPDATING") && wishlistData.data.items.length === 0 &&
          <div className={cn(styles.item, styles.empty)}>
            <p>{t("WISHLIST.EMPTY")}</p>
            <Link to="/">{t("WISHLIST.EXPLORE_ASSORTMENT")}</Link>
          </div>
        }
      </div>

      {pageCount > 1 &&
        <PaginationBar
          className={styles.paginationBar}
          currentPage={currentPage}
          setPage={setCurrentPage}
          pageCount={pageCount}
        />
      }
    </div>
  );
};

export default Wishlist;
