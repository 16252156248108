/* @flow */

import React, { useMemo } from "react";
import { DrawerHeader } from "components/AppHeader/Drawer";

import TickIcon from "icons/tick.svg";
import IconSE from "icons/lang/SE.svg";
import IconDK from "icons/lang/DK.svg";
import IconNO from "icons/lang/NO.svg";
import IconFI from "icons/lang/FI.svg";
import IconOther from "icons/lang/Other.svg";
import useCookie from "helpers/use-cookie";

import styles from "./styles.scss";

export type Language = {
  url: string,
  icon: any,
  title: string,
  code: string,
  lang: string,
};

type Props = {
  currentLang: Language,
  onBack?: () => void,
  onClose?: () => void,
  setCurrentLang: (lang: Language) => void,
};

export const languages: Array<Language> = [
  { url: "/se", icon: IconSE, title: "Sweden", code: "se", lang: "sv-SE" },
  { url: "/dk", icon: IconDK, title: "Denmark", code: "dk", lang: "da-DK" },
  { url: "/fi", icon: IconFI, title: "Suomi", code: "fi", lang: "fi-FI" },
  { url: "/no", icon: IconNO, title: "Norway", code: "no", lang: "nb-NO" },
  { url: "/en", icon: IconOther, title: "Other", code: "en", lang: "en-GB" },
];

const LanguageSwitcher = ({ currentLang, setCurrentLang, onBack, onClose }: Props) => {
  const cookieOpts = useMemo(() => ({ domain: `.${typeof window !== "undefined" ? window.location.host : ""}`, expires: 3650 }), []);
  const [cookieLang, setCookieLang] = useCookie("lang", currentLang.lang, cookieOpts);

  const setNewLang = (e: Event, lang: Language) => {
    if (lang.code === currentLang.code) {
      e.preventDefault();
      return;
    }

    if (cookieLang !== lang.lang) {
      setCookieLang(lang.lang);
    }

    setCurrentLang(lang);
  };

  return (
    <div className={styles.block}>
      {onBack && onClose &&
        <DrawerHeader
          heading="Deliver to"
          onBack={onBack}
          onClose={onClose}
        />
      }

      {languages.slice().sort((a, b) => a.title > b.title ? 1 : -1).map(x => {
        const Icon = x.icon;

        return (
          <a
            key={x.code}
            href={x.url}
            className={styles.item}
            onClick={e => setNewLang(e, x)}
          >
            <Icon className={styles.flag} />
            <span>{x.title}</span>
            {x.code === currentLang.code &&
            <TickIcon className={styles.tick} />}
          </a>
        );
      })}
    </div>
  );
};

export default LanguageSwitcher;
