/* @flow */

import type { ProductSort } from "shop-state/types";

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";

type Props = {
  items: Array<ProductSort>,
  value: ?string,
  onChange: (o: any) => void,
};

const DropdownSort = ({ items, value, onChange }: Props) => {
  const t = useTranslate();

  return (
    <Dropdown
      placeholder={t("FILTER.SORT")}
      value={value}
      variant="dropdownSort"
      onChange={onChange}
    >
      {items.map(item => (
        <DropdownItem
          key={item.code}
          value={item.code}
        >
          {item.label}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

export default DropdownSort;
