/* @flow */

import React from "react";
import { withRouter } from "react-router";
import styles from "./styles.scss";
import cn from "classnames";
import CartIcon from "icons/cart.svg";
import { QuoteData } from "data";
import { useData } from "crustate/react";
import Spinner from "components/Spinner";

type Props = {
  className: string,
  location: { pathname: string },
  isOpen: boolean,
  openMiniCart: () => void,
  history: {
    push: (path: string) => void,
    goBack: () => void,
  },
  useSpinner: boolean,
};

const CartCounter = ({ className = "", location, history, isOpen, openMiniCart, useSpinner = false }: Props) => {
  const data = useData(QuoteData);
  const onCheckout = location.pathname.includes("/checkout");
  const cartCount =
    data.state === "LOADED" || data.state === "ADDING_ITEM" ?
      data.data.items.reduce((a, { qty }) => a + qty, 0) :
      0;

  const showSpinner = useSpinner &&
    ["ADDING_ITEM", "REMOVING_ITEM", "UPDATING_ITEM"].includes(data.state);

  if (cartCount > 0) {
    return (
      <span
        className={cn(
          styles.block,
          { [styles.active]: onCheckout || isOpen },
          className
        )}
        onClick={() => {
          if (onCheckout) {
            history.goBack();
          }
          else if (cartCount) {
            openMiniCart();
          }
          else {
            history.push("/checkout/cart");
          }
        }}
      >
        <span className={styles.count}>
          {cartCount}
        </span>
        <CartIcon />
      </span>
    );
  }

  return (
    <div className={cn(styles.block, className)}>
      {showSpinner &&
        <Spinner className={styles.spinner} />
      }
      <CartIcon />
    </div>
  );
};

export default withRouter(CartCounter);
