/* @flow */

import type { ShippingAddressInput, BillingAddressInput, QuotePointPayment } from "shop-state/types";
import type { QuoteResponse } from "@crossroads/shop-state/quote";

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { useSendMessage, useData } from "crustate/react";
import { QuoteData, VerifyData } from "data";
import { addMessage, addMessageTranslated } from "@crossroads/shop-state/messages";
import { syncCustomer } from "@crossroads/shop-state/customer";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import { QUOTE_SYNC } from "@crossroads/shop-state/quote";
import { setPointsPayment, getQuoteData } from "state/quote";
import Container from "components/CheckoutView/Container";
import { CartSummary, CartSummaryCheckout } from "components/CartSummary";
import CheckoutModal from "components/CheckoutModal";
import PaymentMethods from "components/PaymentMethods";
import Button from "components/Button";
import SuccessIcon from "icons/success.svg";
import { focusInvalidField, removeExampleEmail } from "helpers/utils";
import {
  Form, rules, nestedRule, conditional, isPhone, isRequired,
} from "@awardit/formaggio";
import PointPayment from "../PointPayment";
import DiscountCode from "../DiscountCode";
import Addresses from "../Addresses";
import RejectionReasons from "../RejectionReasons";
import { CheckoutTimerContext, timeout, TIMEOUT } from "helpers/payment-timer";
import { StripeContext, useInitPaymentRequest, CODE as STRIPE_CODE } from "components/Stripe";
import CustomerServiceLink from "../CustomerServiceLink";
import { useSaveAddressOnUpdate } from "./use-save-address-on-update";
import {
  placeOrder as placeOrderMutation,
  quote as quoteQuery,
  customer as customerQuery,
} from "queries";
import { setMode, MODE } from "state/view-mode";
import { pointsPriceByID } from "helpers/points";

import styles from "./styles.scss";

export type FormState = {
  billing: BillingAddressInput,
  shipping: ShippingAddressInput,
  email: string,
  shipToOtherAddress: boolean,
  checkDiscountCode: boolean,
  discountCode: string,
  terms: boolean,
};

const validationAddress = () => rules([
  isRequired("firstname"),
  isRequired("lastname"),
  isRequired("postcode"),
  isPhone("telephone"),
  isRequired("city"),
  nestedRule("street", rules([
    isRequired("0"),
  ])),
]);

const validation = rules([
  isRequired("terms"),
  isRequired("billing"),
  nestedRule("billing", validationAddress()),

  conditional(s => Boolean(s.shipToOtherAddress), rules([
    isRequired("shipping"),
    nestedRule("shipping", validationAddress()),
  ])),
]);

const isCheckoutDisabled = (
  reqPointPayment: boolean, selectedPointPayment: ?QuotePointPayment): boolean => {
  return reqPointPayment && (
    !selectedPointPayment ||
    selectedPointPayment.rejectionReasons.length > 0
  );
};

const EMPTY_ADDRESS = {
  city: "",
  countryCode: "",
  firstname: "",
  lastname: "",
  postcode: "",
  street: [],
  telephone: "",
};

const initialFormState = ({ quote, quoteBilling, quoteShipping, defaultCountry }) => ({
  email: quote.email || "",
  telephone: "",
  billing: quoteBilling ? {
    city: quoteBilling.city,
    countryCode: quoteBilling.country ?
      quoteBilling.country.code :
      defaultCountry.code,
    firstname: quoteBilling.firstname,
    lastname: quoteBilling.lastname,
    postcode: quoteBilling.postcode,
    street: quoteBilling.street,
    telephone: quoteBilling.telephone,
  } : EMPTY_ADDRESS,
  shipping: quoteShipping ? {
    city: quoteShipping.city,
    countryCode: quoteShipping.country ?
      quoteShipping.country.code :
      defaultCountry.code,
    firstname: quoteShipping.firstname,
    lastname: quoteShipping.lastname,
    postcode: quoteShipping.postcode,
    street: quoteShipping.street,
    telephone: quoteBilling.telephone,
  } : EMPTY_ADDRESS,
  shipToOtherAddress: quoteBilling.type === "billing" ?
    !quoteBilling.isUsedAsShipping :
    false,
  checkDiscountCode: Boolean(quote.coupon !== null && quote.coupon.code),
  discountCode: "",
  terms: false,
});

/* eslint-disable complexity */
const Checkout = () => {
  const t = useTranslate();
  const { push } = useHistory();
  const client = useClient();
  const gaContext = useContext(AnalyticsContext);
  const sendMessage = useSendMessage();
  const quoteData = useData(QuoteData);
  const verifyData = useData(VerifyData);
  const quote = getQuoteData(quoteData) || {};
  const addresses = quote.addresses || [];
  const quoteBilling = addresses.find(x => x.type === "billing") || {};
  const quoteShipping = addresses.find(x => x.type === "shipping") || {};
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const { setCheckoutTimerStep } = useContext(CheckoutTimerContext);
  const { stripe, stripePaymentReq, payWithStripe } = useContext(StripeContext);
  const { info: { defaultCountry, locale } } = useContext(StoreInfoContext);
  const { payment, selectedPointPayment, availablePointPayments, requiresPointPayment } = quote;
  const pointsOnly = payment?.code === "free"; // Quote is only payable by points
  const availablePointPayment = availablePointPayments ? pointsPriceByID(availablePointPayments, "scandic") : null;

  const formErrors = Array.isArray(quote.validationErrors) && quote.validationErrors.length > 0;

  const [state, setState] = useState<FormState>(initialFormState({
    quote,
    quoteBilling,
    quoteShipping,
    defaultCountry,
  }));

  useEffect(() => {
    gaContext.registerBeginCheckoutProcess(
      quote.items,
      quote.grandTotal.incVat
    );
  }, []);

  // Set selected point payment when landing on this page
  useEffect(() => {
    if (quoteData.state === "LOADED" && availablePointPayment && !selectedPointPayment) {
      sendMessage(setPointsPayment("scandic"));
    }
  }, [quoteData]);

  // TODO: Maybe update formaggio types to be more accepting or become
  //       structured to accept a specific structure
  const errors = validation((state: any));

  if (quoteData.errors && Object.keys(quoteData.errors).length > 0) {
    const keys = Object.keys(quoteData.errors);

    for (const key of keys) {
      const error = quoteData.errors[key];
      if (!errors.some(e => e.field === key)) {
        errors.push({
          error,
          field: key,
        });
      }
    }
  }

  useInitPaymentRequest();

  useEffect(() => {
    if (state.shipToOtherAddress && Object.keys(quoteShipping).length === 0) {
      setState({
        ...state,
        shipping: state.billing,
      });
    }
  }, [state.shipToOtherAddress]);

  // Show errors in address at page visit
  useEffect(() => {
    const fields = errors.reduce((acc, curr) => acc + curr.field, "");
    if (fields.includes("billing") || fields.includes("shipping")) {
      setEditingAddress(true);
    }
  }, []);

  const handleErrors = e => {
    setProcessing(false);
    setCheckoutTimerStep(null);

    if (typeof e !== "object" || Array.isArray(e)) {
      return;
    }

    if (Array.isArray(e.errors)) {
      if (e.errors.some(error => error.message === "errorInsufficientScandicPointsForPurchase")) {
        sendMessage(
          addMessage("errorInsufficientScandicPointsForPurchase", "error")
        );
      }
      else if (e.errors.some(error => error.extensions?.code === "scandic_token_invalid")) {
        sendMessage(setMode(MODE.LOGIN));
      }
      else {
        for (const error of e.errors) {
          const errorCode = error.message.replace(/\./g, "").replace(/ /g, "_").toUpperCase();
          sendMessage(addMessage(errorCode, "error"));
        }
      }
    }
    else {
      sendMessage(addMessageTranslated(e.message, "error"));
    }
  };

  const handleStripeErrors = e => {
    setProcessing(false);
    setCheckoutTimerStep(null);

    if (typeof e !== "object" || Array.isArray(e)) {
      return;
    }

    sendMessage(addMessageTranslated(e.message, "error"));
  };

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProcessing(true);

    if (payment?.code === STRIPE_CODE) {
      payWithStripe()
        .catch(e_ => {
          handleStripeErrors(e_);
          return Promise.reject();
        })
        .then(() => {
          submitInner();
        })
        .catch(handleErrors);
    }
    else if (verifyData.state === "VERIFIED") {
      try {
        submitInner();
      }
      catch (e_) {
        handleErrors(e_);
      }
    }
    else {
      setShowAuth(true);
    }
  };

  const submitInner = async () => {
    // Start timer with placeOrder call
    setCheckoutTimerStep("CREATING");

    try {
      const { placeOrder } = await client(placeOrderMutation);
      setCheckoutTimerStep("ALLOCATING");
      await timeout(TIMEOUT);

      if (placeOrder.result !== "success") {
        sendMessage(addMessage(placeOrder.result, "error"));

        // Sync quote and end submitting order
        const { quote: newQuoteData } = await client(quoteQuery);

        if (newQuoteData) {
          sendMessage(({
            tag: QUOTE_SYNC,
            data: removeExampleEmail(newQuoteData),
          }: QuoteResponse));
        }

        setProcessing(false);
        setCheckoutTimerStep(null);
        return;
      }

      // Sync new customer data to get the new point balance etc
      setCheckoutTimerStep("COMPLETE");
      const { customer } = await client(customerQuery);
      sendMessage(syncCustomer(customer));
    }
    catch (e) {
      handleErrors(e);
      return;
    }

    // If everything worked, proceed with syncing the quote
    // and send user to the successview
    const { quote: quoteData } = await client(quoteQuery);

    if (quoteData) {
      sendMessage(({
        tag: QUOTE_SYNC,
        data: removeExampleEmail(quoteData),
      }: QuoteResponse));
    }

    setProcessing(false);
    setCheckoutTimerStep(null);
    push("/checkout/success");
  };

  useSaveAddressOnUpdate(state);

  const checkoutDisabled = isCheckoutDisabled(requiresPointPayment, selectedPointPayment);

  return (
    <Form
      value={(state: any)}
      errors={errors}
      onError={(e, errors) => {
        focusInvalidField(e, errors);

        const fields = errors.reduce((acc, curr) => acc + curr.field, "");
        if (fields.includes("billing") || fields.includes("shipping")) {
          setEditingAddress(true);
        }
      }}
      onSubmit={submit}
      onChange={(x: any) => {
        if (processing) {
          return;
        }

        const { email, ...rest } = x;

        setState({ ...state, ...rest });
      }}
    >
      <Container
        right={
          <div>
            <div className={styles.row}>
              {selectedPointPayment ?
                <CartSummaryCheckout
                  open={summaryOpen}
                  setOpen={setSummaryOpen}
                /> :
                <CartSummary
                  open={summaryOpen}
                  setOpen={setSummaryOpen}
                />
              }
            </div>

            {showAuth && (
              <CheckoutModal close={isVerified => {
                setShowAuth(false);

                if (isVerified && pointsOnly) {
                  try {
                    submitInner();
                  }
                  catch (e) {
                    handleErrors(e);
                  }
                }
              }} />
            )}

            {!pointsOnly && locale !== "sv_SE" && (verifyData.state === "VERIFIED" ? (
              <div className={styles.verified}><SuccessIcon /><span>{t("CHECKOUT.ACCOUNT_VERIFIED")}</span></div>
            ) : (
              <div className={styles.row}>
                <Button
                  disabled={checkoutDisabled}
                  variant="primary"
                  type="button"
                  className={styles.verify}
                  onClick={() => setShowAuth(true)}
                >
                  {t("CHECKOUT.VERIFY_ACCOUNT")}
                </Button>
                <p className={styles.description}>{t("CHECKOUT.VERIFY_ACCOUNT_DESCRIPTION")}</p>
              </div>
            ))}

            <div className={styles.row}>
              <PaymentMethods
                terms={state.terms}
                disabled={formErrors || checkoutDisabled}
                method={quote.payment}
                loading={!stripe || !stripePaymentReq}
                processing={processing}
                verified={verifyData.state === "VERIFIED"}
              />
            </div>

            <div className={styles.customerService}>
              <CustomerServiceLink />
            </div>
          </div>
        }
      >

        <div className={styles.row}>
          <Addresses
            processing={processing}
            state={state}
            errors={errors}
            editing={editingAddress}
            setEditing={setEditingAddress}
          />
        </div>

        {availablePointPayment &&
          availablePointPayment.points.min.incVat < availablePointPayment.points.available.incVat &&
          <div className={styles.row}>
            <PointPayment processing={quoteData.state !== "LOADED"} />
          </div>
        }

        <RejectionReasons className={styles.row} />

        <div className={styles.row}>
          <DiscountCode
            disabled={processing}
            coupon={quote.coupon}
            state={state}
            setSummaryOpen={setSummaryOpen} />
        </div>

        <div className={styles.row}>
          <div className={styles.deliveryInfo}>
            <h2>{quote.isVirtual ?
              t("CHECKOUT.MODE_OF_DELIVERY", { shippingMethod: t("ACCOUNT.EMAIL") }) :
              t("CHECKOUT.MODE_OF_DELIVERY", { shippingMethod: (quote.shipping ?
                quote.shipping.method.description.split("- ").pop() : "-") })}
            </h2>
            <p>{quote.isVirtual ?
              t("CHECKOUT.VIRTUAL_DELIVERY") :
              t("CHECKOUT.PHYSICAL_DELIVERY", { shippingMethod: (quote.shipping ?
                quote.shipping.method.description.split("- ").pop() : "-") })}
            </p>
          </div>
        </div>
      </Container>
    </Form>
  );
};
/* eslint-enable complexity */

export default Checkout;
