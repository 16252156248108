/* @flow */

import type { OrderHistoryOrder } from "shop-state/types";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Dialogue } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { OrderHistoryData } from "data";
import useFormat from "helpers/use-format";
import { useData, useSendMessage } from "crustate/react";
import Button from "components/Button";
import PaginationBar from "components/AccountView/PaginationBar";
import CloseIcon from "icons/cross.svg";
import {
  getOrderHistory,
  PAGE_SIZE as ORDER_HISTORY_PAGE_SIZE } from "state/order-history";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

type ItemProps = {
  order: OrderHistoryOrder,
  onClick: (order: OrderHistoryOrder) => void,
};

type DialogueOrderProps = {
  order: ?OrderHistoryOrder,
  setOrder: (order: ?OrderHistoryOrder) => void,
};

const OrderHistory = ({ className }: Props) => {
  const t = useTranslate();
  const orderHistory = useData(OrderHistoryData);
  const [openOrder, setOpenOrder] = useState<?OrderHistoryOrder>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const sendMessage = useSendMessage();

  useEffect(() => {
    if (orderHistory.state === "LOADED") {
      const count = Math.ceil(orderHistory.data.totalCount / ORDER_HISTORY_PAGE_SIZE);
      setPageCount(count);
    }
  }, [orderHistory.state, orderHistory.data]);

  const setPage = (pageNum: number) => {
    sendMessage(getOrderHistory(pageNum));
    setCurrentPage(pageNum);
  };

  if (orderHistory.state !== "ERROR" && orderHistory.data.items.length === 0) {
    return (
      <div className={cn(styles.block, className)}>
        <h2 className={styles.heading}>{t("ORDER_HISTORY.TITLE")}</h2>
        <div className={cn(styles.item, styles.empty)}>
          <p>{t("ORDER_HISTORY.NO_TRANSACTIONS")}</p>
          <Link to="/">{t("ORDER_HISTORY.EXPLORE_ASSORTMENT")}</Link>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.block, className)}>
      <h2 className={styles.heading}>{t("ORDER_HISTORY.TITLE")}</h2>
      <div className={styles.content}>
        <div className={styles.list}>
          {orderHistory.state !== "ERROR" && orderHistory.data.items.map(o => {
            return (
              <OrderHistoryItem
                key={o.id}
                order={o}
                onClick={order => setOpenOrder(order)} />
            );
          })}
        </div>
        {pageCount > 1 &&
          <PaginationBar
            currentPage={currentPage}
            setPage={setPage}
            pageCount={pageCount} />
        }
      </div>
      <DialogueOrder order={openOrder} setOrder={setOpenOrder} />
    </div>
  );
};

const OrderHistoryItem = ({ order, onClick }: ItemProps) => {
  const t = useTranslate();
  const { formatDate } = useFormat();

  const deliveryDate = formatDate(order.createdAt);

  return (
    <div className={styles.item} onClick={() => onClick(order)}>
      <div className={styles.info}>
        <span className={styles.id}>{order.id}</span>
        <span className={styles.date}>{deliveryDate}</span>
      </div>
      <Button className={styles.open}>
        {t("ORDER_HISTORY.OPEN")}
      </Button>
    </div>
  );
};

const DialogueOrder = ({ order, setOrder }: DialogueOrderProps) => {
  const t = useTranslate();
  const { formatDate, formatPoints, formatPrice } = useFormat();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (order) {
      setOpen(true);
    }
  }, [order]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      setOrder(null);
    }, 550);
  };

  const { selectedPointPayment, createdAt } = order || {};
  const deliveryDate = formatDate(createdAt);
  const discountPoints = Math.abs(order?.selectedPointPayment?.discount?.points.value || 0);
  const grandTotalCurrency = order?.selectedPointPayment?.currency.incVat || 0;
  const grandTotalPoints = order?.selectedPointPayment?.points.incVat || 0;
  const isVirtual = Boolean(order?.virtual);
  const deliveryAddress = order?.addresses.find(address => address.type === "shipping");

  const shippingPoints = selectedPointPayment?.shipping?.points.incVat || 0;
  const shippingCurrency = selectedPointPayment?.shipping?.currency.incVat || 0;

  return (
    <Dialogue
      style={{ width: "500px", padding: 0 }}
      open={open}
      setOpen={close}
    >
      <div className={styles.dialogue}>
        {order &&
          <>
            <div className={styles.header}>
              <div className={styles.info}>
                <h4>
                  {order.id}
                </h4>
                <p>
                  {deliveryDate}
                </p>
              </div>
              <Button
                className={styles.close_button}
                onClick={close}
              >
                <CloseIcon />
              </Button>
            </div>
            <div className={styles.content}>
              <table>
                <tbody>
                  <tr>
                    <td className={styles.title}>{t("ORDER_HISTORY.ORDER_NO")}</td>
                    <td>{order.id}</td>
                  </tr>
                  <tr>
                    <td className={styles.title}>{t("ORDER_HISTORY.ORDER_DATE")}</td>
                    <td>{deliveryDate}</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td className={styles.title}>{t("ORDER_HISTORY.ORDER_CONTENT")}</td>
                    <td />
                  </tr>
                  {order.items.map(item => {
                    return (
                      <tr key={`${order.id}_${item.product.name}`} className={styles.product}>
                        <td>{`${item.product.name} x ${item.qty}`}</td>
                        <td>{`${item.product.name} x ${item.qty}`}</td>
                      </tr>
                    );
                  })
                  }
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td className={styles.title}>{t("ORDER_HISTORY.SHIPPING")}</td>
                    <td>
                      {shippingPoints > 0 &&
                        <p>{formatPoints(shippingPoints)}</p>
                      }
                      {shippingCurrency > 0.01 &&
                        <p>{formatPrice(shippingCurrency)}</p>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>

              {discountPoints > 0.01 &&
                <table>
                  <tbody>
                    <tr>
                      <td className={styles.title}>{t("ORDER_HISTORY.DISCOUNT")}</td>
                      <td>{formatPoints(discountPoints)}</td>
                    </tr>
                  </tbody>
                </table>
              }

              <table>
                <tbody>
                  <tr>
                    <td className={styles.title}>{t("ORDER_HISTORY.PAYMENT")}</td>
                    <td>
                      {grandTotalPoints > 0 &&
                        <p>{formatPoints(grandTotalPoints)}</p>
                      }
                      {grandTotalCurrency > 0.01 &&
                        <p>{formatPrice(grandTotalCurrency)}</p>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td className={styles.title}>{t("ORDER_HISTORY.DELIVERED_TO")}</td>
                    <td className={styles.address}>
                      {deliveryAddress &&
                        <>
                          <span>{`${deliveryAddress.firstname} ${deliveryAddress.lastname}, `}</span>
                          <span>{`${deliveryAddress.street[0]}, `}</span>
                          <span>{`${deliveryAddress.postcode} ${deliveryAddress.city}`}</span>
                        </>
                      }
                      {isVirtual &&
                        <span>{order.email}</span>
                      }
                    </td>
                  </tr>
                  {!isVirtual && deliveryAddress &&
                    <tr>
                      <td className={styles.title}>{t("ORDER_HISTORY.OTHER_DETAILS")}</td>
                      <td className={styles.address}>
                        <span>{`${deliveryAddress.telephone}, `}</span>
                        <span>{`${order.email}`}</span>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td className={styles.title}>
                      {t("ORDER_HISTORY.ORDER_STATUS")}
                    </td>
                    <td>
                      {t(`ORDER_HISTORY.STATUS.${order.status ?
                        order.status.toLocaleUpperCase() :
                        "DEFAULT"}`
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        }
      </div>
    </Dialogue>
  );
};

export default OrderHistory;
