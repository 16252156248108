/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";
import type { MinimalProductCardProduct } from "shop-state/types";

import React, { useRef } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Pixel from "components/Pixel";
import styles from "./styles.scss";
import { useCurrentSrc } from "helpers/utils";

type MinimalProductCardProps = {
  product: MinimalProductCardProduct,
  className?: string,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

const MinimalProductCard = ({ product, className, breadcrumbLinks }: MinimalProductCardProps) => {
  const imageRef = useRef();
  // Default to smallest image
  const { largeImage } = product.attributes;
  const image = useCurrentSrc(imageRef, largeImage?.x1 || "");

  return (
    <Link
      className={cn(styles.block, className)} to={{
        pathname: product.url,
        state: { hint: {
          type: "product",
          product,
          image,
        },
        breadcrumbLinks },
      }}
    >
      <picture className={styles.imageWrapper}>
        {largeImage &&
          <source
            srcSet={`${largeImage.x1} 1x, ${largeImage.x2} 2x`}
          />
        }
        <img
          ref={imageRef}
          alt={product.name}
          src={largeImage?.x2}
          className={styles.image}
        />
        <Pixel className={styles.imagePixel} />
      </picture>

      <div className={styles.minimalBody}>
        <span className={styles.minimalName}>
          {product.name}
        </span>
      </div>
    </Link>
  );
};

export default MinimalProductCard;
