/* @flow */

import type { PointsPrice } from "helpers/points";

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import useFormat from "helpers/use-format";
import { getPriceSplit } from "helpers/points";
import { useTranslate } from "@awardit/react-use-translate";

type Props = {
  pointsPrice?: ?PointsPrice,
  className?: string,
};

const PriceSplit = ({ pointsPrice, className }: Props) => {
  const t = useTranslate();
  const { formatPoints, formatPrice } = useFormat();
  const priceSplit = getPriceSplit(pointsPrice);
  const { info: { usePoints } } = useContext(StoreInfoContext);

  if (!priceSplit) {
    return null;
  }

  if (!usePoints) {
    return (
      <div className={className}>
        <div>
          <span>{formatPrice(priceSplit.values[0].currency)}</span>
        </div>
      </div>
    );
  }

  const renderSplit = (s, i) => {
    switch (s.type) {
      case "points":
        return (
          <div key={i}>
            {i > 0 && <span>{t("OR")}&nbsp;</span>}
            <span>{formatPoints(s.values.points)}</span>
          </div>
        );
      case "currency":
        return (
          <div key={i}>
            {i > 0 && <span>{t("OR")}&nbsp;</span>}
            <span>{formatPrice(s.values.currency)}</span>
          </div>
        );
      case "split":
        return (
          <div key={i}>
            {i > 0 && <span>{t("OR")}&nbsp;</span>}
            <span>
              <span>{formatPoints(s.values.points)}</span>
              <span>&nbsp;+&nbsp;{formatPrice(s.values.currency)}</span>
            </span>
          </div>
        );
      default:
    }
  };

  return (
    <div className={className}>
      {priceSplit.map(renderSplit)}
    </div>
  );
};

export default PriceSplit;
