/* @flow */

import { useData } from "crustate/react";
import { CustomerData } from "data";

const useCustomer = () => {
  const customerData = useData(CustomerData);
  const customer = customerData.data ? customerData.data : null;
  const loggedIn = customer !== null;

  return {
    loggedIn,
    customer,
  };
};

export default useCustomer;
