/* @flow */

import type { ShippingAddressInput, BillingAddressInput } from "shop-state/types";

import { deepEquals } from "diskho";
import React, { useEffect, useContext, useState } from "react";
import { setAddresses } from "@crossroads/shop-state/quote";
import { QuoteData } from "data";
import { useData, useSendMessage } from "crustate/react";
import { getQuoteData } from "state/quote";
import { StoreInfoContext } from "entrypoint/shared";

type FormState = {
  billing: BillingAddressInput,
  shipping: ShippingAddressInput,
  email: string,
  shipToOtherAddress: boolean,
};

const ADDRESS_KEYS = [
  "city",
  "firstname",
  "lastname",
  "postcode",
  "street",
  "telephone",
];

const compareAddress = (primary: {}, secondary: {}) => {
  let isSame = true;

  const compare = (first, second) => {
    for (const key in first) {
      if (!Object.prototype.hasOwnProperty.call(first, key)) {
        continue;
      }

      const f = first[key];
      const s = second[key];

      // Compare array
      if (key === "street") {
        const sf = (Array.isArray(f) ? f : Object.values(f || {})).filter(x => x);
        const ss = (Array.isArray(s) ? s : Object.values(s || {})).filter(x => x);

        if (deepEquals(sf, ss)) {
          continue;
        }
        else {
          isSame = false;
          return;
        }
      }

      // Compare falsy values
      if (Boolean(f) === false || Boolean(s) === false) {
        if (Boolean(f) === Boolean(s)) {
          continue;
        }
        else {
          isSame = false;
          return;
        }
      }

      // Compare primitive values
      if (f !== s) {
        isSame = false;
        return;
      }
    }
  };

  compare(primary, secondary);
  compare(secondary, primary);

  return isSame;
};

export const useSaveAddressOnUpdate = (state: FormState) => {
  const sendMessage = useSendMessage();
  const { info: { defaultCountry } } = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(quoteData) || {};
  const [prevShipToOtherAddress, setPrevShipToOtherAddress] = useState(state.shipToOtherAddress);
  const [prevEmail] = useState(state.email);

  const addresses = quote.addresses || [];

  const quoteBilling = addresses.find(x => x.type === "billing") || {};
  const quoteShipping = addresses.find(x => x.type === "shipping") || {};

  useEffect(() => {
    if (quoteData.state !== "LOADED") {
      return;
    }

    const billing = Object.fromEntries(
      Object.entries(quoteBilling).filter(([key]) => ADDRESS_KEYS.includes(key))
    );

    const shipping = Object.fromEntries(
      Object.entries(quoteShipping).filter(([key]) => ADDRESS_KEYS.includes(key))
    );

    const countryCodeBilling = Object.prototype.hasOwnProperty.call(quoteBilling, "country") ?
      quoteBilling.country.code :
      null;

    if (countryCodeBilling) {
      billing.countryCode = countryCodeBilling;
    }
    else {
      billing.countryCode = defaultCountry.code;
    }

    if (Object.keys(shipping).length > 0 && state.shipToOtherAddress) {
      const countryCodeShipping = Object.prototype.hasOwnProperty.call(quoteShipping, "country") ?
        quoteShipping.country.code :
        null;

      if (countryCodeShipping) {
        shipping.countryCode = countryCodeShipping;
      }
      else {
        shipping.countryCode = defaultCountry.code;
      }

      shipping.telephone = billing.telephone;
    }

    if (!compareAddress(billing, state.billing) ||
      (!state.shipToOtherAddress && state.shipToOtherAddress !== prevShipToOtherAddress) ||
      (state.shipToOtherAddress && (
        (Object.keys(shipping).length > 0 && !compareAddress(shipping, state.shipping)) ||
        (Object.keys(shipping).length === 0 && !compareAddress(state.billing, state.shipping)))
      )) {
      sendMessage(
        setAddresses(prevEmail, state.billing, state.shipping, !state.shipToOtherAddress)
      );
    }

    setPrevShipToOtherAddress(state.shipToOtherAddress);
  }, [
    quoteBilling,
    quoteShipping,
    state,
    sendMessage,
    defaultCountry.code,
    prevShipToOtherAddress,
    quoteData,
    prevEmail,
  ]);
};
