/* @flow */

/* eslint-disable complexity */

import type { Customer, AbstractAddressInput } from "shop-state/types";

import type { Model } from "crustate";

import { updateData, updateNone, EFFECT_ERROR } from "crustate";

type Data =
  | {| +state: "INITING" |}
  | {| +state: "SAVING_ADDRESS", +data: Customer |}
  | {| +state: "FETCHING_LOGIN_TOKEN" |}
  | {| +state: "FETCHED_LOGIN_TOKEN", +url: string |}
  | {| +state: "LOGGING_IN", +url: string |}
  | {| +state: "LOGGED_IN", +data: Customer |}
  | {| +state: "LOGGING_OUT", +data: Customer |}
  | {| +state: "NOT_LOGGED_IN" |}
  | {| +state: "RESET_PASSWORD" |};

export type CustomerRequest = {
  tag: typeof CUSTOMER_INIT_REQUEST,
} | {
  tag: typeof CUSTOMER_LOGIN_REQUEST,
  code: string,
  state: string,
} | {
  tag: typeof CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST,
  address: AbstractAddressInput,
} | {
  tag: typeof CUSTOMER_SAVE_SHIPPING_ADDRESS_REQUEST,
  address: AbstractAddressInput,
} | {
  tag: typeof CUSTOMER_LOGOUT_REQUEST,
} | {
  tag: typeof FETCH_LOGIN_TOKEN_REQUEST,
};

export type CustomerResponse = {
  tag: typeof CUSTOMER_SYNC,
  data: Customer,
} | {
  tag: typeof CUSTOMER_INIT_RESPONSE,
  data: ?Customer,
} | {
  tag: typeof CUSTOMER_LOGIN_RESPONSE,
  data: ?Customer,
} | {
  tag: typeof CUSTOMER_SAVE_BILLING_ADDRESS_RESPONSE,
  data: Customer,
} | {
  tag: typeof CUSTOMER_SAVE_SHIPPING_ADDRESS_RESPONSE,
  data: Customer,
} | {
  tag: typeof CUSTOMER_LOGOUT_RESPONSE,
} | {
  tag: typeof FETCH_LOGIN_TOKEN_RESPONSE,
  url: string,
} | {
  tag: typeof EFFECT_ERROR,
};

export const REFRESH_PRODUCT_DATA: "customer/refresh_product_data" = "customer/refresh_product_data";

export const CUSTOMER_SYNC: "customer/sync" = "customer/sync";

export const CUSTOMER_INIT_REQUEST: "customer/init/request" = "customer/init/request";
export const CUSTOMER_INIT_RESPONSE: "customer/init/response" = "customer/init/response";

export const FETCH_LOGIN_TOKEN_REQUEST: "customer/fetch_login_token/request" =
    "customer/fetch_login_token/request";
export const FETCH_LOGIN_TOKEN_RESPONSE: "customer/fetch_login_token/response" =
    "customer/fetch_login_token/response";

export const CUSTOMER_LOGIN_REQUEST: "customer/login/request" = "customer/login/request";
export const CUSTOMER_LOGIN_RESPONSE: "customer/login/response" = "customer/login/response";

export const CUSTOMER_LOGOUT_REQUEST: "customer/logout/request" = "customer/logout/request";
export const CUSTOMER_LOGOUT_RESPONSE: "customer/logout/response" = "customer/logout/response";

export const CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST: "customer/save_billing_address/request" =
  "customer/save_billing_address/request";

export const CUSTOMER_SAVE_BILLING_ADDRESS_RESPONSE: "customer/save_billing_address/response" =
  "customer/save_billing_address/response";

export const CUSTOMER_SAVE_SHIPPING_ADDRESS_REQUEST: "customer/save_shipping_address/request" =
  "customer/save_shipping_address/request";

export const CUSTOMER_SAVE_SHIPPING_ADDRESS_RESPONSE: "customer/save_shipping_address/response" =
  "customer/save_shipping_address/response";

export const ABORT_SSO_LOGIN_REQUEST: "customer/abort_sso_login/request" =
  "customer/abort_sso_login/request";

// Actions
export const syncCustomer = (data: Customer) => ({
  tag: CUSTOMER_SYNC,
  data,
});

export const fetchLoginToken = () => ({
  tag: FETCH_LOGIN_TOKEN_REQUEST,
});

export const abortSSOLogin = () => ({
  tag: ABORT_SSO_LOGIN_REQUEST,
});

export const login = (code: string, state: string) => ({
  tag: CUSTOMER_LOGIN_REQUEST,
  code,
  state,
});

export const logout = () => ({
  tag: CUSTOMER_LOGOUT_REQUEST,
});

export const saveBillingAddress = (address: AbstractAddressInput) => ({
  tag: CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST,
  address,
});

export const saveShippingAddress = (address: AbstractAddressInput) => ({
  tag: CUSTOMER_SAVE_SHIPPING_ADDRESS_REQUEST,
  address,
});

const initState = { state: "INITING" };

export const CustomerModel: Model<Data, {}, CustomerRequest | CustomerResponse> = {
  id: "customer",
  init: () => updateData(initState, { tag: CUSTOMER_INIT_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData(initState);

      case ABORT_SSO_LOGIN_REQUEST:
        if (state.state === "FETCHED_LOGIN_TOKEN") {
          return updateData({ state: "NOT_LOGGED_IN" });
        }

        return updateNone();
      case CUSTOMER_SYNC:
        return msg.data ?
          updateData({ state: "LOGGED_IN", data: msg.data }) :
          updateData({ state: "NOT_LOGGED_IN" });

      case CUSTOMER_INIT_RESPONSE:
        if (state.state === "INITING" || state.state === "NOT_LOGGED_IN") {
          return msg.data ?
            updateData({ state: "LOGGED_IN", data: msg.data }) :
            updateData({ state: "NOT_LOGGED_IN" });
        }

        return updateNone();
      case FETCH_LOGIN_TOKEN_REQUEST:
        if (state.state === "NOT_LOGGED_IN" || state.state === "LOGGED_IN") {
          return updateData({
            state: "FETCHING_LOGIN_TOKEN",
          }, msg);
        }

        return updateNone();
      case FETCH_LOGIN_TOKEN_RESPONSE:
        if (state.state === "FETCHING_LOGIN_TOKEN") {
          return updateData({
            state: "FETCHED_LOGIN_TOKEN",
            url: msg.url,
          });
        }

        return updateNone();
      case CUSTOMER_LOGIN_REQUEST:
        if (state.state === "FETCHED_LOGIN_TOKEN") {
          return updateData({
            state: "LOGGING_IN",
            url: state.url,
          }, msg);
        }

        return updateNone();
      case CUSTOMER_LOGIN_RESPONSE:
        if (state.state === "LOGGING_IN") {
          return msg.data ?
            updateData({ state: "LOGGED_IN", data: msg.data }) :
            updateData({ state: "NOT_LOGGED_IN" });
        }

        return updateNone();
      case CUSTOMER_SAVE_BILLING_ADDRESS_RESPONSE:
      case CUSTOMER_SAVE_SHIPPING_ADDRESS_RESPONSE:
        if (state.state === "SAVING_ADDRESS") {
          return updateData({
            state: "LOGGED_IN",
            data: msg.data,
          });
        }

        return updateNone();
      case CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST:
      case CUSTOMER_SAVE_SHIPPING_ADDRESS_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData({
            state: "SAVING_ADDRESS",
            data: state.data,
          }, msg);
        }

        return updateNone();
      case CUSTOMER_LOGOUT_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData({
            state: "LOGGING_OUT",
            data: state.data,
          }, msg);
        }

        return updateNone();
      case CUSTOMER_LOGOUT_RESPONSE:
        if (state.state === "LOGGING_OUT") {
          return updateData({ state: "NOT_LOGGED_IN" });
        }

        return updateNone();
      default:
    }
  },
};

export const getCustomerData = (d: Data): ?Customer => {
  if (d.data !== null) {
    return d.data;
  }

  return null;
};

export const getCustomerPoints = (c: ?Customer): number => {
  return (c ? c.points.find(x => x.id === "scandic")?.points : 0) || 0;
};

/* eslint-enable complexity */
