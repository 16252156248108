/* @flow */

import type { Customer } from "shop-state/types";

import React from "react";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  customer: ?Customer,
  children: React$Node,
  className?: string,
};

const StatusbarTier = ({ customer, children, className, ...props }: Props) => {
  const tier = "level" + (customer?.scandic.level?.level.toString() || "");

  return (
    <div
      {...props}
      className={cn(
        styles[tier],
        styles.statusbar,
        className
      )}
    >
      {children}
    </div>
  );
};

export default StatusbarTier;
