/* @flow */

import type { Mode } from "state/view-mode";

import React, { useState, useContext, createContext, useEffect, useCallback } from "react";
import { useData, useSendMessage } from "crustate/react";
import { setMode, MODE } from "state/view-mode";
import useHeaderScroll from "helpers/use-header-scroll";
import { ViewModeData } from "data";

export type Ui = {
  hamburgerOpen: boolean,
  setHamburgerOpen: boolean => void,
  subNavOpen: boolean,
  setSubNavOpen: boolean => void,
  toggleHamburger: string => void,
  closeHamburger: (string, ?Mode) => void,
  openHamburger: () => void,
  isHamburgerOpening: boolean,
  isHamburgerClosing: boolean,
  setModeAndOpenMenu: Mode => void,
  setViewMode: Mode => void,
  viewMode: Mode,
  headerHidden: boolean,
  pageIsScrolled: boolean,
};

export type UiProviderProps = {
  children: React$Node,
};

export const UiContext = createContext<Ui>({});

export const UiProvider = ({ children }: UiProviderProps) => {
  const sendMessage = useSendMessage();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [subNavOpen, setSubNavOpen] = useState(false);
  const [isHamburgerOpening, setIsHamburgerOpening] = useState(false);
  const [isHamburgerClosing, setIsHamburgerClosing] = useState(false);
  const viewMode = useData(ViewModeData);

  const [headerHidden, pageIsScrolled] = useHeaderScroll();

  useEffect(() => {
    if (viewMode === MODE.NORMAL || viewMode === MODE.CART) {
      setHamburgerOpen(false);
    }
  }, [viewMode, setHamburgerOpen]);

  const openHamburger = () => {
    sendMessage(setMode(MODE.NORMAL));
    setIsHamburgerOpening(true);
    setHamburgerOpen(true);
  };

  const closeHamburger = (duration: string, mode: ?Mode) => {
    setIsHamburgerClosing(true);

    setTimeout(() => {
      setSubNavOpen(false);
      setHamburgerOpen(false);
      setIsHamburgerClosing(false);
      sendMessage(setMode(mode ? mode : MODE.NORMAL));
    }, Number.parseInt(duration, 10));
  };

  const toggleHamburger = (duration: string) => hamburgerOpen ?
    closeHamburger(duration) : openHamburger();

  const setModeAndOpenMenu = (mode: Mode) => {
    sendMessage(setMode(mode));
    setSubNavOpen(true);
  };

  const setViewMode = useCallback((mode: Mode) => {
    sendMessage(setMode(mode));

    if (mode === MODE.NORMAL || mode === MODE.CART) {
      setHamburgerOpen(false);
      setSubNavOpen(false);
    }
    else {
      setHamburgerOpen(true);
      setSubNavOpen(true);
    }
  }, [setHamburgerOpen, sendMessage, setSubNavOpen]);

  const defaultContext = {
    hamburgerOpen,
    setHamburgerOpen,
    subNavOpen,
    setSubNavOpen,
    toggleHamburger,
    closeHamburger,
    openHamburger,
    isHamburgerOpening,
    isHamburgerClosing,
    setModeAndOpenMenu,
    setViewMode,
    viewMode,
    pageIsScrolled,
    headerHidden,
  };

  return (
    <UiContext.Provider value={defaultContext}>{children}</UiContext.Provider>
  );
};

const useUI = (): Ui => {
  const ui = useContext(UiContext);

  if (!ui) {
    throw new Error("useUI(): Usage must be wrapped in a <UiProvider />.");
  }

  return ui;
};

export default useUI;
