/* @flow */

import type { Model } from "crustate";

import { updateData, updateNone, EFFECT_ERROR } from "crustate";

type Data =
  | { state: "NOT_VERIFIED" }
  | { state: "FETCHING_CHECKOUT_TOKEN" }
  | { state: "FETCHED_CHECKOUT_TOKEN", url: string }
  | { state: "VERIFYING", url: string }
  | { state: "VERIFIED" };

export type VerifyRequest = {
  tag: typeof VERIFY_REQUEST,
  code: string,
  state: string,
} | {
  tag: typeof FETCH_CHECKOUT_TOKEN_REQUEST,
} | {
  tag: typeof CHECK_VERIFICATION_REQUEST,
};

export type VerifyResponse = {
  tag: typeof VERIFY_RESPONSE,
  data: ?string,
} | {
  tag: typeof FETCH_CHECKOUT_TOKEN_RESPONSE,
  url: string,
} | {
  tag: typeof CHECK_VERIFICATION_RESPONSE,
  data: ?boolean,
} | {
  tag: typeof EFFECT_ERROR,
};

export const FETCH_CHECKOUT_TOKEN_REQUEST: "verify/fetch_checkout_token/request" =
    "verify/fetch_checkout_token/request";
export const FETCH_CHECKOUT_TOKEN_RESPONSE: "verify/fetch_checkout_token/response" =
    "verify/fetch_checkout_token/response";

export const VERIFY_REQUEST: "verify/checkout/request" = "verify/checkout/request";
export const VERIFY_RESPONSE: "verify/checkout/response" = "verify/checkout/response";

export const CHECK_VERIFICATION_REQUEST: "check_verify/request" = "check_verify/request";
export const CHECK_VERIFICATION_RESPONSE: "check_verify/response" = "check_verify/response";

export const fetchCheckoutToken = () => ({
  tag: FETCH_CHECKOUT_TOKEN_REQUEST,
});

export const verify = (code: string, state: string) => ({
  tag: VERIFY_REQUEST,
  code,
  state,
});

export const checkVerification = (): VerifyRequest => {
  return {
    tag: CHECK_VERIFICATION_REQUEST,
  };
};

const initState = { state: "NOT_VERIFIED" };

export const VerifyModel: Model<Data, {}, VerifyRequest | VerifyResponse> = {
  id: "verify",
  init: () => updateData(initState, checkVerification()),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData(initState);

      case FETCH_CHECKOUT_TOKEN_REQUEST:
        if (state.state === "NOT_VERIFIED") {
          return updateData({
            state: "FETCHING_CHECKOUT_TOKEN",
          }, msg);
        }

        return updateNone();
      case FETCH_CHECKOUT_TOKEN_RESPONSE:
        if (state.state === "FETCHING_CHECKOUT_TOKEN") {
          return updateData({
            state: "FETCHED_CHECKOUT_TOKEN",
            url: msg.url,
          });
        }

        return updateNone();
      case VERIFY_REQUEST:
        if (state.state === "FETCHED_CHECKOUT_TOKEN") {
          return updateData({
            state: "VERIFYING",
            url: state.url,
          }, msg);
        }

        return updateNone();
      case VERIFY_RESPONSE:
        if (state.state === "VERIFYING") {
          return msg.data === "success" ?
            updateData({ state: "VERIFIED" }) :
            updateData({ state: "NOT_VERIFIED" });
        }

        return updateNone();
      case CHECK_VERIFICATION_REQUEST:
        return updateNone();
      case CHECK_VERIFICATION_RESPONSE:
        return msg.data === true ?
          updateData({ state: "VERIFIED" }) :
          updateData({ state: "NOT_VERIFIED" });
      default:
    }
  },
};
