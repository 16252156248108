/* @flow */

import type {
  ProductFilterInput,
  ProductFilterInputPrice,
} from "shop-state/types";

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import CloseIconMini from "icons/cross.svg";
import { formatNumber } from "helpers/use-format";

import styles from "./styles.scss";

type Props = {
  filter: ProductFilterInput | ProductFilterInputPrice,
  clearFilter: (filter: ProductFilterInput | ProductFilterInputPrice) => void,
};

const getFilterText = (
  filter: ProductFilterInput | ProductFilterInputPrice,
  t: (string, any) => string): string => {
  if (typeof filter.value !== "undefined") {
    return filter.value;
  }

  const min = typeof filter.minValue === "number" ? formatNumber(`${filter.minValue}`) : "Min";
  const max = typeof filter.maxValue === "number" ? formatNumber(`${filter.maxValue}`) : "Max";

  return t("OCF.PRICE_RANGE", {
    range: `${min} - ${max}`,
  });
};

const ActiveFilter = ({ filter, clearFilter }: Props) => {
  const t = useTranslate();
  const text = getFilterText(filter, t);
  return (
    <div
      className={styles.block}
      onClick={() => clearFilter(filter)}
    >
      <div className={styles.icon}>
        <CloseIconMini />
      </div>
      <div className={styles.text}>
        {text}
      </div>
    </div>
  );
};

export default ActiveFilter;
