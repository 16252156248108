/* @flow */

import type { FilterState } from "helpers/use-filter";

import React from "react";
import cn from "classnames";
import { useSendMessage } from "crustate/react";
import { RangeSlider, Button } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import DropdownSort from "components/DropdownSort";
import Wrapper from "components/Wrapper";
import { setMode, MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  className?: string,
  filterState: FilterState,
};

const Filterbar = ({
  className,
  filterState,
}: Props, ref) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();

  if (!filterState.visible) {
    return null;
  }

  return (
    <div ref={ref} className={className}>
      <Wrapper className={styles.block}>
        <div className={styles.block__left}>
          <div className={styles.item}>
            <Button onClick={() => sendMessage(setMode(MODE.FILTER))}>{t("FILTER.ALL_FILTERS")}</Button>
          </div>
          {filterState.price &&
          filterState.price.max > 0 &&
            filterState.price.min !== filterState.price.max &&
            <div className={cn(styles.item, styles.price)}>
              <span>{t(`FILTER.FILTER_BY_${filterState.usePoints ? "POINT" : "PRICE"}`)}</span>
              <div className={styles.slider}>
                <RangeSlider
                  variant="small"
                  value={filterState.price.range}
                  minValue={filterState.price.min}
                  maxValue={filterState.price.max}
                  onChange={x => filterState.price.setRange(x)}
                />
              </div>
              <span>{t(`FILTER.${filterState.usePoints ? "POINT" : "PRICE"}_RANGE`, filterState.price.range)}</span>
            </div>
          }
        </div>
        {filterState.sort.values && filterState.sort.values.length > 0 &&
        <div className={styles.block__right}>
          <div className={styles.item}>
            <DropdownSort
              value={filterState.sort.value}
              items={filterState.sort.values}
              onChange={v => filterState.sort.setValue(v)}
            />
          </div>
        </div>
        }
      </Wrapper>
    </div>
  );
};

export default React.forwardRef<Props, HTMLDivElement>(Filterbar);
