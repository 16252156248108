/* @flow */

import type { Customer } from "shop-state/types";
import type { Mode } from "state/view-mode";
import type { Language } from "components/LanguageSwitcher";

import React, { useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { useSendMessage } from "crustate/react";
import { logout } from "@crossroads/shop-state/customer";
import Wrapper from "components/Wrapper";
import useFormat from "helpers/use-format";
import useUI from "helpers/use-ui";
import { MODE } from "state/view-mode";
import { getCustomerPoints } from "state/customer";

import styles from "./styles.scss";

type Props = {
  className?: string,
  customer: ?Customer,
  customerLoggingIn: boolean,
  closeMenu: Mode => void,
  currentLang?: Language,
  mode: Mode,
};

const Bottom = ({ className = "", customer, currentLang, closeMenu, customerLoggingIn, mode }: Props) => {
  const sendMessage = useSendMessage();
  const { formatPoints } = useFormat();
  const t = useTranslate();
  const { info: { usePoints } } = useContext(StoreInfoContext);
  const { setModeAndOpenMenu } = useUI();
  const loggedIn = customer !== null;

  const login = () => {
    if (!customerLoggingIn) {
      closeMenu(MODE.LOGIN);
    }
  };

  return (
    <Wrapper className={cn(styles.bottom, className)}>
      {loggedIn && customer &&
      <div className={styles.row}>
        <div><Link className={styles.link} to="/account">{t("HEADER.MY_ACCOUNT")}</Link></div>
        {usePoints && formatPoints(getCustomerPoints(customer))}
      </div>}

      <div className={styles.row}>
        <div className={styles.link} onClick={() => setModeAndOpenMenu("language")}>{t("HEADER.DELIVER_TO")}</div>
        <div>{currentLang && currentLang.title}</div>
      </div>

      <div className={styles.row}>
        <a
          className={styles.link}
          href={t("HEADER.GO_TO_SCANDIC_LINK")}
          target="_blank" rel="noopener noreferrer"
        >
          {t("HEADER.GO_TO_SCANDIC")}
        </a>
        <div />
      </div>

      {!loggedIn &&
      <div className={styles.row}>
        <span className={styles.link} onClick={login}>
          {t(customerLoggingIn && mode !== MODE.LOGIN ? "HEADER.LOGGING_IN" : "HEADER.LOGIN")}
        </span>
        <div />
      </div>}

      {loggedIn &&
      <div className={styles.row}>
        <span className={styles.link} onClick={() => sendMessage(logout())}>
          {t("HEADER.LOGOUT")}
        </span>
        <div />
      </div>}
    </Wrapper>
  );
};

export default Bottom;
