/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { CustomerRequest, CustomerResponse } from "state/customer";

import { removeExampleEmail } from "helpers/utils";
import { addMessage } from "@crossroads/shop-state/messages";
import {
  CUSTOMER_INIT_REQUEST,
  CUSTOMER_INIT_RESPONSE,
  CUSTOMER_LOGIN_REQUEST,
  CUSTOMER_LOGIN_RESPONSE,
  CUSTOMER_LOGOUT_REQUEST,
  CUSTOMER_LOGOUT_RESPONSE,
  FETCH_LOGIN_TOKEN_REQUEST,
  FETCH_LOGIN_TOKEN_RESPONSE,
  REFRESH_PRODUCT_DATA,
} from "state/customer";
import {
  customer as customerQuery,
  login,
  logout,
  createScandicLoginToken as createScandicLoginTokenQuery,
} from "queries";

type History = {
  push: (path: string) => void,
};

const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  storage.addEffect({
    effect: async () => {
      try {
        const { customer } = await client(customerQuery);

        return ({
          tag: CUSTOMER_INIT_RESPONSE,
          data: removeExampleEmail(customer),
        }: CustomerResponse);
      }
      catch (e) {
        console.error(e);
        return ({
          tag: CUSTOMER_INIT_RESPONSE,
          data: undefined,
        }: CustomerResponse);
      }
    },
    subscribe: { [CUSTOMER_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: CustomerRequest) => {
      if (msg.tag === FETCH_LOGIN_TOKEN_REQUEST) {
        const { createScandicLoginToken } = await client(createScandicLoginTokenQuery);

        return ({
          tag: FETCH_LOGIN_TOKEN_RESPONSE,
          url: createScandicLoginToken.url,
        }: CustomerResponse);
      }
    },
    subscribe: { [FETCH_LOGIN_TOKEN_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: CustomerRequest) => {
      if (msg.tag === CUSTOMER_LOGIN_REQUEST) {
        try {
          const { login: { result, customer } } = await client(login, {
            code: msg.code,
            state: msg.state,
          });

          if (result !== "success") {
            storage.broadcastMessage(addMessage(result, "error"));

            return ({
              tag: CUSTOMER_LOGIN_RESPONSE,
              data: undefined,
            }: CustomerResponse);
          }

          storage.broadcastMessage({ tag: REFRESH_PRODUCT_DATA });

          return ({
            tag: CUSTOMER_LOGIN_RESPONSE,
            data: removeExampleEmail(customer),
          }: CustomerResponse);
        }
        catch (e) {
          console.error(e);
          storage.broadcastMessage(addMessage(e.getMessage(), "error"));

          return ({
            tag: CUSTOMER_LOGIN_RESPONSE,
            data: undefined,
          }: CustomerResponse);
        }
      }
    },
    subscribe: { [CUSTOMER_LOGIN_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      const { customer } = await client(logout);

      history.push("/");

      return ({
        tag: CUSTOMER_LOGOUT_RESPONSE,
        data: customer,
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_LOGOUT_REQUEST]: true },
  });
};

export default registerClient;
