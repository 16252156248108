/* @flow */

import React, { useEffect, useContext, useState } from "react";
import { SuccessView as SuccessViewExt } from "@crossroads/shop-views";
import { AnalyticsContext } from "@crossroads/analytics";
import { useData } from "crustate/react";
import { load as loadOrder } from "state/order";
import { useClient } from "entrypoint/shared";
import { loadScript } from "helpers/utils";
import { OrderData } from "data";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import useFormat from "helpers/use-format";
import Map from "components/Map";
import CheckIcon from "icons/tick.svg";
import ChatIcon from "icons/customer-service.svg";

import styles from "./styles.scss";

const googleMapsKey = "AIzaSyAxJOZHwCsQNcLYUtOEdWBbHq6aE_2-qrQ";

const SuccessView = () => {
  const orderData = useData(OrderData);
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const gaContext = useContext(AnalyticsContext);
  const { formatPrice } = useFormat();

  useEffect(() =>
    loadScript(
      () => typeof google !== "undefined" && typeof google.maps !== "undefined",
      `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&callback=initGoogleMaps`,
      () => {
        setMapsLoaded(true);
      }
    ),
  []);

  const mapComponent = orderData => {
    if (orderData.state === "LOADED" && mapsLoaded &&
      (orderData.data !== null && orderData.data !== undefined)) {
      const { addresses, id } = orderData.data;
      const shippingAddress = addresses.find(x => x.type === "shipping");
      const countryCode = shippingAddress ? shippingAddress.country.code : "SE";
      const mapAddress = shippingAddress ?
        [shippingAddress.street[0], shippingAddress.city, shippingAddress.postcode] :
        [];

      return (
        <Map
          address={mapAddress}
          className={styles.map}
          countryCode={countryCode}
          identifier={id} />
      );
    }

    return null;
  };

  return (
    <SuccessViewExt
      orderData={orderData}
      useClient={useClient}
      analytics={gaContext}
      formatPrice={formatPrice}
      loadOrder={loadOrder}
      hasVisiblePrices={false}
      additionalField="CUSTOM"
      customAdditionalField={<CustomerServiceLink />}
      mapComponent={mapComponent(orderData)}
      CheckIcon={<CheckIcon style={{ color: "#fff" }} />}
      ChatIcon={<ChatIcon style={{ height: "24px", width: "24px" }} />}
    />
  );
};

export default SuccessView;
