/* @flow */

import type { SortableProductList, ProductFilterInput } from "shop-state/types";
import type { Mode } from "state/view-mode";
import type { FilterState } from "helpers/use-filter";

import React, { useCallback } from "react";
import cn from "classnames";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import { useSendMessage } from "crustate/react";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";
import { Button } from "@crossroads/ui-components";
import { setMode, MODE } from "state/view-mode";
import useUI from "helpers/use-ui";

type Props = {
  className?: string,
  search: SortableProductList,
  query: string,
  filterState: FilterState,
};

const getSearchTitle = (filters: Array<ProductFilterInput>) => {
  return filters.reduce((acc, curr) => {
    if (curr.value) {
      return `${acc}, ${curr.value}`;
    }

    return acc;
  }, "");
};

const SearchHero = ({ className, search, query, filterState }: Props) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { setHamburgerOpen, setSubNavOpen } = useUI();

  const filterTitle = getSearchTitle(filterState.active.filters);

  const setViewMode = useCallback((mode: Mode) => {
    setHamburgerOpen(true);
    sendMessage(setMode(mode));
    setSubNavOpen(true);
  }, [setHamburgerOpen, sendMessage, setSubNavOpen]);

  const filterCount = () => {
    if (search.items.length > 1) {
      return t("SEARCH.PRODUCTS_IN_SEARCH_RESULT", { itemsCount: search.items.length });
    }

    if (search.items.length === 1) {
      return t("SEARCH.PRODUCT_IN_SEARCH_RESULT");
    }

    return t("SEARCH.NO_RESULTS");
  };

  return (
    <div className={cn(styles.block, className)}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.body}>
          <Breadcrumbs
            className={styles.breadcrumbs}
            current={`${t("SEARCH.SEARCH_RESULTS_FOR")} "${query}${filterTitle}"`} />

          {search &&
            <>
              <h1 className={styles.title}>
                {`"${query}${filterTitle}"`}
              </h1>

              {filterState.totalCount > 0 &&
                <p className={styles.itemCount}>
                  {filterCount()}
                </p>
              }

              {filterState.totalCount === 0 && (
                <div className={styles.itemCount}>
                  <span className={styles.emptyItemCount}>
                    {t("CATEGORY.EMPTY")}
                  </span>
                  <div>
                    {filterState.active.filters.length === 0 ?
                      <Button
                        variant="primary"
                        className={styles.emptyItemCount}
                        onClick={() => setViewMode(MODE.SEARCH)}
                      >
                        {t("SEARCH.NEW_SEARCH")}
                      </Button> :
                      <Button
                        variant="primary"
                        className={styles.emptyItemCount}
                        onClick={() => filterState.clearAllFilters()}
                      >
                        {t("FILTER.CLEAR_ALL")}
                      </Button>
                    }
                  </div>
                </div>
              )}
            </>
          }
        </div>
      </Wrapper>
    </div>
  );
};

export default SearchHero;
