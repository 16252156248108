/* @flow */

import type { SortableProductList } from "shop-state/types";

import React, { useRef, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";

import useFixedFilterBar from "helpers/use-fixed-filterbar";
import useUI from "helpers/use-ui";
import useHeaderHeight from "helpers/use-header-height";
import { useData, useSendMessage } from "crustate/react";
import { AllProductsData } from "data";
import cn from "classnames";
import { allProducts } from "@crossroads/shop-state/all-products";
import useFilter from "helpers/use-filter";
import Filterbar from "components/Filterbar";
import OffCanvasFilterMenu from "components/OffCanvasFilterMenu";
import PaginatedProductList from "components/PaginatedProductList";
import { useLocation } from "react-router";
import { PAGE_SIZE } from "effects/route";

import styles from "../CategoryView/styles.scss";

type Props = {
  list: SortableProductList,
  updating: boolean,
};

// TODO: use Pagination instead on server
const AllProducts = ({ list, updating }: Props) => {
  const t = useTranslate();
  const { info: { usePoints } } = useContext(StoreInfoContext);
  const filterbarRef = useRef();
  const filterbarPlaceholderRef = useRef();
  const currentHeaderHeight = useHeaderHeight(styles);
  const { headerHidden } = useUI();
  const sendMessage = useSendMessage();
  const location = useLocation();

  const useFilterStateConfig = {
    loading: updating,
    productList: list,
    usePoints,
    load: location => {
      sendMessage(allProducts(location));
    },
    sortValues: [
      {
        code: `price_desc`,
        label: t("FILTER.SORT_PRICE_DESC"),
      },
      {
        code: `price_asc`,
        label: t("FILTER.SORT_PRICE_ASC"),
      },
      {
        code: `name_asc`,
        label: t("FILTER.SORT_NAME"),
      },
    ],
  };

  const filterState = useFilter(useFilterStateConfig);

  const filterbarFixed = useFixedFilterBar(
    filterbarRef,
    filterbarPlaceholderRef,
    currentHeaderHeight);

  const numPages = Math.ceil(list.totalCount / PAGE_SIZE);

  return (
    <div className={styles.block}>
      <Helmet title={t("ALL_PRODUCTS.TITLE")} />
      <Filterbar
        ref={filterbarRef}
        className={cn(
          styles.filterbar,
          { [styles.filterbar__fixed]: filterbarFixed },
          { [styles.filterbar__fixed_header_hidden]: filterbarFixed && headerHidden }
        )}
        filterState={filterState}
      />
      <div
        ref={filterbarPlaceholderRef}
        className={cn(
          styles.filterbar_placeholder,
          { [styles.filterbar_placeholder__hidden]: !filterbarFixed })} />

      <PaginatedProductList
        updating={updating}
        numPages={numPages}
        items={list}
        productList={list}
        listName="All products"
        breadcrumbLinks={[{
          pathname: "/all-products",
          title: t("ALL_PRODUCTS.TITLE"),
          search: location.search,
          hint: {
            name: t("ALL_PRODUCTS.TITLE"),
          },
        }]}
        category={t("ALL_PRODUCTS.TITLE")}
      />

      <OffCanvasFilterMenu filterState={filterState} />
    </div>
  );
};

const AllProductsView = () => {
  const data = useData(AllProductsData);

  if (data.state === "LOADED" || data.state === "UPDATING") {
    return <AllProducts updating={data.state === "UPDATING"} list={data.data} />;
  }

  return (
    <div className={styles.block}>
      <div className={styles.filterbar_placeholder} />

      <Wrapper className={styles.listWrapper}>
        <ProductList products={[null, null, null, null, null, null, null, null]} />
      </Wrapper>
    </div>
  );
};

export default AllProductsView;
