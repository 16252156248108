/* @flow */

import type { Customer } from "shop-state/types";
import type { Mode } from "state/view-mode";
import type { Language } from "components/LanguageSwitcher";

import React, { useContext, useEffect, useRef } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { useSendMessage } from "crustate/react";
import { logout } from "@crossroads/shop-state/customer";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import CartCounter from "components/AppHeader/CartCounter";
import cn from "classnames";
import SearchMenu from "../SearchMenu";
import Drawer from "../Drawer";
import CartMini from "components/CartMini";
import useFormat from "helpers/use-format";
import useLocale from "helpers/use-locale";
import ChevronIcon from "icons/chevron.svg";
import ExternalLinkIcon from "icons/external-link.svg";
import SystemMessages from "components/SystemMessages";
import StatusbarTier from "components/StatusbarTier";
import LanguageSwitcher, { languages } from "components/LanguageSwitcher";
import ArrowIcon from "icons/arrow.svg";
import GlobeIcon from "icons/globe.svg";
import Logo from "components/AppHeader/Logo";
import { MODE } from "state/view-mode";
import { CheckoutTimerContext } from "helpers/payment-timer";
import { getCustomerPoints } from "state/customer";

import styles from "./styles.scss";

type Props = {
  className: string,
  customer: ?Customer,
  customerLoggingIn: boolean,
  mode: Mode,
  setMode: Mode => void,
  onCheckout: boolean,
  onSuccess: boolean,
  hidden: boolean,
  lastVisited: ?string,
};

type StatusBarProps = {
  customer: ?Customer,
  mode: Mode,
  customerLoggingIn: boolean,
  setMode: Mode => void,
};

export const mapBrands = (brands: $ReadOnlyArray<string>) => {
  const grouped = {};

  brands.forEach(brand => {
    const firstLetter = brand[0].toLowerCase();
    const array = (grouped[firstLetter] || (grouped[firstLetter] = []));

    array.push({
      name: brand,
      url: `/brand/${encodeURIComponent(brand)}`,
    });
  });

  return Object.keys(grouped)
    .sort((a, b) => a.localeCompare(b))
    .map<{ name: string, url: string }>(letter => ({
      name: letter.toUpperCase(),
      id: letter,
      url: "/",
      children: grouped[letter]
        .sort((a, b) => a.name.localeCompare(b.name, { caseInsensitive: true })),
    }));
};

const StatusBar = ({ customer, mode, setMode, customerLoggingIn }: StatusBarProps) => {
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const { formatPoints } = useFormat();
  const { localeUrl } = useLocale();
  const { info: { usePoints, locale } } = useContext(StoreInfoContext);
  const lang = useRef<Language>(languages.find(x => x.lang === locale) || languages[0]);

  const login = () => {
    if (!customerLoggingIn) {
      setMode(MODE.LOGIN);
    }
  };

  const setLang = (l: Language) => {
    lang.current = l;
  };

  useEffect(() => {
    const _locale = locale.replace(/_/, "-");
    const language = languages.find(language => language.lang === _locale);

    if (language && language !== lang.current) {
      lang.current = language;
    }
  }, [locale]);

  return (
    <StatusbarTier customer={customer} className={styles.statusbar}>
      <Wrapper>
        <div className={styles.statusbarWrapper}>
          <div>
            <div className={cn(styles.statusbarItem, styles.scandicfriends)}>
              <span className={styles.pointsShop}>{t("HEADER.POINTS_SHOP")}</span>
            </div>

            <div className={cn(styles.statusbarItem, styles.backToContainer)}>
              <a href={localeUrl} target="_blank" rel="noopener noreferrer" className={cn(styles.statusbarItem, styles.backTo)}>
                <span>{t("HEADER.BACK_TO", { url: localeUrl.replace("https://www.", "") })}</span>
                <ExternalLinkIcon />
              </a>
            </div>
          </div>
          <div>
            <div className={styles.statusbarItem}>
              <span onClick={() => setMode(mode === MODE.LANGUAGE ? MODE.NORMAL : MODE.LANGUAGE)}>
                <GlobeIcon />

                <strong>{t("LANGUAGE.DELIVER_TO")}:</strong>&nbsp;<span>{lang.current.title}</span>

                <ChevronIcon className={styles.languageChevron} />
              </span>
              {mode === MODE.LANGUAGE &&
                <div className={styles.languageSwitcher}>
                  <LanguageSwitcher currentLang={lang.current} setCurrentLang={setLang} />
                </div>
              }
            </div>

            {!customer &&
              <div className={styles.statusbarUser}>
                <Button className={cn(styles.statusbarItem)} onClick={login}>
                  {t(customerLoggingIn && mode !== MODE.LOGIN ? "HEADER.LOGGING_IN" : "HEADER.LOGIN")}
                </Button>
              </div>
            }

            {customer &&
              <div className={styles.statusbarUser}>
                <Link className={styles.statusbarItem} to="/account">
                  <strong>{customer.firstname}</strong>
                  {usePoints && customer.scandic &&
                    <span>
                      &nbsp;&nbsp;{usePoints && formatPoints(getCustomerPoints(customer))}
                    </span>
                  }
                </Link>

                <span className={styles.statusbarSeparator} />

                <strong className={styles.statusbarTextItem}>
                  {customer.scandic.level?.name || ""}
                </strong>

                <span className={styles.statusbarSeparator} />

                <span
                  className={styles.statusbarItem}
                  onClick={() => sendMessage(logout())}
                >
                  {t("HEADER.LOGOUT")}
                </span>
              </div>
            }
          </div>
        </div>
      </Wrapper>
    </StatusbarTier>
  );
};

const AppHeaderLarge = ({
  className = "",
  mode,
  setMode,
  customer,
  onCheckout,
  onSuccess,
  hidden,
  lastVisited,
  customerLoggingIn,
}: Props) => {
  const t = useTranslate();
  const history = useHistory();
  const toggleSearch = () => setMode(mode === MODE.SEARCH ? MODE.NORMAL : MODE.SEARCH);
  const toggleCategories = () => setMode(mode === MODE.CATEGORIES ? MODE.NORMAL : MODE.CATEGORIES);
  const toggleBrands = () => setMode(mode === MODE.BRANDS ? MODE.NORMAL : MODE.BRANDS);
  const { categories, brands } = useContext(StoreInfoContext);
  const { checkoutTimerStep } = useContext(CheckoutTimerContext);
  const loggedIn = customer !== null;

  if (onCheckout) {
    return (
      <div className={styles.container}>
        <StatusBar
          customer={customer}
          customerLoggingIn={customerLoggingIn}
          mode={mode}
          setMode={setMode} />

        <div className={cn(
          styles.block,
          { [styles.hidden]: hidden },
          className)}
        >
          <Wrapper className={styles.wrapper}>
            <Link className={cn(styles.item, styles.item__logo)} to="/">
              <Logo
                onClick={() => setMode(MODE.NORMAL)}
              />
            </Link>

            {checkoutTimerStep ?
              <Button
                key={checkoutTimerStep}
                className={cn("link", styles.timeoutStep)}
                onClick={() => history.goBack()}
              >
                {t(`CHECKOUT.PAYMENT_STEP.${checkoutTimerStep}`)}
              </Button> :
              <Button
                className={cn("link", styles.back)}
                slotLeft={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
                onClick={() => lastVisited ?
                  history.push(lastVisited) :
                  history.push("/")}
              >
                {onSuccess ? t("CHECKOUT.BACK_TO_STORE") : t("CHECKOUT.CONTINUE_SHOPPING")}
              </Button>
            }
          </Wrapper>
        </div>

        <Wrapper>
          <SystemMessages />
        </Wrapper>

        {checkoutTimerStep &&
          <div className={styles.loader} />
        }
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <StatusBar
        customerLoggingIn={customerLoggingIn}
        customer={customer}
        setMode={setMode}
        mode={mode}
      />

      <div className={cn(
        styles.block,
        { [styles.hidden]: hidden },
        className)}
      >
        <Wrapper className={styles.wrapper}>
          <Link className={cn(styles.item, styles.item__logo)} to="/">
            <Logo
              className={styles.logo}
              onClick={() => setMode(MODE.NORMAL)}
            />
          </Link>

          <nav className={styles.nav}>
            <Link
              to="/all-products"
              className={
                cn(
                  styles.item,
                  styles.item__categories,
                  { [styles.active]: mode === MODE.CATEGORIES }
                )
              }
              onClick={() => setMode(MODE.NORMAL)}
            >
              {t("HEADER.ALL_PRODUCTS")}
            </Link>

            <span
              className={
                cn(
                  styles.item,
                  styles.item__categories,
                  { [styles.active]: mode === MODE.CATEGORIES }
                )
              }
              onClick={toggleCategories}
            >
              {t("HEADER.CATEGORIES")}
            </span>

            <span
              className={
                cn(
                  styles.item,
                  styles.item__categories,
                  { [styles.active]: mode === MODE.BRANDS }
                )
              }
              onClick={toggleBrands}
            >
              {t("HEADER.BRANDS")}
            </span>

            <span
              className={
                cn(
                  styles.item,
                  styles.item__search,
                  { [styles.active]: mode === MODE.SEARCH }
                )
              }
              onClick={toggleSearch}
            >
              {t("HEADER.SEARCH")}
            </span>

            <NavLink
              to={t("CUSTOMER_SERVICE.LINK")}
              activeClassName={styles.active}
              className={styles.item}
              onClick={() => setMode(MODE.NORMAL)}
            >
              {t("CUSTOMER_SERVICE.TEXT")}
            </NavLink>

            {loggedIn && <CartCounter
              className={styles.item}
              isOpen={mode === MODE.CART}
              openMiniCart={() =>
                setMode(mode === MODE.CART ? MODE.NORMAL : MODE.CART)
              } />}
          </nav>

        </Wrapper>
      </div>

      {mode === MODE.SEARCH &&
        <nav className={styles.search}>
          <SearchMenu autoFocus />
        </nav>
      }

      {mode === MODE.CATEGORIES &&
        <nav className={styles.categories}>
          <Drawer
            setMode={setMode}
            heading={t("HEADER.CATEGORIES")}
            items={categories}
            onClose={toggleCategories}
          />
        </nav>
      }

      {mode === MODE.BRANDS &&
        <nav className={styles.brands}>
          <Drawer
            setMode={setMode}
            heading={t("HEADER.BRANDS")}
            items={mapBrands(brands)}
            onClose={toggleBrands}
          />
        </nav>
      }

      {mode === MODE.CART &&
        <Wrapper>
          <CartMini className={styles.cartMini} />
        </Wrapper>
      }

      {mode !== MODE.NORMAL &&
        <div className={styles.dim} onClick={() => setMode(MODE.NORMAL)} />
      }

      <Wrapper>
        <SystemMessages />
      </Wrapper>
    </div>
  );
};

export default AppHeaderLarge;
