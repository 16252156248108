/* @flow */

import React from "react";
import cn from "classnames";
import useFormat from "helpers/use-format";
import { useTranslate } from "@awardit/react-use-translate";
import InfoIcon from "icons/info.svg";
import styles from "./styles.scss";

type PriceProps = {
  className?: string,
  paymentPoints: number,
  paymentCash: number,
  pointsOnly: boolean,
};

type PriceBadgesProps = {
  className?: string,
  minimum?: number,
  pointsOnly: boolean,
  pointsOnlyText?: string,
  forcedSplitPayment: boolean,
};

const Price = ({
  paymentPoints,
  paymentCash,
  pointsOnly,
  className,
}: PriceProps) => {
  const { formatPrice, formatPoints } = useFormat();
  const t = useTranslate();

  return (
    <div className={className}>
      <p className={styles.mainPayment}>
        {formatPoints(paymentPoints)}
      </p>
      {paymentCash > 0 && !pointsOnly && (
        <p className={styles.secondaryPayment}>
          {t("OR")} {formatPrice(paymentCash)}
        </p>
      )}
    </div>
  );
};

export const PriceBadges = ({
  className,
  minimum,
  pointsOnly,
  pointsOnlyText,
  forcedSplitPayment,
}: PriceBadgesProps) => {
  const t = useTranslate();
  const pointsOnlyString = pointsOnlyText || t("PRODUCT.PAY_ONLY_WITH_PTS");

  return (
    <div className={cn(className, styles.badges)}>
      {pointsOnly && (
        <div className={styles.pointsOnly}>
          <InfoIcon />
          <span>{pointsOnlyString}</span>
        </div>
      )}
      {forcedSplitPayment && <div>{t("PRODUCT.PAY_AT_LEAST_PTS", { points: minimum })}</div>}
    </div>
  );
};

export default Price;
