/* @flow */

import type {
  FilterableProductList,
  SortableProductList,
  FilterLocation,
  FilterRange,
  ProductFilterBucket,
  ProductFilterBoolean,
  ProductFilterPrice,
  ProductFilterRange,
  ProductFilterInput,
  ProductFilterInputPrice,
  ProductSort,
  ProductSortInput } from "shop-state/types";

import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { debounce, deepEquals } from "diskho";
import {
  getInputFilters,
  getInputSort,
  getPage,
  getSearchFromFilter,
} from "@crossroads/shop-state/filter";
import { sortValueToInput, getAvailableFilters, useUpdateProductList } from "helpers/use-filter-helpers";
import usePrevious from "helpers/use-previous";

export type UseFilterProps = {
  usePoints?: boolean,
  loading: boolean,
  productList: FilterableProductList | SortableProductList,
  load: (location: FilterLocation) => void,
  sortValues?: Array<ProductSort>,
  priceToPoints?: (price: number) => number,
  incVat?: boolean,
};

export type FilterState = {
  price: {
    range: FilterRange,
    min: number,
    max: number,
    setRange: (value: FilterRange) => void,
  },
  sort: {
    setValue: (value: string) => void,
    values: ?Array<ProductSort>,
    value: ?string,
  },
  filters: {
    buckets: Array<ProductFilterBucket>,
    booleans: Array<ProductFilterBoolean>,
    prices: Array<ProductFilterPrice>,
    ranges: Array<ProductFilterRange>,
  },
  active: {
    filters: Array<ProductFilterInput>,
    sort: ?ProductSortInput,
    page: ?number,
  },
  toggleFilter: (filterInput: ProductFilterInput) => void,
  clearAllFilters: () => void,
  loading: boolean,
  totalCount: number,
  usePoints: boolean,
  visible: boolean,
  balance?: number,
};

const useFilter =
  ({
    productList,
    usePoints = false,
    loading,
    load,
    sortValues,
    priceToPoints = (price: number) => price,
    incVat = true,
  }: UseFilterProps): FilterState => {
    const { push, location } = useHistory();
    const floorPrice = (price: number) => Math.floor(priceToPoints(price));
    const ceilPrice = (price: number) => Math.ceil(priceToPoints(price));

    const filters = getInputFilters(location, incVat).filter(f => f.code !== "price");
    const sort = getInputSort(location);
    const page = getPage(location);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
      if (updating && loading) {
        setUpdating(false);
      }
    }, [loading]);

    const availableFilters = getAvailableFilters(productList.filterableBy);

    const _sortValues = sortValues || (
      Array.isArray(productList.sortableBy) ?
        productList.sortableBy :
        null
    );

    const activePriceFilter = filters.find(f => f.code.startsWith("points:"));

    let priceFilter: ?ProductFilterPrice;

    for (const fb of productList.filterableBy) {
      if (fb.__typename === "ProductFilterPrice" && fb.code.startsWith("points:")) {
        priceFilter = fb;
        break;
      }
    }

    const previousPriceFilter: ?ProductFilterPrice = usePrevious(priceFilter);
    const [minPrice, setMinPrice] =
      useState(priceFilter ?
        floorPrice(incVat ? priceFilter.incVat.min : priceFilter.exVat.min) :
        0);
    const [maxPrice, setMaxPrice] =
      useState(priceFilter ? ceilPrice(incVat ?
        priceFilter.incVat.max : priceFilter.exVat.max) :
        100000);

    const [sortValue, setSortValue] = useState<?string>(sort ? `${sort.code}_${sort.order.toLowerCase()}` : null);

    const setRanges = () => {
      if (!activePriceFilter || (typeof activePriceFilter.maxValue !== "number" || typeof activePriceFilter.minValue !== "number")) {
        return {
          min: minPrice,
          max: maxPrice,
        };
      }

      if (activePriceFilter.maxValue < activePriceFilter.minValue) {
        return {
          min: 0,
          max: activePriceFilter.maxValue,
        };
      }

      return {
        min: activePriceFilter.minValue,
        max: activePriceFilter.maxValue,
      };
    };

    const [localPriceRange, setLocalPriceRange] = useState<FilterRange>(setRanges());
    const [priceRange, setPriceRange] = useState<FilterRange>(localPriceRange);
    const previousPriceRange = usePrevious(priceRange) || priceRange;
    const previousSortValueTemp = usePrevious(sortValue);
    const previousSortValue = previousSortValueTemp === undefined ?
      sortValue :
      previousSortValueTemp;
    const applyPriceRange = useRef(debounce(setPriceRange, 200)).current;

    const toggleFilter = (filterInput: ProductFilterInput | ProductFilterInputPrice) => {
      setUpdating(true);
      let activeFilters = filters.map(x => x);

      if (activeFilters.find(x => x.code === filterInput.code)) {
        activeFilters = activeFilters.filter(x => x.code !== filterInput.code);
      }
      else {
        activeFilters.push(filterInput);
      }

      push(location.pathname + getSearchFromFilter(activeFilters, sort, 0));
    };

    const clearAllFilters = () => {
      setUpdating(true);
      push(location.pathname + getSearchFromFilter([], sort, 0));
      setLocalPriceRange({ min: minPrice, max: maxPrice });
    };

    useUpdateProductList(load);

    // Reset min/max-price for slider if range on pricefilter changed
    useEffect(() => {
      if (priceFilter && !deepEquals(priceFilter, previousPriceFilter)) {
        const newMaxPrice = ceilPrice(incVat ? priceFilter.incVat.max : priceFilter.exVat.max);
        const newMinPrice = floorPrice(incVat ? priceFilter.incVat.min : priceFilter.exVat.min);

        if (newMaxPrice !== maxPrice || newMinPrice !== minPrice) {
          setLocalPriceRange({ min: newMinPrice, max: newMaxPrice });
          setPriceRange({ min: newMinPrice, max: newMaxPrice });
          setMinPrice(newMinPrice);
          setMaxPrice(newMaxPrice);
        }
      }
    }, [priceFilter]);

    useEffect(() => {
      applyPriceRange(localPriceRange);
    }, [applyPriceRange, localPriceRange]);

    // Update URL when applying sorts and price filter (not normal filters)
    useEffect(() => {
      if (sortValue !== previousSortValue || !deepEquals(priceRange, previousPriceRange)) {
        const sort = sortValue ? sortValueToInput(sortValue) : null;

        const activeFilters = filters.map(x => x).filter(x => x.code !== priceFilter?.code);

        if (priceRange.min !== minPrice || priceRange.max !== maxPrice) {
          activeFilters.push({
            code: priceFilter?.code || "",
            minValue: priceRange.min !== minPrice ? priceRange.min : minPrice,
            maxValue: priceRange.max !== maxPrice ? priceRange.max : maxPrice,
          });
        }

        push(location.pathname + getSearchFromFilter(activeFilters, sort, 0));
      }
    }, [sortValue, priceRange]);

    return {
      sort: {
        setValue: setSortValue,
        values: _sortValues,
        value: sortValue,
      },
      price: {
        min: minPrice,
        max: maxPrice,
        range: localPriceRange,
        setRange: (r: FilterRange) => {
          if (!loading) {
            setUpdating(true);
            setLocalPriceRange(r);
          }
        },
      },
      filters: availableFilters,
      active: {
        filters,
        sort,
        page,
      },
      toggleFilter,
      clearAllFilters,
      totalCount: productList.totalCount,
      usePoints,
      loading,
      visible: loading || filters.length > 0 || productList.totalCount > 1 || updating,
    };
  };

export default useFilter;
