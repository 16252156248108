/* @flow */

import type { ProductCardProduct, GAProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { useRef, useContext, useEffect } from "react";
import cn from "classnames";
import { AnalyticsContext, ProductLink } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import useWishlist from "helpers/use-wishlist";
import Pixel from "components/Pixel";
import PriceSplit from "components/PriceSplit";
import { useCurrentSrc } from "helpers/utils";
import { pointsPriceByID, isPointsOnly } from "helpers/points";
import useCustomer from "helpers/use-customer";
import WishlistIcon from "components/WishlistIcon";
import InfoIcon from "icons/info.svg";

import styles from "./styles.scss";

type ProductCardProps = {
  product: ProductCardProduct,
  className?: string,
  list?: string,
  position?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

const ProductCard = ({ product, className, breadcrumbLinks, position, list }: ProductCardProps) => {
  const t = useTranslate();
  const imageRef = useRef();
  const gaContext = useContext(AnalyticsContext);
  const { toggleWishlist, inWishlist } = useWishlist({ product });
  // Default to smallest image
  const { tinyImage, smallImage, mediumImage, largeImage } = product.attributes;
  const defaultImage = tinyImage || smallImage || mediumImage || largeImage || { x1: "", x2: "" };
  const image = useCurrentSrc(imageRef, defaultImage.x1);
  const { loggedIn } = useCustomer();
  const category = product.categories.length > 0 ? product.categories[0].name : "";
  const gaRef: { current: null | Element } = useRef(null);
  const pointsPrice = pointsPriceByID(product.pointsPrices, "scandic");
  const pointsOnly = isPointsOnly(pointsPrice);

  const mapGaObject = (product: ProductCardProduct): GAProduct => {
    const gaObject = {
      item_id: product.sku, // eslint-disable-line camelcase
      item_name: product.name, // eslint-disable-line camelcase
      item_brand: product.attributes.manufacturer, // eslint-disable-line camelcase
      price: product.price.incVat,
      index: Number(position) + 1,
      item_list_name: list ?? "", // eslint-disable-line camelcase
      item_category: "", // eslint-disable-line camelcase
    };

    if (product.categories === undefined || product.categories.length === 0) {
      return gaObject;
    }

    product.categories.forEach((c, i) => {
      if (i === 0) {
        gaObject.item_category = c.name; // eslint-disable-line camelcase
      }
      else {
        gaObject[`item_category${i + 1}`] = c.name;
      }
    });

    return gaObject;
  };

  useEffect(() => {
    const gaObject = mapGaObject(product);

    if (!gaRef.current) {
      return;
    }

    gaContext.register(gaRef.current, gaObject);
  }, [gaRef]);

  return (
    <ProductLink
      className={cn(styles.block, className)}
      product={{
        name: product.name,
        sku: product.sku,
        price: product.price,
        qty: 1,
        attributes: {
          manufacturer: product.attributes.manufacturer,
        },
        categories: product.categories,
      }}
      innerRef={gaRef}
      position={Number(position) + 1}
      list={list}
      category={category}
      to={{
        pathname: product.url,
        state: {
          hint: {
            type: "product",
            product,
            image,
          },
          breadcrumbLinks,
          qty: 1,
          list,
          position: Number(position) + 1,
        },
      }}
    >
      <span ref={gaRef} />

      <div className={styles.header}>
        <picture className={styles.imageWrapper}>
          {tinyImage &&
            <source
              srcSet={`${tinyImage.x1} 1x, ${tinyImage.x2} 2x`}
              media={`(max-width: ${parseInt(styles.small, 10) - 1}px)`}
            />
          }
          {smallImage &&
            <source
              srcSet={`${smallImage.x1} 1x, ${smallImage.x2} 2x`}
              media={`(min-width: ${styles.small}px and max-width: ${parseInt(styles.medium, 10) - 1}px)`}
            />
          }
          {mediumImage &&
            <source
              srcSet={`${mediumImage.x1} 1x, ${mediumImage.x2} 2x`}
              media={`(min-width: ${styles.medium}px and max-width: ${parseInt(styles.large, 10) - 1}px)`}
            />
          }
          {largeImage &&
            <source
              srcSet={`${largeImage.x1} 1x, ${largeImage.x2} 2x`}
              media={`(min-width: ${styles.large}px)`}
            />
          }
          <img
            ref={imageRef}
            alt={product.name}
            src={defaultImage.x2}
            className={styles.image}
          />
          <Pixel className={styles.imagePixel} />
        </picture>

        <div className={styles.badges}>
          {pointsOnly && <span><InfoIcon />{t("PRODUCT.POINTS_ONLY")}</span>}
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.top}>
          <span className={styles.name}>
            {product.name}
          </span>
          {loggedIn && product.type !== "configurable" &&
            <WishlistIcon
              className={styles.wishlistIcon}
              size="small"
              inWishlist={inWishlist}
              onClick={() => toggleWishlist()}
            />
          }
          {loggedIn && product.type === "configurable" &&
            <WishlistIcon
              className={styles.wishlistIcon}
              size="small"
              inWishlist={inWishlist}
            />
          }
        </div>

        <p className={styles.brand}>
          {product.attributes.manufacturer}
        </p>

        <PriceSplit className={styles.price} pointsPrice={pointsPrice} />
      </div>
    </ProductLink>
  );
};

export const DummyCard = ({ className }: { className?: string }) => {
  return (
    <div className={cn(className, styles.block, styles.dummy)}>
      <div className={styles.imageWrapper}>
        <Pixel className={styles.image} />
      </div>

      <div className={styles.body}>
        <span className={styles.name}>&nbsp;</span>

        <p className={styles.brand}>&nbsp;</p>

        <div>
          <p className={styles.price}>&nbsp;</p>
          <p className={styles.splitPrice}>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
