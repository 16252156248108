/* @flow */

import React, { useState, useEffect, useRef, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import { useSendMessage, useData } from "crustate/react";
import { debounce } from "diskho";
import { QuoteData } from "data";
import useCustomer from "helpers/use-customer";
import { setPointsPayment, getQuoteData } from "state/quote";
import useFormat from "helpers/use-format";
import { useTranslate } from "@awardit/react-use-translate";
import { Input, Slider, Tooltip, useModal, Dialogue } from "@crossroads/ui-components";
import { Box, BoxHeader, BoxBody } from "components/Box";
import Button from "components/Button";
import useDevice from "helpers/use-device";
import InfoIcon from "icons/info.svg";

import styles from "./styles.scss";

const TipModal = ({ text }: {text: string }) => {
  const t = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const [transitioning, setIsTransitioning] = useState(false);

  const setOpen = v => {
    if (!transitioning) {
      setIsOpen(v);
    }
  };

  const {
    Modal,
  } = useModal(isOpen, setOpen);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <InfoIcon className={styles.infoIcon} />
      </Button>
      <Modal>
        <Dialogue
          title={t("CHECKOUT.POINTS_TITLE")}
          open={isOpen}
          setOpen={v => setIsOpen(v)}
          onOpen={() => setIsTransitioning(false)}
          onClose={() => setIsTransitioning(false)}
        >
          {text}
        </Dialogue>
      </Modal>
    </>
  );
};

const PointPayment = ({ processing }: { processing: boolean }) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { formatPoints, formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(quoteData) || {};
  const { customer } = useCustomer();
  const points = customer?.points.find(x => x.id === "scandic") || null;

  const { info: { locale } } = useContext(StoreInfoContext);

  const isOneColLayout = useDevice("lt", parseInt(styles.oneColLayout, 10));
  const isGtLarge = useDevice("gt", parseInt(styles.large, 10));

  const { selectedPointPayment } = quote;
  const selectedPoints = selectedPointPayment?.points.selected.incVat || 0;

  const [sliderValue, setSliderValueReal] = useState<number>(selectedPoints);
  const [inputValue, setInputValue] = useState<number>(sliderValue);

  const setQuotePointsDebounced = useRef(debounce(x => {
    sendMessage(setPointsPayment("scandic", x));
  }, 100)).current;

  useEffect(() => {
    if (sliderValue !== selectedPoints) {
      setQuotePointsDebounced(sliderValue);
    }
  }, [sliderValue, setQuotePointsDebounced]);

  // Update inputvalue when slider is changed
  useEffect(() => {
    setInputValue(sliderValue);
  }, [sliderValue]);

  useEffect(() => {
    setSliderValue(selectedPoints);
  }, [selectedPoints]);

  if (!selectedPointPayment || !customer) {
    return null;
  }

  const minimumPoints = selectedPointPayment.points.min.incVat;
  const maximumPoints = selectedPointPayment.points.available.incVat;

  const limitPoints = (p: number): number => {
    if (isNaN(p)) {
      return selectedPoints;
    }

    return Math.max(minimumPoints, Math.min(maximumPoints, p));
  };

  const setSliderValue = (v: number) =>
    setSliderValueReal(limitPoints(v));

  if (selectedPointPayment.points.min.incVat >= selectedPointPayment.points.available.incVat) {
    return null;
  }

  const remainingCurrency = selectedPointPayment.currency.remaining.incVat;
  const spendingLimit = points ? points.spendingLimit : null;
  let tooltipText = t("CHECKOUT.POINTS_TOOLTIP");

  if (spendingLimit) {
    tooltipText += ` ${t("CHECKOUT.POINTS_TOOLTIP_POSTFIX", {
      remaining: spendingLimit.remaining,
      limit: spendingLimit.limit,
    })}`;
  }

  return (
    locale !== "sv_SE" ?
      <Box>
        <BoxHeader>
          <h2>{t("CHECKOUT.POINTS_TITLE")}</h2>

          {isOneColLayout ?
            <TipModal text={tooltipText} /> :
            <Tooltip
              direction="top_left"
              title={t("CHECKOUT.POINTS_TITLE")}
              text={tooltipText}
              width={isGtLarge ? 480 : 400}
            >
              <InfoIcon className={styles.infoIcon} />
            </Tooltip>
          }
        </BoxHeader>
        <BoxBody>
          <div className={styles.pointSelect}>
            <div className={styles.slider}>
              <Slider
                value={sliderValue}
                minValue={minimumPoints}
                maxValue={maximumPoints}
                onChange={v => {
                  setSliderValue(limitPoints(v));
                }}
              />

              <div className={styles.sliderLabels}>
                <span>{formatPoints(minimumPoints)}</span>
                <span>{formatPoints(maximumPoints)}</span>
              </div>
            </div>

            <Input
              label={t("CHECKOUT.POINTS")}
              type="number"
              min="0"
              inputMode="numeric"
              pattern="[0-9]*"
              value={inputValue.toString()}
              onChange={e => setInputValue(e.target.value)}
              onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
                const v = parseInt(e.currentTarget.value, 10);
                setSliderValue(limitPoints(v));
              }}
            />
          </div>

          <footer className={cn(styles.summary, { [styles.processing]: processing })}>
            <div className={styles.summary__label}>
              <span><strong>{t("CHECKOUT.AMOUNT_TO_PAY")}</strong></span>
            </div>
            <div className={styles.summary__value}>
              <span><strong>{formatPoints(sliderValue)}</strong></span>
              {remainingCurrency > 0 &&
              <span>+ {formatPrice(remainingCurrency)}</span>
              }
            </div>
          </footer>
        </BoxBody>
      </Box> :
      ""
  );
};

export default PointPayment;
