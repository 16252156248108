/* @flow */

export type TimerStep = "CREATING" | "ALLOCATING" | "COMPLETE" | null;

import { createContext } from "react";

export const TIMEOUT = 3000;

export const timeout = (ms: number) =>
  new Promise<void, void>(resolve => setTimeout(resolve, ms));

export const CheckoutTimerContext = createContext<{
  checkoutTimerStep: TimerStep,
  setCheckoutTimerStep: TimerStep => void,
}>({
  checkoutTimerStep: null,
  setCheckoutTimerStep: () => undefined,
});
