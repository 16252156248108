/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { useBrowser } from "@crossroads-loyalty-solutions/react-use-browser";
import cn from "classnames";
import useFormat from "helpers/use-format";
import { useTranslate } from "@awardit/react-use-translate";
import HeadsupIcon from "icons/headsup.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  customer: Customer,
};

const ltTwoMonths = (expiringAt: Date) => {
  const now = new Date();
  const inTwoMonths = new Date();
  inTwoMonths.setMonth(now.getMonth() + 2);

  return expiringAt < inTwoMonths;
};

const dateFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const ElapsingPoints = ({ customer, className }: Props) => {
  const items = customer.scandic.expiring.filter(x => ltTwoMonths(new Date(x.expiringAt)));
  const t = useTranslate();
  const { info: { locale } } = useContext(StoreInfoContext);
  const { formatPoints } = useFormat();
  const browser = useBrowser();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 48);

  if (!browser || items.length === 0) {
    return null;
  }

  return (
    <div className={cn(styles.block, className)}>
      <h2 className={styles.heading}>{t("ACCOUNT.ELAPSED_POINTS")}</h2>

      {items.map((x, i) => {
        const date = new Date(x.expiringAt);
        const formattedDate = date.toLocaleDateString(locale.replace("_", "-"), dateFormatOptions);
        const now = new Date();
        const daysLeft = Math.round((date - now) / (1000 * 60 * 60 * 24));

        const dateRelative = daysLeft > 1 ? t("ACCOUNT.DAYS_LEFT", { daysLeft }) : t("ACCOUNT.ONE_DAY_LEFT");

        return (
          <p key={i} className={styles.body}>
            <span className={styles.iconWrapper}>
              <HeadsupIcon />
            </span>
            <span>
              {t("ACCOUNT.ELAPSING_POINTS_INFO", { points: formatPoints(x.points), date: formattedDate, dateRelative })}
            </span>
          </p>
        );
      })}
    </div>
  );
};

export default ElapsingPoints;
