/* @flow */

import type {
  SortableProductList,

} from "shop-state/types";

import React, { useRef, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router";
import { useData, useSendMessage } from "crustate/react";
import cn from "classnames";
import { SearchData } from "data";
import Wrapper from "components/Wrapper";
import SearchHero from "components/SearchHero";
import useHeaderHeight from "helpers/use-header-height";
import useHeaderScroll from "helpers/use-header-scroll";
import useFilter from "helpers/use-filter";
import Filterbar from "components/Filterbar";
import useFixedFilterBar from "helpers/use-fixed-filterbar";
import OffCanvasFilterMenu from "components/OffCanvasFilterMenu";
import { useTranslate } from "@awardit/react-use-translate";
import { search } from "@crossroads/shop-state/search";
import PaginatedProductList from "components/PaginatedProductList";
import { PAGE_SIZE } from "effects/route";
import { useLocation } from "react-router-dom";
import styles from "../CategoryView/styles.scss";

type SearchProps = {
  data: SortableProductList,
  query: string,
  usePoints: boolean,
  updating: boolean,
};

type WrapperProps = {
  query: string,
};

const SearchViewWrapper = ({ query }: WrapperProps) => {
  const search = useData(SearchData);
  const { info: { usePoints } } = useContext(StoreInfoContext);

  if (search.state === "LOADED" || search.state === "UPDATING") {
    return (
      <SearchView
        data={search.data}
        query={query}
        usePoints={usePoints}
        updating={search.state === "UPDATING"}
      />
    );
  }

  return null;
};

const SearchView = ({ data, query, usePoints, updating }: SearchProps) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const filterbarRef = useRef(null);
  const filterbarPlaceholderRef = useRef(null);
  const currentHeaderHeight = useHeaderHeight(styles);
  const location = useLocation();

  // TODO: Use custom hook for this to improve performance
  // const four = useDevice("gt", MQ_FOUR - 1);
  // const three = useDevice("gt", MQ_THREE - 1);
  // const perPage = four ? 52 : (three ? 51 : 52);
  // Just an easy way to disable the pagination for now
  const numPages = Math.ceil(data.totalCount / PAGE_SIZE);

  const useFilterStateConfig = {
    loading: updating,
    productList: data,
    usePoints,
    load: location => {
      sendMessage(search(query, location));
    },
    sortValues: [
      {
        code: `price_desc`,
        label: t("FILTER.SORT_PRICE_DESC"),
      },
      {
        code: `price_asc`,
        label: t("FILTER.SORT_PRICE_ASC"),
      },
      {
        code: `name_asc`,
        label: t("FILTER.SORT_NAME"),
      },
    ],
  };

  const filterState = useFilter(useFilterStateConfig);

  const [headerHidden] = useHeaderScroll();
  const filterbarFixed = useFixedFilterBar(
    filterbarRef,
    filterbarPlaceholderRef,
    currentHeaderHeight);

  return (
    <div className={styles.block}>
      <Helmet
        title={`${t("SEARCH.TITLE_PREFIX")}${query}${""}`}
      />
      <section className={styles.row}>
        <SearchHero
          className={styles.hero1}
          search={data}
          query={query}
          filterState={filterState}
        />
      </section>

      <Filterbar
        ref={filterbarRef}
        className={cn(
          styles.filterbar,
          { [styles.filterbar__fixed]: filterbarFixed },
          { [styles.filterbar__fixed_header_hidden]: filterbarFixed && headerHidden }
        )}
        filterState={filterState}
      />
      <div
        ref={filterbarPlaceholderRef}
        className={cn(
          styles.filterbar_placeholder,
          { [styles.filterbar_placeholder__hidden]: !filterbarFixed })} />

      <Wrapper>
        <PaginatedProductList
          updating={updating}
          numPages={numPages}
          items={data}
          productList={data}
          listName="Search results"
          breadcrumbLinks={[{
            pathname: location.pathname,
            search: location.search,
            title: `${t("SEARCH.SEARCH_RESULTS_FOR")} "${query}"`,
            hint: {
              name: `${t("SEARCH.SEARCH_RESULTS_FOR")} "${query}"`,
            },
          }]}
          category={`${t("SEARCH.TITLE_PREFIX")}${query}`}
        />

        <OffCanvasFilterMenu filterState={filterState} />
      </Wrapper>
    </div>
  );
};

export default withRouter(SearchViewWrapper);
