/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { RouteRequest, RouteResponse } from "@crossroads/shop-state/route";
import { ROUTE_REQUEST, ROUTE_RESPONSE } from "@crossroads/shop-state/route";
import { addMessage } from "@crossroads/shop-state/messages";
import { route } from "queries";
import { EFFECT_ERROR } from "crustate";

export const PAGE_SIZE = 32;

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: RouteRequest) => {
      try {
        const { route: data } = await client(
          route,
          {
            path: msg.url.replace("/", ""),
            filter: msg.filters ? msg.filters : null,
            sort: msg.sort,
            pageSize: PAGE_SIZE,
            page: typeof msg.page === "number" ? msg.page : 1,
          },
          { cache: true }
        );

        return ({
          tag: ROUTE_RESPONSE,
          data,
        }: RouteResponse);
      }
      catch (e) {
        storage.broadcastMessage(addMessage(e.getMessage(), "error"));

        return ({
          tag: EFFECT_ERROR,
        });
      }
    },
    subscribe: { [ROUTE_REQUEST]: true },
  });
};

export default registerClient;
