/* @flow */

import type { FilterableProductList } from "shop-state/types";

import React, { useRef, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";
import { PopularData } from "data";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import Filterbar from "components/Filterbar";
import useFixedFilterBar from "helpers/use-fixed-filterbar";
import useFilter from "helpers/use-filter";
import useHeaderScroll from "helpers/use-header-scroll";
import useHeaderHeight from "helpers/use-header-height";
import OffCanvasFilterMenu from "components/OffCanvasFilterMenu";
import Breadcrumbs from "components/Breadcrumbs";
import { load as fetchPopular } from "state/popular";
import styles from "../CategoryView/styles.scss";
import heroStyles from "../BrandHero/styles.scss";

type PopularProps = {
  popularData: FilterableProductList,
  usePoints: boolean,
  updating: boolean,
};

const PopularViewWrapper = () => {
  const popular = useData(PopularData);
  const { info: { usePoints } } = useContext(StoreInfoContext);

  if (popular.state === "LOADED" || popular.state === "UPDATING") {
    return (
      <PopularView
        usePoints={usePoints}
        updating={popular.state === "UPDATING"}
        popularData={popular.data}
      />
    );
  }

  return <HintPopularView />;
};

const PopularHero = () => {
  const t = useTranslate();

  return (
    <div className={heroStyles.block}>
      <Wrapper className={heroStyles.wrapper}>
        <div className={heroStyles.body}>
          <Breadcrumbs className={heroStyles.breadcrumbs} current={t("POPULAR.POPULAR_PRODUCTS")} />
          <h1 className={heroStyles.title}>{t("POPULAR.POPULAR_PRODUCTS")}</h1>
        </div>
      </Wrapper>
    </div>
  );
};

const PopularView = ({ popularData, updating, usePoints }: PopularProps) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const filterbarRef = useRef(null);
  const filterbarPlaceholderRef = useRef(null);
  const currentHeaderHeight = useHeaderHeight(styles);

  const useFilterStateConfig = {
    loading: updating,
    productList: popularData,
    usePoints,
    load: location => {
      sendMessage(fetchPopular(location));
    },
  };

  const filterState = useFilter(useFilterStateConfig);

  const [headerHidden] = useHeaderScroll();
  const filterbarFixed = useFixedFilterBar(
    filterbarRef,
    filterbarPlaceholderRef,
    currentHeaderHeight);

  return (
    <div className={styles.block}>
      <PopularHero />

      <Filterbar
        ref={filterbarRef}
        className={cn(
          styles.filterbar,
          { [styles.filterbar__fixed]: filterbarFixed },
          { [styles.filterbar__fixed_header_hidden]: filterbarFixed && headerHidden }
        )}
        filterState={filterState}
      />
      <div
        ref={filterbarPlaceholderRef}
        className={cn(
          styles.filterbar_placeholder,
          { [styles.filterbar_placeholder__hidden]: !filterbarFixed })} />

      <Wrapper>
        {popularData.items.length > 0 ?
          <ProductList listName="Popular products" loading={updating} products={popularData.items} /> :
          <p className={styles.empty}>{t("CATEGORY.EMPTY")}</p>
        }
        <OffCanvasFilterMenu filterState={filterState} />
      </Wrapper>
    </div>
  );
};

export const HintPopularView = () => (
  <div className={styles.block}>
    <PopularHero />
    <Wrapper>
      <ProductList products={[null, null, null, null, null, null, null, null]} />
    </Wrapper>
  </div>
);

export default PopularViewWrapper;
