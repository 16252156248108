/* @flow */

import React, { useRef, useEffect, useContext } from "react";
import styles from "./styles.scss";
import { withRouter } from "react-router";
import cn from "classnames";
import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext } from "@crossroads/analytics";
import SearchIcon from "icons/search.svg";
import CloseIcon from "icons/cross.svg";
import ChevronIcon from "icons/chevron.svg";

type Props = {
  className?: string,
  heading?: string,
  onClose?: () => void,
  onBack?: () => void,
  history: {
    push: (path: string) => void,
  },
  autoFocus?: boolean,
};

const SearchMenu = ({ className, heading, onClose, onBack, history, autoFocus = false }: Props) => {
  const t = useTranslate();
  const inputRef = useRef(null);
  const gaContext = useContext(AnalyticsContext);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, []);

  const suggestions = [];

  const onSubmit = (e: Event) => {
    e.preventDefault();

    if (inputRef.current !== null) {
      const { value } = inputRef.current;

      if (!value || value.length <= 2) {
        return;
      }

      history.push(`/search/${value}`);
      gaContext.searchTerm(value);
    }
  };

  return (
    <form className={cn(className, styles.block)} onSubmit={onSubmit}>
      {(heading && onBack && onClose) &&
        <Wrapper className={styles.top}>
          <button type="button" className={styles.back} onClick={onBack}>
            <ChevronIcon />
          </button>

          <span className={styles.heading}>{heading}</span>

          <button type="button" className={styles.close} onClick={onClose}>
            <CloseIcon />
          </button>
        </Wrapper>
      }

      <Wrapper className={styles.container}>
        <button type="submit" className={styles.submit}>
          <SearchIcon className={styles.icon} />
        </button>

        <input
          ref={inputRef}
          className={styles.input}
          type="text"
          placeholder={t("SEARCH.PLACEHOLDER")} />
      </Wrapper>

      <section className={styles.suggestions}>
        {suggestions.map(x => (
          <div key={x.id} className={styles.suggestion}>
            <Wrapper className={styles.suggestionWrapper}>
              <div className={styles.suggestionLeft}>
                <img src={x.image} alt={x.name} className={styles.suggestionImage} />

                <div className={styles.suggestionBody}>
                  <div>
                    <p className={styles.suggestionName}>{x.name}</p>
                    <p className={styles.suggestionBrand}>{x.brand}</p>
                  </div>
                </div>
              </div>

              <span className={styles.suggestionPrice}>{x.price}</span>
            </Wrapper>
          </div>
        ))}
      </section>
    </form>
  );
};

SearchMenu.defaultProps = {
  className: "",
  heading: null,
  onClose: null,
  onBack: null,
};

export default withRouter(SearchMenu);
