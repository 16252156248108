/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { VerifyRequest, VerifyResponse } from "state/verify";

import {
  FETCH_CHECKOUT_TOKEN_REQUEST,
  FETCH_CHECKOUT_TOKEN_RESPONSE,
  VERIFY_REQUEST,
  VERIFY_RESPONSE,
  CHECK_VERIFICATION_REQUEST,
  CHECK_VERIFICATION_RESPONSE,
} from "state/verify";
import {
  createScandicCheckoutToken as createScandicCheckoutTokenQuery,
  validateScandicCheckout as validateScandicCheckoutQuery,
  scandicHasCheckoutToken as scandicHasCheckoutTokenQuery,
} from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: VerifyRequest) => {
      if (msg.tag === FETCH_CHECKOUT_TOKEN_REQUEST) {
        const { createScandicCheckout } = await client(createScandicCheckoutTokenQuery);

        return ({
          tag: FETCH_CHECKOUT_TOKEN_RESPONSE,
          url: createScandicCheckout.url,
        }: VerifyResponse);
      }
    },
    subscribe: { [FETCH_CHECKOUT_TOKEN_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: VerifyRequest) => {
      if (msg.tag === VERIFY_REQUEST) {
        const { validateScandicCheckout } = await client(validateScandicCheckoutQuery, {
          code: msg.code,
          state: msg.state,
        });

        return ({
          tag: VERIFY_RESPONSE,
          data: validateScandicCheckout,
        }: VerifyResponse);
      }
    },
    subscribe: { [VERIFY_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: VerifyRequest) => {
      if (msg.tag === CHECK_VERIFICATION_REQUEST) {
        const { scandicHasCheckoutToken } = await client(scandicHasCheckoutTokenQuery);

        return ({
          tag: CHECK_VERIFICATION_RESPONSE,
          data: scandicHasCheckoutToken,
        }: VerifyResponse);
      }
    },
    subscribe: { [CHECK_VERIFICATION_REQUEST]: true },
  });
};

export default registerClient;
