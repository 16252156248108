/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import useFormat from "helpers/use-format";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import { StoreInfoContext } from "entrypoint/shared";
import { getCustomerPoints } from "state/customer";
import LinkIcon from "icons/link.svg";

import Level1Icon from "icons/level/1.svg";
import Level2Icon from "icons/level/2.svg";
import Level3Icon from "icons/level/3.svg";
import LevelTopIcon from "icons/level/4.svg";
import LevelRedIcon from "icons/level/5.svg";

import styles from "./styles.scss";

type Props = {
  customer: Customer,
};

const LevelIcon = ({ customer }: Props) => {
  switch (customer.scandic.level?.level) {
    case 1:
      return <Level1Icon className={styles.icon} />;
    case 2:
      return <Level2Icon className={styles.icon} />;
    case 3:
      return <Level3Icon className={styles.icon} />;
    case 4:
      return <LevelTopIcon className={styles.icon} />;
    case 5:
      return <LevelRedIcon className={styles.icon} />;
    default:
      return null;
  }
};

const AccountViewHero = ({ customer }: Props) => {
  const { formatPoints } = useFormat();
  const t = useTranslate();
  const { info: { usePoints } } = useContext(StoreInfoContext);

  const { firstname, lastname } = customer;

  return (
    <header className={styles.block}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.split}>
          <div className={styles.left}>
            <LevelIcon customer={customer} />
            <h1 className={styles.name}>{`${firstname} ${lastname}`}
              {usePoints && customer.scandic && (
                <>
                  <br />
                  {formatPoints(getCustomerPoints(customer))}
                </>
              )}
            </h1>
          </div>
          <div className={styles.right}>
            <p>

              <span className={styles.level}>{customer.scandic.level?.name || ""}</span>
              <br />
              <span className={styles.membernumber}>{t("ACCOUNT.MEMBER_NUMBER") + `: ${customer.scandic.membershipNumber}`}</span>
            </p>
            {usePoints &&
              <p>
                <span
                  dangerouslySetInnerHTML={{ __html: t("ACCOUNT.POINTS_READ_MORE") }}
                  className={styles.readMore}
                />

                <a
                  href={t("ACCOUNT.POINTS_READ_MORE_LINK")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("ACCOUNT.POINTS_READ_MORE_LINK").replace("https://", "")}
                  <LinkIcon />
                </a>
              </p>
            }
          </div>
        </div>
      </Wrapper>
    </header>
  );
};

export default AccountViewHero;
