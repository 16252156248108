/* @flow */

import type { Product, Category, CmsPage } from "shop-state/types";
import { useContext } from "react";
import { decodeHTML } from "entities";
import striptags from "striptags";
import { StoreInfoContext } from "entrypoint/shared";

type Meta = {
  title: string,
  description?: string,
  canonicals?: Array<{
    rel: string,
    href: string,
  }>,
  og?: {
    title: string,
    description: string,
    type?: string,
    url?: string,
    image?: string,
  },
  twitter?: {},
  product?: {},
  price?: {},
};

type MetaReponse = {
  title: string,
  link: Array<{
    rel: string,
    href: string,
  }>,
  data: Array<{ [key: string]: ?string }>,
};

const ID = x => x;

export const formatMeta = ({
  title,
  description,
  canonicals,
  og = {},
  twitter = {},
  product = {},
  price = {},
}: Meta): MetaReponse => {
  const meta = {
    title,
    data: [],
    link: [],
  };

  const openGraph = {
    title,
    description,
    ...og,
  };

  Object.keys(openGraph).filter(k => openGraph[k]).forEach(k => {
    meta.data.push({ property: `og:${k}`, content: openGraph[k] });
  });
  Object.keys(twitter).filter(k => twitter[k]).forEach(k => {
    meta.data.push({ property: `twitter:${k}`, content: twitter[k] });
  });
  Object.keys(product).filter(k => product[k]).forEach(k => {
    meta.data.push({ property: `product:${k}`, content: product[k] });
  });
  Object.keys(price).filter(k => price[k]).forEach(k => {
    meta.data.push({ property: `product:price:${k}`, content: price[k] });
  });

  if (description) {
    meta.data.push({ name: "description", content: description });
  }

  if (canonicals) {
    canonicals.forEach(a => {
      meta.link.push({
        rel: "canonical",
        href: a.href,
      });
    });
  }

  return meta;
};

export const getCategoryMeta = (category: Category) => {
  const title = category.metaTitle || category.name;
  const description = category.metaDescription || category.description;

  const meta = {
    title,
    data: [{ property: "og:title", content: title }],
  };

  if (description) {
    meta.data.push(
      { name: "description", content: description },
      { property: "og:description", content: description }
    );
  }

  return meta;
};

export const useGetFrontPageMeta = (title: string, description: string): MetaReponse => {
  const { info } = useContext(StoreInfoContext);

  return formatMeta({
    title,
    description,
    canonicals: [{ rel: "canonical", href: info.baseUrl }],
    og: {
      title,
      description,
    },
  });
};

export const useGetProductMeta = (product: Product, t: string => string): MetaReponse => {
  const { info } = useContext(StoreInfoContext);
  if (!product) {
    return {};
  }

  const description = striptags(decodeHTML(
    product.attributes.metaDescription ||
    product.attributes.shortDescription
  ));

  const title = product.attributes.metaTitle || [
    product.name,
    [product.attributes.manufacturer, product.sku].filter(ID).join(" "),
  ].filter(ID).join(", ");

  const { smallImage, manufacturer } = product.attributes;
  const url = `${info.baseUrl}${product.url.slice(1)}`; // No relative urls
  const priceAmount = product.price;
  const priceCurrency = t("LOCALE.CURRENCY");
  // const { sku } = product;
  /*
  const availability = product.isInStock && product.isSalable ?
    "in stock" :
    "out of stock";
  */
  const availability = "in stock";

  return formatMeta({
    title,
    description,
    canonicals: [{ rel: "canonical", href: url }],
    og: {
      type: "product",
      title,
      url,
      description,
      smallImage1x: smallImage?.x1,
    },
    twitter: {
      card: "product",
      title,
      description,
      smallImage1x: smallImage?.x1,
    },
    product: {
      // retailer_item_id: sku,
      brand: manufacturer,
      availability,
    },
    price: {
      amount: priceAmount,
      currency: priceCurrency,
    },
  });
};

export const useGetCmsMeta = (page: CmsPage) => {
  const { title, metaDescription: description } = page;
  const { info } = useContext(StoreInfoContext);
  return formatMeta({
    title,
    description,
    canonicals: [{ rel: "canonical", href: `${info.baseUrl}${page.url.slice(1)}` }],
    og: {
      title,
      description,
    },
  });
};
