/* @flow */

import { useEffect, useState } from "react";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import useHeaderScroll from "helpers/use-header-scroll";

type HeaderHeightProps = {
  headerHeight: string,
  headerHeightSmall: string,
  userHeaderHeight: string,
  small: string,
};

const useHeaderHeight = ({
  headerHeight,
  headerHeightSmall,
  userHeaderHeight,
  small: browserWidthSmall,
}: HeaderHeightProps) => {
  const [headerHidden] = useHeaderScroll();
  const browserDimensions = useBrowserDimensions();
  const [currentHeaderHeight, setCurrentHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerHidden) {
      if (browserDimensions.width <= parseInt(browserWidthSmall, 10)) {
        setCurrentHeaderHeight(0);
      }
      else {
        setCurrentHeaderHeight(parseInt(userHeaderHeight, 10));
      }
    }
    else if (browserDimensions.width <= parseInt(browserWidthSmall, 10)) {
      setCurrentHeaderHeight(parseInt(headerHeightSmall, 10));
    }
    else {
      setCurrentHeaderHeight(parseInt(userHeaderHeight, 10) + parseInt(headerHeight, 10));
    }
  }, [
    browserDimensions.width,
    headerHidden,
    headerHeight,
    headerHeightSmall,
    userHeaderHeight,
    browserWidthSmall,
  ]);

  return currentHeaderHeight;
};

export default useHeaderHeight;
