/* @flow */

import React, { useEffect, useRef, memo } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { parseParams } from "helpers/location-search-string";
import { verify, fetchCheckoutToken } from "state/verify";
import { VerifyData } from "data";
import Spinner from "components/Spinner";

import styles from "./styles.scss";

type Props = {
  close: (boolean) => void,
};

const CheckoutModal = ({ close }: Props) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const verifyData = useData(VerifyData);
  const origin = useRef("");
  const options = `&for_origin=${origin.current}&ui_locales=${t("LOCALE.ISO.639-1")}`;
  const modal = useRef();

  useEffect(() => {
    const modalRef = modal.current;
    disableBodyScroll(modalRef);

    return () => {
      enableBodyScroll(modalRef);
    };
  }, []);

  useEffect(() => {
    sendMessage(fetchCheckoutToken());

    origin.current = window.location.origin;
  }, []);

  useEffect(() => {
    if (verifyData.state === "VERIFIED") {
      close(true);
    }
  }, [verifyData.state]);

  useEffect(() => {
    const loginCallback = window.addEventListener("message", async event => {
      if (event.data.type === "loginCallback") {
        const { code, state } = parseParams(event.data.params);

        if (typeof code === "string" && typeof state === "string") {
          sendMessage(verify(code, state));
        }
      }

      if (event.data === "closeLoginModal") {
        close(verifyData.state === "VERIFIED");
      }
    });

    return () => window.removeEventListener("message", loginCallback);
  }, [sendMessage, close]);

  return (
    <div ref={modal} className={styles.block}>
      <div className={styles.container}>
        {verifyData.state === "FETCHED_CHECKOUT_TOKEN" && (
          <Iframe src={verifyData.url + options} />
        )}

        <div className={styles.spinnerContainer}>
          <Spinner className={styles.spinner} />
        </div>
      </div>
    </div>
  );
};

const Iframe = memo(props => {
  return (
    <iframe
      {...props}
      className={styles.iframe}
      title="Scandic checkout 2FA"
      name="2fa"
    />
  );
});

export default CheckoutModal;
