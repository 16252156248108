/* @flow */

import type { OptionProduct } from "shop-state/types";
import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";
import { Button } from "@crossroads/ui-components";
import { isValidHex } from "helpers/utils";

type Props = {
  items: Array<{
    key?: string,
    buyRequest: ?string,
    product: OptionProduct,
    label: string,
    price?: {
      incVat: number,
    },
  }>,
  value: ?string,
  onChange: (o: any) => void,
};

const stutter = (delay: number, initialDelay: number) => (idx: number): string => {
  return `${initialDelay + (delay * idx)}ms`;
};

const formatConfigColors = rawColor => {
  const colors = rawColor.trim().split("-");

  return colors.every(isValidHex) ? `#${colors.join(", #")}` : "#eee";
};

export const ColorSelect = ({ items, value, onChange }: Props) => {
  const t = useTranslate();
  const _stutter = stutter(40, 100);

  return (
    <div className={styles.cardValue}>
      {items.map((item, idx) => (
        <div
          key={item.buyRequest}
          style={{
            animationDelay: _stutter(idx),
          }}
          className={styles.col}
        >
          <Button
            className={cn(
              styles.button,
              { [styles.active]: value === item.buyRequest },
              { [styles.outOfStock]: item.buyRequest === null }
            )}
            value={item.buyRequest}
            disabled={item.buyRequest === null}
            onClick={e => {
              e.preventDefault();
              onChange(item.buyRequest);
            }}
          >
            <div className={styles.shadow} />

            {item.product.attrLabels.color &&
              <div
                className={styles.color}
                style={{ background: `linear-gradient(${formatConfigColors(item.product.attrLabels.color.title)})` }} />
            }

            {item.label}

            {item.buyRequest === null &&
              <div className={styles.outOfStockLabel}>
                {t("PRODUCT.OUT_OF_STOCK")}
              </div>
            }
          </Button>
        </div>
      ))}
    </div>
  );
};
