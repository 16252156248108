/* @flow */

import { useMemo } from "react";
import { useTranslate } from "@awardit/react-use-translate";

const useSteps = (step: string): ?{ id: string, titleShort: string, titleLong: string } => {
  const t = useTranslate();
  const steps = useMemo(() => [
    {
      id: "cart",
      titleShort: t("CHECKOUT.STEPS.CART.SHORT"),
      titleLong: t("CHECKOUT.STEPS.CART.LONG"),
    },
    {
      id: "placeorder",
      titleShort: t("CHECKOUT.STEPS.CHECKOUT.SHORT"),
      titleLong: t("CHECKOUT.STEPS.CHECKOUT.LONG"),
    },
  ], [t]);

  return step ? steps.find(x => x.id === step) || steps[0] : null;
};

export default useSteps;
