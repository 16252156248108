/* @flow */

import React, { useRef, useContext } from "react";
import type { FilterState } from "helpers/use-filter";
import { useTranslate } from "@awardit/react-use-translate";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import { useData, useSendMessage } from "crustate/react";
import { BrandData } from "data";
import type { SortableProductList } from "shop-state/types";
import BrandHero from "components/BrandHero";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
// import Filterbar from "components/Filterbar";
import useFixedFilterBar from "helpers/use-fixed-filterbar";
import useHeaderHeight from "helpers/use-header-height";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import { load as loadBrand } from "@crossroads/shop-state/brand";
import useFilter from "helpers/use-filter";
import Filterbar from "components/Filterbar";
import OffCanvasFilterMenu from "components/OffCanvasFilterMenu";
import PaginatedProductList from "components/PaginatedProductList";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import useUI from "helpers/use-ui";
import { PAGE_SIZE } from "effects/route";

import styles from "../CategoryView/styles.scss";

type BrandProps = {
  brandData: SortableProductList,
  brandName: string,
  usePoints: boolean,
  updating: boolean,
};

type HintBrandProps = {
  brandName: string,
};

const purgeBucketFromFilterState = (code: string, filterState: FilterState) => {
  return {
    ...filterState,
    filters: {
      ...filterState.filters,
      buckets: filterState.filters.buckets.filter(f => f.code !== code),
    },
  };
};

const BrandViewWrapper = () => {
  const brand = useData(BrandData);
  const { info: { usePoints } } = useContext(StoreInfoContext);

  if (brand.state === "LOADED" || brand.state === "UPDATING") {
    return (
      <BrandView
        brandName={brand.brand}
        brandData={brand.data}
        usePoints={usePoints}
        updating={brand.state === "UPDATING"} />
    );
  }

  return <HintBrandView brandName={brand.state === "LOADING" ? brand.brand : ""} />;
};

const BrandView = ({ brandData, brandName, usePoints, updating }: BrandProps) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const filterbarRef = useRef(null);
  const filterbarPlaceholderRef = useRef(null);
  const currentHeaderHeight = useHeaderHeight(styles);
  const { headerHidden } = useUI();
  const breadcrumbLinks = useBreadcrumbLinks({ current: brandName });
  const location = useLocation();

  const filterbarFixed = useFixedFilterBar(
    filterbarRef,
    filterbarPlaceholderRef,
    currentHeaderHeight);

  const useFilterStateConfig = {
    loading: updating,
    productList: brandData,
    usePoints,
    load: location => {
      sendMessage(loadBrand(brandName, location));
    },
    sortValues: [
      {
        code: `price_desc`,
        label: t("FILTER.SORT_PRICE_DESC"),
      },
      {
        code: `price_asc`,
        label: t("FILTER.SORT_PRICE_ASC"),
      },
      {
        code: `name_asc`,
        label: t("FILTER.SORT_NAME"),
      },
    ],
  };

  const filterState = purgeBucketFromFilterState("manufacturer", useFilter(useFilterStateConfig));

  const numPages = Math.ceil(brandData.totalCount / PAGE_SIZE);

  return (
    <div className={styles.block}>
      <Helmet title={brandName} />

      <BrandHero
        brandName={brandName}
        totalCount={brandData.totalCount}
        breadcrumbLinks={breadcrumbLinks}
      />

      <Filterbar
        ref={filterbarRef}
        className={cn(
          styles.filterbar,
          { [styles.filterbar__fixed]: filterbarFixed },
          { [styles.filterbar__fixed_header_hidden]: filterbarFixed && headerHidden }
        )}
        filterState={filterState}
      />

      <div
        ref={filterbarPlaceholderRef}
        className={cn(
          styles.filterbar_placeholder,
          { [styles.filterbar_placeholder__hidden]: !filterbarFixed })} />

      <PaginatedProductList
        updating={updating}
        numPages={numPages}
        items={brandData}
        productList={brandData}
        listName={brandName}
        breadcrumbLinks={[{
          pathname: `/brand/${encodeURIComponent(brandName)}`,
          search: location.search,
          title: brandName,
          hint: {
            name: brandName,
          },
        }]}
        category={brandName}
      />

      <OffCanvasFilterMenu
        filterState={filterState} />
    </div>
  );
};

export const HintBrandView = ({ brandName }: HintBrandProps) => {
  const breadcrumbLinks = useBreadcrumbLinks({ current: brandName });

  return (
    <div className={styles.block}>
      <Helmet title={brandName} />

      <BrandHero
        brandName={brandName}
        breadcrumbLinks={breadcrumbLinks}
      />

      <Wrapper className={styles.listWrapper}>
        <ProductList products={[null, null, null, null, null, null, null, null]} />
      </Wrapper>
    </div>
  );
};

export default BrandViewWrapper;
