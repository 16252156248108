/* @flow */

import { createStateData } from "crustate/react";

import { BrandModel } from "@crossroads/shop-state/brand";
import { CheckoutStepModel } from "state/checkout-step";
import { CustomerModel } from "state/customer";
import { HomeModel } from "state/home";
import { MessagesModel } from "@crossroads/shop-state/messages";
import { AllProductsModel } from "@crossroads/shop-state/all-products";
import { OrderHistoryModel } from "state/order-history";
import { OrderModel } from "state/order";
import { PopularModel } from "state/popular";
import { CmsModel } from "state/cms";
import { QuoteModel } from "state/quote";
import { RouteModel } from "@crossroads/shop-state/route";
import { SearchModel } from "@crossroads/shop-state/search";
import { ViewModeModel } from "state/view-mode";
import { WishlistModel } from "state/wishlist";
import { WishlistToggleModel } from "state/wishlist-toggle";
import { VerifyModel } from "state/verify";

export const BrandData = createStateData<typeof BrandModel>(BrandModel);
export const CheckoutStepData = createStateData<typeof CheckoutStepModel>(CheckoutStepModel);
export const CustomerData = createStateData<typeof CustomerModel>(CustomerModel);
export const HomeData = createStateData<typeof HomeModel>(HomeModel);
export const MessagesData = createStateData<typeof MessagesModel>(MessagesModel);
export const OrderData = createStateData<typeof OrderModel>(OrderModel);
export const OrderHistoryData = createStateData<typeof OrderHistoryModel>(OrderHistoryModel);
export const PopularData = createStateData<typeof PopularModel>(PopularModel);
export const CmsData = createStateData<typeof CmsModel>(CmsModel);
export const QuoteData = createStateData<typeof QuoteModel>(QuoteModel);
export const RouteData = createStateData<typeof RouteModel>(RouteModel);
export const SearchData = createStateData<typeof SearchModel>(SearchModel);
export const ViewModeData = createStateData<typeof ViewModeModel>(ViewModeModel);
export const WishlistData = createStateData<typeof WishlistModel>(WishlistModel);
export const AllProductsData = createStateData<typeof AllProductsModel>(AllProductsModel);
export const WishlistToggleData = createStateData<typeof WishlistToggleModel>(WishlistToggleModel);
export const VerifyData = createStateData<typeof VerifyModel>(VerifyModel);
