/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import styles from "./styles.scss";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import ChevronIcon from "icons/chevron.svg";
import { QuoteData, CustomerData } from "data";
import { useData } from "crustate/react";
import { pointsPriceByID } from "helpers/points";
import PriceSplit from "components/PriceSplit";
import useFormat from "helpers/use-format";
import { getCustomerPoints } from "state/customer";
import { useTranslate } from "@awardit/react-use-translate";
import { Box, BoxHeader, BoxBody } from "components/Box";

type Props = {
  children?: React$Node,
  open: boolean,
  setOpen: boolean => void,
  className?: string,
};

/* eslint-disable complexity */
const CartSummary = ({
  children,
  open,
  setOpen,
  className,
}: Props) => {
  const t = useTranslate();
  const { formatPoints, formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const customerData = useData(CustomerData);
  const { info: { locale } } = useContext(StoreInfoContext);

  if (!quoteData.data || !customerData.data) {
    return null;
  }

  const {
    items,
    shipping,
    isVirtual,
    grandTotal,
    selectedPointPayment,
  } = quoteData.data;

  const balance = getCustomerPoints(customerData.data);
  const toPayPrice = grandTotal.incVat;

  if (!selectedPointPayment) {
    return null;
  }

  const { discount, points } = selectedPointPayment;
  const shippingValue = selectedPointPayment.shipping?.points.value.incVat || 0;
  const enoughPoints = points.min.incVat < points.available.incVat;
  const toPayPoints = points.selected !== null ?
    points.selected.incVat :
    points.value.incVat;
  const discountValue = Math.abs(discount?.points.value.incVat || 0);
  const subTotal = items.reduce(
    (acc, curr) => acc + (pointsPriceByID(curr.availablePointPayments, "scandic")?.points.value.incVat || 0), 0
  ) + discountValue;

  const toggleOpen = () => setOpen(!open);

  return (
    <div className={cn(
      styles.block,
      {
        [styles.open]: open,
        [styles.processing]: quoteData.state === "UPDATING_ITEM",
      },
      className
    )}
    >
      <Box>
        <BoxHeader className={styles.header} onClick={toggleOpen}>
          <h2>{t("CART.SUMMARY")}</h2>
          <ChevronIcon className={styles.closeIcon} />
        </BoxHeader>
        <BoxBody className={styles.body}>
          <Foldable open={open}>
            <table>
              <tbody>
                <tr className={styles.balance}>
                  <td className={styles.tableSection}>{t("CART.YOUR_BALANCE")}</td>
                  <td className={styles.tableSection}>{formatPoints(balance)}</td>
                </tr>
                <tr>
                  <td>{t("CART.SUBTOTAL")}</td>
                  <td>{formatPoints(subTotal)}</td>
                </tr>
                {(
                  !isVirtual &&
                  shipping &&
                  shipping.method &&
                  shippingValue > 0
                ) &&
                  <tr>
                    <td>{t("CART.SHIPPING", { shippingMethod: shipping.method.description })}</td>
                    <td>
                      {formatPoints(shippingValue)}
                    </td>
                  </tr>
                }

                {discountValue > 0 &&
                  <tr>
                    <td>{t("CART.DISCOUNT")}</td>
                    <td>
                      -{formatPoints(discountValue)}
                    </td>
                  </tr>
                }
                <tr>
                  <td className={styles.tableSection}><strong>{t("CART.GRANDTOTAL")}</strong></td>
                  <td className={styles.tableSection}>
                    <PriceSplit pointsPrice={selectedPointPayment} />
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
          </Foldable>

          <section className={styles.footer}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <h4>{t("CART.TO_PAY")}</h4>
                  </td>

                  {!enoughPoints && locale !== "sv_SE" &&
                    <td>
                      <h4>
                        {formatPoints(points.min.incVat)}
                      </h4>
                      {selectedPointPayment.currency.max.incVat > 0 &&
                        <span>+ {formatPrice(selectedPointPayment.currency.max.incVat)}</span>
                      }
                    </td>
                  }

                  {enoughPoints && locale !== "sv_SE" && typeof toPayPoints === "number" && typeof toPayPrice === "number" &&
                    <td>
                      <h4>{formatPoints(toPayPoints)}</h4>
                      {toPayPrice > 0 &&
                        <span>+ {formatPrice(toPayPrice)}</span>
                      }
                    </td>
                  }
                  {locale === "sv_SE" &&
                  <td>
                    <h4>{formatPoints(points.max.incVat)}</h4>
                  </td>
                  }

                  {enoughPoints && locale !== "sv_SE" && (typeof toPayPoints !== "number" || typeof toPayPrice !== "number") &&
                    <td>
                      <h4>
                        {formatPoints(selectedPointPayment.points.max.incVat)}
                      </h4>
                      {(selectedPointPayment.points.min.incVat > 0 &&
                      selectedPointPayment.currency.min.incVat === 0) &&
                        <span>
                          {t("OR")} {formatPrice(selectedPointPayment.currency.max.incVat)}
                        </span>
                      }
                    </td>
                  }
                </tr>
              </tbody>
            </table>
            {locale === "sv_SE" &&
            <p>Inom kort återkommer möjligheten till delbetalning med en ny smididgare betallösning.
              För närvarande kan man bara handla för poäng.
            </p>
            }
          </section>

          {children}
        </BoxBody>
      </Box>
    </div>
  );
};
/* eslint-enable complexity */

export default CartSummary;
