/* @flow */

import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { Route, Switch } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { getQuoteData } from "state/quote";
import Wrapper from "components/Wrapper";
import { StripePaymentProvider } from "components/Stripe";
import Cart from "components/CheckoutView/Cart";
import Checkout from "components/CheckoutView/Checkout";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import { confirmStripePaymentIntent, createStripePaymentIntent } from "queries";
import useSteps from "helpers/use-steps";

import styles from "./styles.scss";

type Props = {
  match: {
    params: {
      step?: string,
    },
  },
};

const CheckoutView = ({ match }: Props) => {
  const t = useTranslate();
  const history = useHistory();
  const quoteData = useData(QuoteData);
  const client = useClient();
  const quote = getQuoteData(useData(QuoteData));
  const [visited, setVisited] = useState([]);
  const step = match.params.step || "cart";

  const currentStep = useSteps(step);

  useEffect(() => {
    setVisited([...visited, step]);
  }, [step]);

  const storeInfo = useContext(StoreInfoContext);

  if (quoteData.state === "LOADING" || !quote) {
    return null;
  }

  if (quoteData.state === "LOADED" && quoteData.data.items.length === 0) {
    history.push("/");
    return (
      <Wrapper className={styles.block}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t("CART.EMPTY")}</h1>
        </header>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={styles.block}>
      <Helmet title={currentStep?.titleShort || ""} />

      <header className={styles.header}>
        <h1 className={styles.heading}>{currentStep?.titleLong || ""}</h1>
        <div className={styles.customerService}>
          <CustomerServiceLink />
        </div>
      </header>

      <Switch>
        <Route exact path="/checkout/cart" render={() => <Cart step={0} quoteData={quoteData} />} />
        <StripePaymentProvider
          client={client}
          quoteData={quoteData}
          confirmStripePaymentIntentQuery={confirmStripePaymentIntent}
          createStripePaymentIntentQuery={createStripePaymentIntent}
          storeInfo={storeInfo.info}
        >
          <Route
            exact path="/checkout/placeorder"
            render={() => (
              <Checkout step={1} quoteData={quoteData} />
            )} />
        </StripePaymentProvider>
      </Switch>
    </Wrapper>
  );
};

export default CheckoutView;
