/* @flow */

import type { FilterRange } from "shop-state/types";

import React, { useEffect, useState } from "react";
import { RangeSlider, Input } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import Button from "components/Button";
import FilterContainer from "../FilterContainer";
import styles from "./styles.scss";

type Props = {
  openFilter: string,
  setOpenFilter: (filterName: string) => void,
  minPrice: number,
  maxPrice: number,
  priceRange: FilterRange,
  setPriceRange: (value: FilterRange, overrideMinDiff?: boolean) => void,
  loading: boolean,
  usePoints: boolean,
  label: string,
};

const FilterPrice = ({
  openFilter,
  setOpenFilter,
  minPrice,
  maxPrice,
  priceRange,
  setPriceRange,
  loading,
  usePoints,
  label }: Props) => {
  const { formatPoints, formatPrice } = useFormat();
  const t = useTranslate();
  const [price, setPrice] = useState<{ min: string, max: string}>({
    min: String(priceRange.min),
    max: String(priceRange.max),
  });

  useEffect(() => {
    setPrice({ min: String(priceRange.min), max: String(priceRange.max) });
  }, [priceRange]);

  const submitPriceRange = () => {
    if (minPrice === maxPrice) {
      setPrice({
        min: String(priceRange.min),
        max: String(priceRange.max),
      });
    }

    const min = price.min ? Number.parseInt(price.min, 10) : minPrice;
    const max = price.max ? Number.parseInt(price.max, 10) : maxPrice;

    const range = {
      min: min > minPrice && min < maxPrice ? min : minPrice,
      max: max > minPrice && max < maxPrice ? max : maxPrice,
    };
    setPriceRange(range, true);
  };

  const reset = () => {
    setPriceRange({ min: minPrice, max: maxPrice });
  };

  const showResetButton = priceRange.min !== minPrice || priceRange.max !== maxPrice;

  return (
    <FilterContainer
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      filterName={label}
    >
      <div className={styles.body}>
        <div className={styles.body__top}>
          <div className={styles.subheader}>
            {t(`OCF.FILTER_BY_${usePoints ? "POINT" : "PRICE"}`)}
          </div>
          {showResetButton &&
            <Button
              disabled={loading}
              className={styles.reset_button}
              onClick={reset}
            >
              {t("OCF.RESET_PRICE_FILTER")}
            </Button>
          }
        </div>
        <div
          className={styles.price_slider}
          onTransitionEnd={e => {
            e.stopPropagation();
          }}
        >
          <RangeSlider
            variant="small"
            value={priceRange}
            minValue={minPrice}
            maxValue={maxPrice}
            onChange={setPriceRange} />
        </div>
        {usePoints ?
          <div className={styles.price_slider__labels}>
            <span>{formatPoints(priceRange.min)}</span>
            <span>{formatPoints(priceRange.max)}</span>
          </div> :
          <div className={styles.price_slider__labels}>
            <span>{formatPrice(priceRange.min)}</span>
            <span>{formatPrice(priceRange.max)}</span>
          </div>
        }
        <div className={styles.price_inputs}>
          <Input
            disabled={loading}
            label={t("OCF.PRICE_FROM")}
            type="number"
            min="0"
            inputMode="numeric"
            pattern="[0-9]*"
            value={price.min}
            onBlur={submitPriceRange}
            onKeyUp={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                submitPriceRange();
              }
            }}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setPrice({
                min: e.currentTarget.value,
                max: price.max,
              });
            }}
          />
          <div className={styles.dash}>
            -
          </div>
          <Input
            disabled={loading}
            type="number"
            min="0"
            inputMode="numeric"
            pattern="[0-9]*"
            label={t("OCF.PRICE_TO")}
            value={price.max}
            onBlur={submitPriceRange}
            onKeyUp={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                submitPriceRange();
              }
            }}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setPrice({
                min: price.min,
                max: e.currentTarget.value,
              });
            }}
          />
        </div>
      </div>
    </FilterContainer>
  );
};

export default FilterPrice;
