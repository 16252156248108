/* @flow */

import type { FormState } from "components/CheckoutView/Checkout";

import React, { useState, useEffect } from "react";

import { useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Input } from "@crossroads/ui-components";
import { CheckboxField } from "components/Field";
import Button from "components/Button";
import { Box, BoxHeader, BoxBody } from "components/Box";
import { setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";

import styles from "./styles.scss";

type Props = {
  coupon?: {
    code: string,
    label: string,
  },
  state: FormState,
  setSummaryOpen: boolean => void,
  disabled?: boolean,
};

const DiscountCode = ({ coupon = "", state, setSummaryOpen, disabled }: Props) => {
  const t = useTranslate();
  const [discountCode, setDiscountCode] = useState<string>(coupon ? coupon.code : "");
  const sendMessage = useSendMessage();
  const [prevCode, setPrevCode] = useState(state.checkDiscountCode);

  const removeCode = () => {
    sendMessage(removeQuoteDiscountCode());
    setDiscountCode("");
    setSummaryOpen(true);
  };

  const onSubmit = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }

    if (discountCode.length === 0 || disabled === true) {
      return;
    }

    sendMessage(setQuoteDiscountCode(discountCode));
    setSummaryOpen(true);
  };

  const onRemove = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }

    if (disabled === true) {
      return;
    }

    removeCode();
  };

  useEffect(() => {
    if (discountCode.length > 0 &&
      !state.checkDiscountCode &&
      state.checkDiscountCode !== prevCode) {
      removeCode();
    }

    setPrevCode(state.checkDiscountCode);
  }, [state.checkDiscountCode, discountCode]);

  return (
    <Box className={styles.block}>
      <BoxHeader className={styles.boxHeader}>
        <CheckboxField
          name="checkDiscountCode"
          checked={state.checkDiscountCode}
        >
          {t("CHECKOUT.DISCOUNT_CODE.CHECKBOX_LABEL")}
        </CheckboxField>
      </BoxHeader>
      {state.checkDiscountCode &&
      <BoxBody>
        <div className={styles.discountCode}>
          <Input
            label={t("CHECKOUT.DISCOUNT_CODE.HEADER")}
            name="discountCode"
            disabled={coupon || disabled}
            value={discountCode}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setDiscountCode(e.currentTarget.value);
            }}
            onKeyUp={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
          {coupon ? (
            <Button
              className={styles.button}
              variant="primary"
              onClick={onRemove}
            >
              {t("CHECKOUT.DISCOUNT_CODE.REMOVE")}
            </Button>
          ) : (
            <Button
              className={styles.button}
              variant="primary"
              onClick={onSubmit}
            >
              {t("CHECKOUT.DISCOUNT_CODE.APPLY")}
            </Button>
          )}
        </div>
      </BoxBody>}
    </Box>
  );
};

export default DiscountCode;
