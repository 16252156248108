/* @flow */

import { useEffect, useState } from "react";
import { useBrowser } from "@crossroads-loyalty-solutions/react-use-browser";
import { throttle } from "diskho";

type Direction = "lt" | "gt";

const useDevice = (direction: Direction, breakpoint: number = 550) => {
  const isBrowser = useBrowser();
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 1200);

  useEffect(() => {
    if (isBrowser) {
      setWidth(window.innerWidth);

      const handleResize = throttle(() => setWidth(window.innerWidth), 1000);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [width, isBrowser]);

  return direction === "gt" ? width > breakpoint : width < breakpoint;
};

export default useDevice;
