/* @flow */

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { Link, withRouter } from "react-router-dom";
import { getCustomerData, getCustomerPoints } from "state/customer";
import cn from "classnames";
import { HomeData, CustomerData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { useBrowser } from "@crossroads-loyalty-solutions/react-use-browser";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import useFormat from "helpers/use-format";
import { formatMeta } from "helpers/get-meta";
import Wrapper from "components/Wrapper";
import Hero from "components/Hero";
import Button from "components/Button";
import MinimalProductCard from "components/MinimalProductCard";
import ProductList from "components/ProductList";

import ChevronIcon from "icons/chevron.svg";

import styles from "./styles.scss";

const HomeView = () => {
  const browser = useBrowser();
  const t = useTranslate();
  const home = useData(HomeData);
  const { info: { defaultTitle, defaultDescription, usePoints } } = useContext(StoreInfoContext);
  const { width: browserWidth } = useBrowserDimensions();
  const customer = getCustomerData(useData(CustomerData));
  const customerBalance = getCustomerPoints(customer);
  const { formatPoints } = useFormat();

  if (home.state !== "LOADED") {
    return null;
  }

  const slideData = (Object.values(home.data): any)
    .filter(e => e && Object.prototype.hasOwnProperty.call(e, "carousel"))
    .map(c => ({
      name: c.carousel.name,
      url: c.carousel.url,
      image: { x1: c.carousel.image.x1, x2: c.carousel.image.x2 },
    }));

  const meta = formatMeta({
    title: defaultTitle,
    description: defaultDescription,
  });

  const isCurrentBreakpoint = breakpoint => (browserWidth < parseInt(styles[breakpoint], 10));

  const getProductAmount = (breakpointColumnMapping, defaultAmount) => {
    const currentBreakpoint = Object.keys(breakpointColumnMapping).find(isCurrentBreakpoint);
    return !currentBreakpoint ? defaultAmount : breakpointColumnMapping[currentBreakpoint];
  };

  const getPopularProductAmount = () => {
    if (browserWidth < parseInt(styles.small, 10)) {
      return 2;
    }

    if (browserWidth < parseInt(styles.medium, 10)) {
      return 3;
    }

    return 4;
  };

  const getRecentlyViewedProductAmount = () => {
    const breakpointColumnMapping = {
      mini: 2,
      small: 3,
      medium: 4,
      large: 5,
    };
    return getProductAmount(breakpointColumnMapping, 6);
  };

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />
      {home.data.hero &&
        <section className={styles.row}>
          <Hero
            wrapContent
            className={styles.hero1}
            smallImage={home.data.hero.block.smallImage}
            mediumImage={home.data.hero.block.mediumImage}
            largeImage={home.data.hero.block.largeImage}
            alt={home.data.hero.block.heading}
            modifier={home.data.hero.block.modifier}
            buttonModifier="primary"
            CTA={home.data.hero.block.cta}
            link={home.data.hero.block.link}
          >
            <h1 className={cn(styles.heroHeading, styles.heroHeading__bg)}>
              {home.data.hero?.block.heading}
            </h1>
          </Hero>
        </section>
      }

      {home.data.recentlyViewedProducts && home.data.recentlyViewedProducts.items.length > 0 &&
        <Wrapper>
          <div className={styles.recentlyViewedProducts}>
            <h2>{t("HOMEVIEW.RECENTLY_VIEWED_PRODUCTS")}</h2>
            <div className={styles.products}>
              {browser && home.data.recentlyViewedProducts.items
                .slice(0, getRecentlyViewedProductAmount())
                .map(p => (
                  <div key={p.name} className={styles.product}>
                    <MinimalProductCard product={p} />
                  </div>
                ))}
            </div>
          </div>
        </Wrapper>
      }

      {home.data.featuredProducts &&
        <Wrapper>
          <div className={styles.popularProducts}>
            <header>
              <h2>{t("HOMEVIEW.POPULAR_PRODUCTS")}</h2>
              <Link
                className={styles.cta}
                to={{
                  pathname: home.data.featuredProducts.category.url,
                  state: { hint: {
                    type: "category",
                    category: {
                      name: home.data.featuredProducts.category.name,
                    },
                  } },
                }}
              >
                <ChevronIcon className={styles.chevron} />
                {t("HOMEVIEW.SEE_MORE")}
              </Link>
            </header>
            <ProductList
              listName="Featured products"
              products={
                home.data.featuredProducts.category.products.items
                  .slice(0, getPopularProductAmount())
              }
              breadcrumbLinks={[{
                pathname: home.data.featuredProducts.category.url,
                title: home.data.featuredProducts.category.name,
                hint: {
                  name: home.data.featuredProducts.category.name,
                },
              }]}
            />
          </div>
        </Wrapper>
      }

      {(home.data.block1Left && home.data.block1Right) &&
        <section className={styles.row}>
          <Wrapper>
            <div className={styles.col2}>
              <Link to={home.data.block1Left.block.link} className={styles.campaignCard}>
                <div className={styles.imageWrapper}>
                  <picture
                    className={styles.image}
                    alt={home.data.block1Left.block.heading}
                  >
                    <source
                      media="(min-width: 801px) and (max-resolution: 149dpi)"
                      srcSet={home.data.block1Left.block.image.x1}
                    />

                    <source
                      media="(min-width: 801px) and (min-resolution: 150dpi) and (max-resolution: 299dpi)"
                      srcSet={home.data.block1Left.block.image.x2}
                    />

                    <img
                      alt={home.data.block1Left.block.heading}
                      src={home.data.block1Left.block.image.x1}
                    />
                  </picture>
                </div>
                <h2 className={styles.heading}>{home.data.block1Left.block.heading}</h2>
                <p>{home.data.block1Left.block.text}</p>
              </Link>
              <Link to={home.data.block1Right.block.link} className={styles.campaignCard}>
                <div className={styles.imageWrapper}>
                  <picture className={styles.image} alt={home.data.block1Right.block.heading}>
                    <source
                      media="(min-width: 801px) and (max-resolution: 149dpi)"
                      srcSet={home.data.block1Right.block.image.x1}
                    />
                    <source
                      media="(min-width: 801px) and (min-resolution: 150dpi) and (max-resolution: 299dpi)"
                      srcSet={home.data.block1Right.block.image.x2}
                    />
                    <img
                      alt={home.data.block1Right.block.heading}
                      src={home.data.block1Right.block.image.x1}
                    />
                  </picture>
                </div>
                <h2 className={styles.heading}>{home.data.block1Right.block.heading}</h2>
                <p>{home.data.block1Right.block.text}</p>
              </Link>
            </div>
          </Wrapper>
        </section>
      }

      <section className={styles.row}>
        <Wrapper>
          <div className={styles.categories}>
            {slideData.map(x => (
              <div key={x.name}>
                <Link to={x.url} className={styles.category}>
                  <div className={styles.imageWrapper}>
                    <picture className={styles.image} alt={x.name}>
                      <source media="(max-resolution: 149dpi)" srcSet={x.image.x1} />
                      <source media="(min-resolution: 150dpi)" srcSet={x.image.x2} />
                      <img src={x.image.x1} alt={x.name} />
                    </picture>
                  </div>
                  <Button variant="primary">{x.name}</Button>
                </Link>
              </div>
            ))}
          </div>
        </Wrapper>
      </section>

      {home.data.block2FullWidth &&
        <section className={styles.row}>
          <Wrapper>
            <Hero
              className={styles.hero2}
              wrapperClass={styles.hero2Wrapper}
              smallImage={home.data.block2FullWidth.block.smallImage}
              mediumImage={home.data.block2FullWidth.block.mediumImage}
              largeImage={home.data.block2FullWidth.block.largeImage}
              modifier={home.data.block2FullWidth.block.modifier}
              alt={home.data.block2FullWidth.block.heading}
              buttonModifier="negative"
              CTA={home.data.block2FullWidth.block.cta}
              link={home.data.block2FullWidth.block.link}
            >
              <h2 className={cn(styles.heroHeading, styles.heroHeading__bg)}>
                {home.data.block2FullWidth?.block.heading}
              </h2>
            </Hero>
          </Wrapper>
        </section>
      }
      {usePoints && customerBalance > 1500 ?
        <section className={styles.row}>
          <Wrapper>
            <Link className={styles.promo} to={`/all-products?filter_points%3Ascandic_max=${customerBalance}&filter_points%3Ascandic_min=0`}>
              <div>
                <h2>{t("HOMEVIEW.AFFORDABLE_PRODUCTS.HEADING", { points: formatPoints(customerBalance) })}</h2>
                <p>{t("HOMEVIEW.AFFORDABLE_PRODUCTS.TEXT")}</p>
              </div>
              <div>
                <Button variant="negative">{t("HOMEVIEW.SEE_MORE")}</Button>
              </div>
            </Link>
          </Wrapper>
        </section> :
        null }
    </div>
  );
};

export default withRouter(HomeView);
